import React, { useState, useEffect, FC, useContext } from 'react';
import { useLazyGetCourse } from '../utils/useLazyCourses';
import { Button } from '@mui/material';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Alert } from '@mui/material';

import "../css/LearningPath.css"
import { useNavigate } from 'react-router-dom';
import { Challenge, Course, Difficulty, LEARNING_PATH_COURSE_ID, LEARNING_PATH_GENERATOR_PATH, LEARNING_PATH_MODULE_ID, LEARNING_PATH_SUBMODULE_ID, LOCAL_STORAGE_LEARNING_PATH, LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT, LOCAL_STORAGE_SPACED_REPETITION_KEY, Unit, UnitConceptTags } from '../utils/Constants';
import { ConceptEvaluation, Strength } from './AssessmentFeedback';
import { logToBackendLogFile } from '../externalLayerAccessor/BackEndRequests';
import StateAccessor from '../StateAccessor';


//////////////////////////
//Helper Types and Utils
//////////////////////////


enum TaskType {
    VIDEO = "VIDEO",
    MOCK_INTERVIEW = "MOCK_INTERVIEW",
    CV_OPTIMIZATION = "CV_OPTIMIZATION",
    ARTICLE = "ARTICLE",
    LESSON = "LESSON",
    CHALLENGE = "CHALLENGE",
    ASSESSMENT = "ASSESSMENT",
    SPACED_REP_CHALLENGE = "SPACED_REP_CHALLENGE"
}


interface Lesson {
    tasks: Task[];
}

enum MockInterviewType {
    AI,
    HUMAN
}

enum InterviewerCount {
    ONE,
    TWO
}

enum ArticleType {
    INTERNAL,
    EXTERNAL
}

interface TaskCompletionDetails {
    isCompleted: boolean;
    completionDate: string | null;
}

interface Task {
    id: number;  // Unique identifier
    title: string;
    type: TaskType;
    difficulty: Difficulty;
    estimatedCompletionTimeInMinutes: number; // Estimated time in minutes or hours
    priority: number; //  implies order of tasks in the linearised dag, think of it as topological sort rank of the task
    isOptional: boolean;
    completionDetails: TaskCompletionDetails;
    details?: TaskDetails;
}


interface TaskDetails { // each Task type has a single corresponding field in TaskDetails
    videoUrl?: string;
    mockInterviewDetails?: MockInterviewDetails;
    cvOptimizationDetails?: string;
    articleDetails?: ArticleDetails;
    lesson?: Lesson;
    challengeId?: number;
    assessmentId?: number;
    spacedRepChallengeDetails?: SpacedRepChallengeDetails;
}

interface SpacedRepChallengeDetails {
    parentChallengeId: number; // ID of the original challenge that this spaced repetition task is derived from
    challengeId: number; // ID of the spaced repetition challenge
    dueDate: string; // The due date for the spaced repetition task
}

interface ArticleDetails {
    type: ArticleType;
    url: string;
}

interface MockInterviewDetails {
    mockInterviewType: MockInterviewType;
    interviewerCount: InterviewerCount;
}

enum TimeUnit {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH"
}

class SpacingPattern {
    amount: number;
    unit: TimeUnit;

    constructor(amount: number, unit: TimeUnit) {
        this.amount = amount;
        this.unit = unit;
    }

    // Convert the pattern to days for easier computation
    toDays(): number {
        switch (this.unit) {
            case TimeUnit.DAY:
                return this.amount;
            case TimeUnit.WEEK:
                return this.amount * 7;
            case TimeUnit.MONTH:
                return this.amount * 30;  // Approximation, as some months have 31, 28, or 29 days
        }
    }

    static getSpacingForRepetition(repetition: number): SpacingPattern {
        if (repetition < 0) {
            console.error("Repetition cannot be negative");
            repetition = 0;
        }
        const patterns = [
            new SpacingPattern(1, TimeUnit.DAY),
            new SpacingPattern(3, TimeUnit.DAY),
            new SpacingPattern(1, TimeUnit.WEEK),
            new SpacingPattern(2, TimeUnit.WEEK),
            new SpacingPattern(1, TimeUnit.MONTH),
            new SpacingPattern(2, TimeUnit.MONTH),
            new SpacingPattern(3, TimeUnit.MONTH)
        ];

        return repetition < patterns.length ? patterns[repetition] : patterns[patterns.length - 1];
    }
}


//////////////////////////
// Components
//////////////////////////

const TaskView: FC<{ tasks: Record<number, Task> | null, onCompletion: (task: Task) => void }> = ({ tasks, onCompletion }) => {
    const getTaskTypeIcon = (type: TaskType) => {
        switch (type) {
            case TaskType.VIDEO:
                return '🎥'; // Camera emoji for videos
            case TaskType.MOCK_INTERVIEW:
                return '👥'; // Two silhouettes representing an interview
            case TaskType.CV_OPTIMIZATION:
                return '📄'; // Page emoji representing CV
            case TaskType.ARTICLE:
                return '📝'; // Memo emoji representing an article
            case TaskType.LESSON:
                return '🎮'; // Game controller emoji for lessons/games
            case TaskType.CHALLENGE:
                return '⚔️'; // Crossed swords emoji for challenge
            case TaskType.ASSESSMENT:
                return '📊'; // Chart emoji for assessments
            default:
                return null;
        }
    }

    const LEARNING_PATH_CHALLENGE_URL_PREFIX = "/app/learning/courses/1/modules/0/submodules/0/" // all challenges must be units in this course-module-submodule combo //NITO!: undo usage

    return (
        <div className="tasks">
            <Typography variant="h6" className='tasks-container-heading'>Your Personalised Path</Typography>
            <div className="info">
                <Alert severity="info">
                    For best results, complete the tasks in the order shown below
                </Alert>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className='table-heading'>Task</TableCell>
                        <TableCell className='table-heading' align="right">Completed?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasks && Object.values(tasks)
                        .sort((a, b) => a.priority - b.priority) // Sort tasks by priority in ascending order
                        .map(task => (
                            <TableRow key={task.id}>
                                <TableCell component="th" scope="row">
                                    {/* Displaying the icon for the task type */}
                                    {getTaskTypeIcon(task.type)}
                                    {/* NEBUG TODO: Replace this with actual links to the resources */}
                                    <a href={(task.type === TaskType.CHALLENGE) ? `${LEARNING_PATH_CHALLENGE_URL_PREFIX}units/${task.details?.challengeId}/challenge/${task.details?.challengeId}` : "http://example.com/task"} target="_blank" rel="noreferrer"> {task.title}</a>
                                </TableCell>
                                <TableCell align="right">
                                    <Checkbox
                                        color="primary"
                                        checked={task.completionDetails.isCompleted}
                                        sx={{ backgroundColor: 'white', ":hover": { backgroundColor: '#f4f4f4' } }}
                                        onChange={() => onCompletion(task)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </div>
    );
};

interface SpacedRepetitionViewProps {
    tasks: Task[];
    lazilyGetCourse: (courseId: number) => Promise<Course | null>;
}

const SpacedRepetitionView: FC<SpacedRepetitionViewProps> = ({ tasks, lazilyGetCourse }) => {
    const [completedChallengeHash, setCompletedChallengeHash] = useState<Record<number, boolean>>({});
    const [dueTasks, setDueTasks] = useState<Task[]>([]);
    const [completedTasks, setCompletedTasks] = useState<Task[]>([]);


    useEffect(() => {
        const storedCompletedChallenges = localStorage.getItem(LOCAL_STORAGE_SPACED_REPETITION_KEY);
        if (storedCompletedChallenges) {
            setCompletedChallengeHash(JSON.parse(storedCompletedChallenges));
        }
    }, []);

    useEffect(() => {
        (async () => {
            const [due, completed] = await fetchTasks(tasks);
            setDueTasks(due);
            setCompletedTasks(completed);
        })();
    }, [tasks, completedChallengeHash]);


    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_SPACED_REPETITION_KEY, JSON.stringify(completedChallengeHash));
    }, [completedChallengeHash]);

    const handleCheckChange = (challengeId: number, checked: boolean) => {
        setCompletedChallengeHash(prevState => ({ ...prevState, [challengeId]: checked }));
    };

    const getChallengeFromLearningPathCourseByChallengeId = async (challengeId: number): Promise<Challenge | null> => {
        try {
            const learningPathCourse: Course | null = await lazilyGetCourse(LEARNING_PATH_COURSE_ID)
            if (!learningPathCourse) {
                console.error('No courses found for the provided ID:', LEARNING_PATH_COURSE_ID);
                return null;
            }

            // Ensure that there's enough data in the structures before accessing them
            if (!learningPathCourse.modules || learningPathCourse.modules.length === 0 ||
                !learningPathCourse.modules[0].submodules || learningPathCourse.modules[0].submodules.length === 0 ||
                !learningPathCourse.modules[0].submodules[0].units || learningPathCourse.modules[0].submodules[0].units.length <= challengeId) {
                console.error('Invalid course structure or challenge ID is out of bounds.');
                return null;
            }

            const challenge = learningPathCourse.modules[0].submodules[0].units[challengeId].challenge;
            return challenge;
        } catch (error) {
            console.error('Error while fetching challenge:', error);
            return null;
        }
    }



    const fetchTasks = async (tasks: Task[]) => {
        const due: Task[] = [];
        const completed: Task[] = [];

        for (const parentTask of tasks) {
            if (parentTask.type === TaskType.CHALLENGE && parentTask.completionDetails.isCompleted) {
                const parentTaskChallengeId = parentTask.details?.challengeId;

                if (!parentTaskChallengeId) {
                    console.error('Invalid challenge ID');
                    continue;
                }

                const challenge: Challenge | null = await getChallengeFromLearningPathCourseByChallengeId(parentTaskChallengeId);
                if (!challenge) {
                    console.error('Invalid challenge');
                    continue;
                }

                const taskSpacedRepChallenges: Challenge[] = challenge?.spaced_repetition_challenges || [];


                let repetition = 0;
                for (const spacedRepChallenge of taskSpacedRepChallenges) {

                    // determine due date of spaced repetition challenge using the spacing pattern and parent task completion date
                    const spacing = SpacingPattern.getSpacingForRepetition(repetition++);
                    const spacingInDays = spacing.toDays();

                    // Compute the due date relative to the parent task's completion date
                    let dueDate: Date = (parentTask.completionDetails.completionDate
                        ? new Date(parentTask.completionDetails.completionDate)
                        : new Date());

                    dueDate.setDate(dueDate.getDate() + spacingInDays);

                    // create new task wrapping the spaced repetition challenge i.e. the spaced repetition task
                    let priorityIndex: number = 0;
                    const newSpacedRepTask: Task = {
                        id: spacedRepChallenge.challenge_id,
                        title: spacedRepChallenge.title,
                        type: TaskType.SPACED_REP_CHALLENGE,
                        difficulty: spacedRepChallenge.difficulty,
                        estimatedCompletionTimeInMinutes: spacedRepChallenge.time_limit_in_minutes,
                        priority: priorityIndex++,
                        isOptional: false,
                        completionDetails: {
                            isCompleted: completedChallengeHash[spacedRepChallenge.challenge_id],
                            completionDate: null // could be set to the date when the challenge was completed but not needed for mvp for spaced rep challenges
                        },
                        details: {
                            spacedRepChallengeDetails:
                            {
                                parentChallengeId: parentTaskChallengeId,
                                challengeId: spacedRepChallenge.challenge_id,
                                dueDate: dueDate.toLocaleDateString()
                            }
                        }

                    }
                    if (completedChallengeHash[spacedRepChallenge.challenge_id]) {
                        completed.push(newSpacedRepTask);
                    } else {
                        due.push(newSpacedRepTask);
                    }
                }
            }
        }

        return [due, completed];
    }


    return (
        <div className="spaced-repetition">

            {(dueTasks.length > 0) && (<div className='due-tasks-container'>
                <Typography variant="h6" className='spaced-repetition-container-heading'>Due Spaced Repetitions</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-heading'>Task</TableCell>
                            <TableCell className='table-heading' align="right">Target Date</TableCell>
                            <TableCell className='table-heading' align="right">Completed</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dueTasks.map(task => (
                            <TableRow key={task.details?.spacedRepChallengeDetails?.challengeId || -1}>
                                <TableCell component="th" scope="row">
                                    <a href={`http://example.com/challenges/${task.details?.spacedRepChallengeDetails?.challengeId || -1}`} target="_blank" rel="noreferrer">
                                        {task.title}
                                    </a>
                                </TableCell>
                                <TableCell align="right">
                                    {task?.details?.spacedRepChallengeDetails?.dueDate || (new Date()).toLocaleDateString()}
                                </TableCell>
                                <TableCell align="right">
                                    <Checkbox
                                        checked={completedChallengeHash[task.details?.spacedRepChallengeDetails?.challengeId || -1] || false}
                                        onChange={(e) => handleCheckChange(task.details?.spacedRepChallengeDetails?.challengeId || -1, e.target.checked)}
                                        color="primary"
                                        sx={{ backgroundColor: 'white', ":hover": { backgroundColor: '#f4f4f4' } }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            )}

            {(completedTasks.length > 0) && (<div className='completed-tasks-container'>
                <Typography variant="h6" className='spaced-repetition-container-heading'>Completed Spaced Repetitions</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-heading'>Task</TableCell>
                            <TableCell className='table-heading' align="right">Completed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {completedTasks.map(task => (
                            <TableRow key={task.details?.spacedRepChallengeDetails?.challengeId || -1}>
                                <TableCell component="th" scope="row">
                                    <a href={`http://example.com/challenges/${task.details?.challengeId}`} target="_blank" rel="noreferrer">
                                        {task.title}
                                    </a>
                                </TableCell>
                                <TableCell align="right">
                                    <Checkbox
                                        checked={completedChallengeHash[task.details?.spacedRepChallengeDetails?.challengeId || -1] || false}
                                        onChange={(e) => handleCheckChange(task.details?.spacedRepChallengeDetails?.challengeId || -1, e.target.checked)}
                                        color="primary"
                                        sx={{ backgroundColor: 'white', ":hover": { backgroundColor: '#f4f4f4' } }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            )}

            {/* handle view for no tasks */}
            {(dueTasks.length === 0 && completedTasks.length === 0) && (
                <div className='no-tasks-container'>
                    <p>🚧 Your spaced repetition tasks will appear here once this feature is enabled</p>
                </div>
            )}
        </div>
    );
};



/*
* Learning Path Component is all about rendering the tasks and spaced repetition challenges
*/

const LearningPathComponent: FC = ({ }) => {
    const [learningPathExists, setLearningPathExists] = useState<boolean>(false);
    const [tasks, setTasks] = useState<Record<number, Task> | null>(null); // Record is pretty much a hashtable or python dictionary with number keys and Task values
    const [studyHoursPerDay, setStudyHoursPerDay] = useState<number>(3);
    const [numWeeksForSelfPacedLearning, setNumWeeksForSelfPacedLearning] = useState<number>(12);
    const { lazilyGetCourse } = useLazyGetCourse();

    const navigate = useNavigate();
    const { userId } = useContext(StateAccessor);

    //////////////////////////
    // useEffect
    //////////////////////////
    useEffect(() => {
        logToBackendLogFile(
            `Viewed Learning Path`,
            "info",
            userId);
        try {
            // Try to retrieve the learning path from localStorage
            const storedLearningPath: string | null = localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH);

            if (storedLearningPath) {
                // console.log("found learning path in local storage")
                // Parse the tasks from the localStorage
                const parsedLearningPathObject: Record<number, Task> = JSON.parse(storedLearningPath);

                // Ensure data integrity: Check if parsedLearningPathObject has the correct format
                if (Object.values(parsedLearningPathObject).every(task => task && typeof task.id === "number")) {
                    setTasks(parsedLearningPathObject);
                    setLearningPathExists(true);
                    //console.log("Learning path retrieved from localStorage")
                } else {
                    console.error("Stored learning path is not in the expected format");
                    setLearningPathExists(false);
                }

            } else {
                // console.log("did not find learning path in local storage")
                // Check if concepts are stored in local storage
                const storedUserAssessmentByConcepts: string | null = localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT);

                if (storedUserAssessmentByConcepts) {
                    // console.log("found user assessment by concepts in localStorage")
                    // Parse the user assessments
                    const parsedConcepts: [] = JSON.parse(storedUserAssessmentByConcepts);

                    if (Array.isArray(parsedConcepts)) {
                        console.log("generating learning path from user assessment by concepts")
                        generateLearningPathFromUserAssessmentByConcept(parsedConcepts);
                    } else {
                        console.error(`Stored user assessment by concepts is not in the expected format, expected Array but got ${typeof parsedConcepts}`);
                        // console.error(parsedConcepts);
                        // console.error(JSON.stringify(parsedConcepts));
                        setLearningPathExists(false);
                    }

                } else {
                    // console.error("No learning path or user assessment by concepts found in localStorage");
                    setLearningPathExists(false);
                }

            }

        } catch (error) {
            console.error("Error while retrieving or parsing data from localStorage:", error);
            setLearningPathExists(false);
        }
    }, []);


    useEffect(() => {
        if (tasks) {
            localStorage.setItem(LOCAL_STORAGE_LEARNING_PATH, JSON.stringify(Object.values(tasks)));
        }
    }, [tasks]);

    useEffect(() => {
        // Try to retrieve the user assessment from localStorage
        const storedUserAssessmentByConcepts: string | null = localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT);

        if (storedUserAssessmentByConcepts) {
            // Parse the user assessments
            const parsedConcepts: ConceptEvaluation[] = JSON.parse(storedUserAssessmentByConcepts);

            if (Array.isArray(parsedConcepts)) {
                generateLearningPathFromUserAssessmentByConcept(parsedConcepts);
            }// do nothing otherwise
        }
    }, [studyHoursPerDay, numWeeksForSelfPacedLearning]);


    //////////////////////////
    // Functions
    //////////////////////////
    const taskMatchesStrength = (task: Task, strength: Strength): boolean => {
        switch (strength) {
            case Strength.Weak:
                // For weak users, any difficulty level is suitable
                return true;

            case Strength.Moderate:
                // For medium users, only ModeratelyChallenging and Challenging tasks
                return task.difficulty === Difficulty.ModeratelyChallenging ||
                    task.difficulty === Difficulty.Challenging;

            case Strength.Strong:
                // For strong users, only Challenging tasks
                return task.difficulty === Difficulty.Challenging;

            default:
                // Default case, in case more strength levels are added in the future or there's an error
                console.warn(`Unknown strength level: ${strength}`);
                return false;
        }
    };

    const getTaskByConceptMapping = async (): Promise<Record<UnitConceptTags, Task[]>> => {//NITO!: rename to include throws and let caller handle exceptions thrown
        /*
SAMPLE OUTPUT:
    //
    {
        [UnitConceptTags.Array]: [
            {
                id: 0,
                title: "Watch this video",
                type: TaskType.VIDEO,
                estimatedCompletionTimeInMinutes: 20, // in minutes
                difficulty: Difficulty.Straightforward,
                priority: 2,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    videoUrl: "http://example.com/video1"
                }
            },
            {
                id: 1,
                title: "Read this article",
                type: TaskType.ARTICLE,
                estimatedCompletionTimeInMinutes: 30, // in minutes
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 0,
                isOptional: true,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    articleDetails: {
                        type: ArticleType.EXTERNAL,
                        url: "http://example.com/article1"
                    }
                }
            },
            {
                id: 2,
                title: "Mock Interview",
                type: TaskType.MOCK_INTERVIEW,
                estimatedCompletionTimeInMinutes: 60, // in minutes
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 3,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    mockInterviewDetails: {
                        mockInterviewType: MockInterviewType.AI,
                        interviewerCount: InterviewerCount.ONE
                    }
                }
            },
            {
                id: 3,
                title: "A dummy Lesson",
                type: TaskType.LESSON,
                estimatedCompletionTimeInMinutes: 120, // in minutes
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 1,
                isOptional: true,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    lesson: {
                        tasks: [
                            {
                                id: 31,
                                title: "An article within a lesson",
                                type: TaskType.ARTICLE,
                                estimatedCompletionTimeInMinutes: 30, // in minutes
                                difficulty: Difficulty.ModeratelyChallenging,
                                priority: 0,
                                isOptional: true,
                                completionDetails: {
                                    isCompleted: false,
                                    completionDate: null
                                },
                                details: {
                                    articleDetails: {
                                        type: ArticleType.EXTERNAL,
                                        url: "http://example.com/article1"
                                    }
                                }
                            },
                        ]
                    }
                }
            },
            {
                id: 4,
                title: "Do this assessment",
                type: TaskType.ASSESSMENT,
                estimatedCompletionTimeInMinutes: 25, // in minutes
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 0,
                isOptional: true,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    assessmentId: 1
                }
            },
            {
                id: 5,
                title: "Do this challenge (no spaced repetition)",
                type: TaskType.CHALLENGE,
                estimatedCompletionTimeInMinutes: 20, // in minutes
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 4,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    challengeId: 0
                }
            },
            {
                id: 6,
                title: "Do this challenge (1 spaced repetition)",
                type: TaskType.CHALLENGE,
                estimatedCompletionTimeInMinutes: 30, // in minutes
                difficulty: Difficulty.Straightforward,
                priority: 4,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null

                },
                details: {
                    challengeId: 502
                }
            },
            {
                id: 7,
                title: "Do this challenge (3 spaced repetition)",
                type: TaskType.CHALLENGE,
                estimatedCompletionTimeInMinutes: 100, // in minutes
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 4,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null

                },
                details: {
                    challengeId: 1
                }
            },
        ],
        [UnitConceptTags.Queue]: [
            {
                id: 8,
                title: "Introduction to Queues",
                type: TaskType.VIDEO,
                estimatedCompletionTimeInMinutes: 15,
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 1,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    videoUrl: "http://example.com/queue-intro"
                }
            }
        ],
        [UnitConceptTags.String]: [
            {
                id: 9,
                title: "Understanding Strings in Programming",
                type: TaskType.ARTICLE,
                estimatedCompletionTimeInMinutes: 20,
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 1,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    articleDetails: {
                        type: ArticleType.EXTERNAL,
                        url: "http://example.com/strings-article"
                    }
                }
            },
            {
                id: 10,
                title: "String Manipulation Challenge",
                type: TaskType.MOCK_INTERVIEW,
                estimatedCompletionTimeInMinutes: 30,
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 2,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    mockInterviewDetails: {
                        mockInterviewType: MockInterviewType.HUMAN,
                        interviewerCount: InterviewerCount.TWO
                    }
                }
            }
        ],
        [UnitConceptTags.Stack]: [
            {
                id: 11,
                title: "Very long read about stacks",
                type: TaskType.ARTICLE,
                estimatedCompletionTimeInMinutes: 20160, // 2 weeks
                difficulty: Difficulty.ModeratelyChallenging,
                priority: 1,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    articleDetails: {
                        type: ArticleType.EXTERNAL,
                        url: "http://example.com/strings-article"
                    }
                }
            },
        ],
        [UnitConceptTags.Matrix]: [],
        [UnitConceptTags.HashFunction]: [],
        [UnitConceptTags.HashTable]: [],
        [UnitConceptTags.BinarySearch]: [],
        [UnitConceptTags.Sorting]: [],
        [UnitConceptTags.PriorityQueue]: [],
        [UnitConceptTags.LinkedList]: [],
        [UnitConceptTags.DoublyLinkedList]: [],
        [UnitConceptTags.Recursion]: [],
        [UnitConceptTags.Tree]: [],
        [UnitConceptTags.BinarySearchTree]: [],
        [UnitConceptTags.DivideAndConquer]: [],
        [UnitConceptTags.Heap]: [],
        [UnitConceptTags.Trie]: [],
        [UnitConceptTags.Graph]: [],
        [UnitConceptTags.DepthFirstSearch]: [],
        [UnitConceptTags.BreadthFirstSearch]: [],
        [UnitConceptTags.TopologicalSort]: [],
        [UnitConceptTags.DynamicProgramming1D]: [],
        [UnitConceptTags.DynamicProgramming2D]: [],
        [UnitConceptTags.BitManipulation]: [],
        [UnitConceptTags.Bitmask]: [],
        [UnitConceptTags.ConcurrencyAndParallelAlgorithms]: [],
        [UnitConceptTags.AsymptoticRuntimeComplexity]: [],
        [UnitConceptTags.AsymptoticSpaceComplexity]: [],
        // ... more concepts can be added similarly
    };

    }
*/
        const learningPathCourse: Course | null = await lazilyGetCourse(LEARNING_PATH_COURSE_ID);

        if (!learningPathCourse) {
            throw new Error(`Could not find learning path course with ID: ${LEARNING_PATH_COURSE_ID}`);
        }

        const learningPathUnits: Unit[] = learningPathCourse.modules[LEARNING_PATH_MODULE_ID].submodules[LEARNING_PATH_SUBMODULE_ID].units;

        // Populate tasksByConceptTags with initial empty arrays
        // @ts-ignore: Suppresses TypeScript error for dynamic enum mapping
        let tasksByConceptTags: Record<UnitConceptTags, Task[]> = Object.fromEntries(
            Object.values(UnitConceptTags).map(tag => [tag, []])
        );

        let task_id = 0;
        for (const unit of learningPathUnits) {
            if (unit.tags.length === 0) {
                // Skip units without concept tags
                continue;
            }

            const challenge_concept_tag: UnitConceptTags = unit.tags[0]; // we take primary tag

            if (!(challenge_concept_tag in tasksByConceptTags)) {
                console.error(`Unknown concept tag: ${challenge_concept_tag} skipping challenge`);
                continue;
            }

            tasksByConceptTags[challenge_concept_tag].push({
                id: task_id++,
                title: unit.challenge.title,
                type: TaskType.CHALLENGE,
                estimatedCompletionTimeInMinutes: unit.challenge.time_limit_in_minutes,
                difficulty: unit.challenge.difficulty,
                priority: 0,
                isOptional: false,
                completionDetails: {
                    isCompleted: false,
                    completionDate: null
                },
                details: {
                    challengeId: unit.challenge.challenge_id
                }
            });
        }

        return tasksByConceptTags;
    };

    const generateLearningPathFromUserAssessmentByConcept = async (userStrengthAssessment: ConceptEvaluation[]) => {
        let remainingTimeInMinutes = numWeeksForSelfPacedLearning * 7 * studyHoursPerDay * 60; // 7 days per week, 60 minutes per hour
        const learningPathTasks: Record<number, Task> = {}; // Initialized as an empty object

        // Try to retrieve the existing learning path from localStorage
        const storedLearningPath: string | null = localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH);
        const existingTasks: Record<number, Task> = storedLearningPath ? JSON.parse(storedLearningPath) : {};

        const contentMapping = await getTaskByConceptMapping();
        // Loop over each concept evaluation
        userStrengthAssessment.forEach(evaluation => {
            const conceptTasks = contentMapping[evaluation.concept];

            // Filter tasks based on strength and then sort by priority
            const sortedTasks = conceptTasks
                .filter(task => taskMatchesStrength(task, evaluation.strength))
                .sort((a, b) => a.priority - b.priority);

            // Add tasks to the learning path based on remaining time
            for (const task of sortedTasks) {
                if (remainingTimeInMinutes - task.estimatedCompletionTimeInMinutes >= 0) {
                    // Check if the task exists and is completed in local storage
                    if (existingTasks[task.id]) {
                        task.completionDetails = existingTasks[task.id].completionDetails;
                        // If there are more properties related to completion, you can continue updating them here
                    }

                    learningPathTasks[task.id] = task; // Set the task by its id
                    remainingTimeInMinutes -= task.estimatedCompletionTimeInMinutes;
                } else if (task.isOptional) {
                    continue; // Skip optional tasks if they exceed the time limit
                } else {
                    // If it's not optional but exceeds time, consider breaking or adjust logic as per needs
                    continue;
                }
            }
        });

        setTasks(learningPathTasks);
        // set setLearningPathExists true if learningPathTasks has at least one entry
        setLearningPathExists(Object.keys(learningPathTasks).length > 0);
    };


    const handleRegenerateLearningPath = () => {
        // Ask user if sure and if yes, clear the learning path from local storage
        const userConfirmed = window.confirm("Are you sure you want to regenerate the learning path?");

        if (userConfirmed) {
            localStorage.removeItem(LOCAL_STORAGE_LEARNING_PATH);
            navigate(LEARNING_PATH_GENERATOR_PATH);
        }
    }

    const handleToggleTaskCompletion = (taskToToggle: Task) => {
        // If tasks is null or undefined, return early
        if (!tasks) return;

        // Create a new task object with the completion status toggled
        const newCompletionDetails: TaskCompletionDetails = {
            isCompleted: !taskToToggle.completionDetails.isCompleted,
            completionDate: new Date().toISOString().split('T')[0]
        };

        const updatedTask = {
            ...taskToToggle, // copy all the properties of taskToToggle
            completionDetails: newCompletionDetails // override the completionDetails property
        };

        // Update the tasks object with the updated task
        setTasks({
            ...tasks, // copy all the properties of tasks
            [taskToToggle.id]: updatedTask// override the task with the updated task
        });
    };

    const handleStudyHoursPerDayChange = (e: Event, newValue: number | number[]) => {
        console.log("Updating study hours per day");
        setStudyHoursPerDay(newValue as number);
    }


    const handleNumWeeksForSelfPacedLearningChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Updating num weeks for self paced learning")
        const newNumWeeksForSelfPacedLearning = parseInt(e.target.value);
        setNumWeeksForSelfPacedLearning(newNumWeeksForSelfPacedLearning);
    }

    return (
        <div className="learning-path">
            <div className="alert horizontalMotionAnimation">
                <Alert severity="warning">
                    Your progress is saved on this device and browser only
                </Alert>
            </div>

            {/* Settings */}
            <div className="settings">
                {!learningPathExists && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(LEARNING_PATH_GENERATOR_PATH)}
                    >
                        Generate Learning Path
                    </Button>
                )}

                {learningPathExists && (
                    <>
                        <div className="setting">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleRegenerateLearningPath}
                                sx={{ marginBottom: "5px" }}
                            >
                                Regenerate Learning Path
                            </Button>
                        </div>
                        <div className="setting">
                            <Tooltip title="Total hours spent studying, excluding breaks">
                                <Typography gutterBottom>Hours per Day</Typography>
                            </Tooltip>
                            <Slider
                                defaultValue={3}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={1}
                                marks
                                min={1}
                                max={8}
                                onChange={handleStudyHoursPerDayChange}
                                sx={{
                                    minWidth: '300px',
                                    maxWidth: '400px',
                                }}
                            />
                        </div>
                        <div className="setting">

                            <Typography gutterBottom>
                                Number of Weeks for self-paced learning
                            </Typography>

                            <Tooltip title="You can type the number">
                                <TextField
                                    type="number"
                                    defaultValue={12}
                                    InputProps={{
                                        inputProps: { min: 1, max: 52 }
                                    }}
                                    sx={{ marginLeft: '5px', backgroundColor: '#f4f4f4' }}
                                    onChange={handleNumWeeksForSelfPacedLearningChange}
                                />
                            </Tooltip>
                        </div>
                    </>
                )}
            </div>

            {/* Learning Path Views */}
            <div className="learning-path-views">
                {learningPathExists && tasks && (
                    <>
                        <TaskView tasks={tasks} onCompletion={handleToggleTaskCompletion} />
                        <SpacedRepetitionView tasks={Object.values(tasks)} lazilyGetCourse={lazilyGetCourse} />

                    </>
                )}
            </div>

            <div className="alert">
                <Alert severity="warning">
                    Your progress is saved on this device and browser only
                </Alert>
            </div>
        </div>
    );
};
export default LearningPathComponent;
