import React, { useContext, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import StateAccessor from '../StateAccessor';
import { CODITIONING_OUTLOOK_SERVICE_ID, DEFAULT_EMAIL_JS_PUBLIC_KEY, FEATURE_FEEDBACK_TEMPLATE_ID } from '../utils/Constants';
import feedback_background from '../img/icons/feedback_background.jpg';
import feedbackimg from '../img/icons/feedbackimg.png';

const EMAIL_JS_PUBLIC_KEY = process.env.EMAIL_JS_PUBLIC_KEY || DEFAULT_EMAIL_JS_PUBLIC_KEY;

const placeholderTexts: { [key: number]: string } = {
  1: "Your experience did not meet your expectations. Please share how we can improve.",
  2: "Your experience was below average. We'd love to know what we can do better.",
  3: "Your experience was average – what could make it better?",
  4: "You had a great experience. Let us know what you liked and what can be improved.",
  5: "You had an excellent experience. We're thrilled! Tell us what stood out, and if there's anything you'd like to see."
};

const ratingDescriptions: { [key: number]: string } = {
  1: "Awful",
  2: "Below Average",
  3: "Just Okay",
  4: "Very Good",
  5: "Excellent"
};

const FeedbackPage: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const { userEmail } = useContext(StateAccessor);
  const characterLimit = 1500;

  const sendFeedback = async () => {
    if (!feedbackSent) {
      const description = `Rating: ${rating}\nAdditional info: ${feedbackText}`;
      const templateParams = {
        feedback_type: 'rating',
        description: description,
        user_email: userEmail,
      };
  
      try {
        await emailjs.send(
          CODITIONING_OUTLOOK_SERVICE_ID,
          FEATURE_FEEDBACK_TEMPLATE_ID,
          templateParams,
          EMAIL_JS_PUBLIC_KEY,
        );
        alert('Thank you for your feedback!');
        setShowForm(false);
        setRating(0);
        setFeedbackText('');
        setFeedbackSent(true); 
      } catch (error) {
        console.error('Failed to send feedback email. Error: ', error);
      }
    } else {
      alert('Feedback has already been sent.');
    }
  };
  

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendFeedback();
  };

  const onRatingSubmit = () => {
    if (rating > 0) {
      sendFeedback();
    } else {
      alert('Please select a rating before submitting.');
    }
  };
  

  const handleFeedbackTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= characterLimit) {
      setFeedbackText(event.target.value);
    }
  };

  const resetForm = () => {
    setShowForm(false);
    setFeedbackText(''); 
  };

  return (
    <div className="site-features-feedback-wrapper bg-cover bg-center" style={{ backgroundImage: `url(${feedback_background})` }}>
      <div className="text-center p-4">
        <h1 className="text-2xl font-bold">Rate Your Experience</h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={feedbackimg} alt="feedback" style={{ maxWidth: '60px', height: '60px' }} />
        </div>
        <div className="flex justify-center items-center my-4">
          {[...Array(5)].map((_, index) => (
            <FaStar
              key={index}
              className={`mx-1 cursor-pointer ${rating > index ? 'text-yellow-400' : 'text-white'}`}
              size="2em"
              onClick={() => setRating(index + 1)}
            />
          ))}
        </div>
        <p className="text-lg">{rating} Star {rating > 0 ? `- ${ratingDescriptions[rating]}` : ''}</p>
        {showForm ? (
          <div className="p-4">
            <form onSubmit={onSubmit} className="flex flex-col">
              <div className="relative">
                <textarea
                  name="additionalInfo"
                  rows={5}
                  className="border p-2 w-full pr-16" // Add right padding to reserve space for the character count
                  placeholder={rating > 0 ? placeholderTexts[rating] : 'Select a rating'}
                  required
                  value={feedbackText}
                  onChange={handleFeedbackTextChange}
                />
                <div className="absolute bottom-0 right-0 text-sm p-2">{feedbackText.length}/{characterLimit}</div>
              </div>
              <div className="mt-2">
                <button
                  type="button"
                  className="bg-gray-500 text-white p-2 rounded mr-2"
                  onClick={resetForm} 
                >
                  Go Back
                </button>
                <button
                  type="submit"
                  className={`bg-blue-500 text-white p-2 rounded ${feedbackText.length > characterLimit ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={feedbackText.length > characterLimit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="h:fit-content">
            <button
              className="bg-blue-500 text-white p-2 rounded"
              onClick={() => setShowForm(true)}
              title="Share Additional Feedback"
            >
              Share Additional Feedback (Optional)
            </button>
            <button
              className="bg-blue-500 text-white p-2 rounded"
              onClick={onRatingSubmit}
              title="Submit Rating"
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackPage;