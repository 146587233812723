import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/HomePage.css";
import DiscoverKnowledge from "../img/features_img/DiscoverWeaknessesFromFruitOfForbidden.webp";
import AiCoachImg from "../img/features_img/AiCoach.webp";
import AiInterviewerImg from "../img/features_img/AiInterviewer.webp";
import AcceleratedLearning from "../img/features_img/AcceleratedLearning.webp";
import CoachingImg from "../img/learning_images/coaching-cover-art-warrior-being-coached.webp";
import FAQ from "./FAQ";
import { CRASH_COURSE_PAGE_PATH, DIAGNOSTICS_PAGE_PATH, HUMAN_INTERVIEWER_COACH_PATH, LEARNING_PAGE_PATH, MOCK_INTERVIEW_PAGE_PATH } from "../utils/Constants";
import { LearningComponents } from "./GenericLearningHome";
import TargetTechEmployersIcon from "./TargetTechEmployersIcon";
import { BentoBoxItem } from "./BentoBox";
import BentoBox from "./BentoBox";
import TestimonialConfig from "./TestimonialConfig";

const MedievalTailorCustomLearningPathImg = "https://coditioning3.wordpress.com/wp-content/uploads/2023/12/dallc2b7e-2023-12-09-22.36.36-a-scene-set-in-a-medieval-shop-that-sells-weapons-and-clothing-inspired-by-the-dragons-dogma-theme.-it-features-a-masculine-warrior-being-measured-b.png";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  // const { userId } = useContext(StateAccessor);


  const handleStartJourney = () => {
    navigate(LEARNING_PAGE_PATH);
  };

  const handleNavigateToDiagnosticTests = () => {
    const url = `${DIAGNOSTICS_PAGE_PATH}?activeComponent=${encodeURIComponent(LearningComponents.IdentifyKnowledgeGaps)}`;
    navigate(url);
  }

  const handleNavigateToCustomLearningPath = () => {
    const url = `${LEARNING_PAGE_PATH}?activeComponent=${encodeURIComponent(LearningComponents.LearningPath)}`;
    navigate(url);
  }

  const handleNavigateToMockInterviews = () => {
    const url = `${HUMAN_INTERVIEWER_COACH_PATH}`;
    navigate(url);
  }

  const handleNavigateToCrashCoursesPage = () => {
    navigate(CRASH_COURSE_PAGE_PATH);
  }

  const handleNavigateToHumanInterviewerCoach = () => {
    navigate(HUMAN_INTERVIEWER_COACH_PATH);
  }

  const items: BentoBoxItem[] = [
    {
      id: 1,
      imgSrc: AcceleratedLearning,
      altText: "Accelerated Learning",
      title: "Accelerated learning with neuroscience optimised crash courses",
      description: "Learn faster and retain what you learn",
      linkText: "Explore our crash courses",
      linkAction: handleNavigateToCrashCoursesPage,
    },
    {
      id: 2,
      imgSrc: DiscoverKnowledge,
      altText: "Discover Knowledge",
      title: "Interview Readiness Checks",
      description: "Get feedback on your strengths and weaknesses; know what to focus on",
      linkText: "Try them out",
      linkAction: handleNavigateToDiagnosticTests,
    },
    {
      id: 3,
      imgSrc: AiCoachImg,
      altText: "AI Assisted Learning",
      title: "AI Assisted Learning",
      description: "Helps when you're stuck, detects bugs, reviews code etc",
      linkText: "Learn with AI on your shoulder",
      linkAction: handleStartJourney,
    },
    {
      id: 4,
      imgSrc: MedievalTailorCustomLearningPathImg,
      altText: "Custom Learning Path",
      title: "Learning Tailored to You",
      description: "One size doesn't fit all, learning tailored to your specific needs",
      linkText: "Create your custom learning path",
      linkAction: handleNavigateToCustomLearningPath,
    },
    {
      id: 5,
      imgSrc: CoachingImg,
      altText: "Coaching",
      title: "Coaching & Mock Interviews",
      description: "Learn from experts & simulate reality",
      linkText: "Get truly interview-ready",
      linkAction: handleNavigateToHumanInterviewerCoach,
    },
    {
      id: 6,
      imgSrc: AiInterviewerImg,
      altText: "AI Mock Interviewer",
      title: "AI Mock Interviewer",
      description: "Ai-driven mock interviews to help you practice on demand",
      linkText: "Try a mock interview with AI",
      linkAction: handleNavigateToMockInterviews,
    },
  ];

  const [openedFaq, setOpenedFaq] = useState<string | null>(null);
  const faqs = [
    {
      question: "How is Coditioning different from other platforms?",
      answerHtml:
        `
        <p>At Coditioning, our objective is to ensure you are truly interview-ready, meaning no surprises on interview day. We've observed that strategies employed by candidates such as "solve one problem a day" or grind as many playlists as possible leaves them remarkably unprepared for real interviews, and means they end up using their first 5-10 real interviews learning how to ace interviews. This is wasteful, as second chances at dream jobs may not come by easily. Here's how we stand out:</p>
        <ul>
            <li><strong>Minimal Effort & Time:</strong> Our platform is crafted for efficiency, enabling you to learn with minimal effort and time. Recognizing the escalating difficulty of algorithm and data structure interviews, due to their use as a filtering mechanism, our goal is to get you off the platform ASAP, freeing you to focus on what's truly important in your life.</li>
            <br/>
            <li><strong>Neuroscience:</strong> Leveraging insights from neuroscience, we've designed our platform to align with how the human brain learns best. This approach helps you learn faster and actually retain information, eliminating the need to re-solve problems you've already encountered.</li>
            <br/>
            <li><strong>Interview-Readiness:</strong> We focus on developing your interviewing skills, ensuring you're ready for the real deal. This means being comfortable engaging with interviewers (pushing back if you need to), speed, being comfortable with unfamiliar problems etc.</li>
            <br/>
            <li><strong>Fun:</strong> We strive to make your learning journey enjoyable and continue to gamify the site. Our challenges and resources are specifically designed to prevent frustration and demotivation. We believe learning should be fun.</li>
            <br/>
            <li><strong>Assistance:</strong> You're never alone with support from our community, AI-assistant, and the Coditioning team. We encourage you to take all the help you can get to thrive in the competitive world of software engineering interviews.</li>
        </ul>
        `
    },
    {
      question: "How can I get help when I'm stuck on a problem?",
      answerHtml:
        `<p>We provide solutions that are easy to understand, offering both quick solutions and detailed walkthroughs of the thought process. If you need further assistance, our AI assistant is on hand to help with detecting bugs, reviewing your code, and answering any questions you might have. Additionally, the Coditioning team is always available to support you.</p>`,
    },
    {
      question: "Can you help with system design interviews?",
      answerHtml:
        `<p>Yes! We have system design learning material, and also offer coaching & mock interviews.</p>`,
    },
    {
      question: "Is there an online community & are you on any social media platforms?",
      answerHtml:
        `<p>Yes, join us on our <a href="https://discord.com/invite/eNYYvpRyBt" target="_blank" rel="noopener noreferrer">Discord</a>, where you can connect with thousands of peers, from newbies to industry veterans, and our Coditioning team, ready to support and answer your questions. Additionally, for valuable insights, news, and trend updates, subscribe to our <a href="https://www.youtube.com/channel/UCINStgVxsKwQAV6XMWW-nDA" target="_blank" rel="noopener noreferrer">YouTube channel</a>.</p>
        `,
    },
  ];

  const testimonials = [
    {
      id: 1,
      text: "“Despite having a few offers, none were from my dream companies. I kept getting rejections at final rounds for the more competitive roles (think quant firms and FAANG). Coditioning's coaching upgraded my skills and problem-solving approach, helping me ace my Jane Street interview! I'm beyond thrilled and highly recommend them!”",
      name: "Nish.A",
      jobTitle: "Graduate Software Engineer",
      companyLogo: "https://coditioning3.files.wordpress.com/2024/03/jane_street_capital_logo.svg_.png",
      companyAlt: "Jane Street Logo",
    },
    {
      id: 2,
      text: "“When I heard of Coditioning, I certainly wasn't ready for my Meta phone interview, which was in 7 days. They assessed my strengths and weaknesses and gave me a 50% chance of passing at the time. Then, through their tailored learning path, one coaching, and two mock interview sessions, I made a lot of improvements. As of 2 days before the interview, they gave me an 80% chance of passing. I ended up clearing the phone screen, and definitely won't have been as prepared without Coditioning”",
      name: "Jacob.J",
      jobTitle: "Software Engineer (E4)",
      companyLogo: "https://coditioning3.files.wordpress.com/2024/03/meta-logo.png",
      companyAlt: "Meta Logo",
    },
    {
      id: 3,
      text: "“I used the mock interview service, and I don't think I would have cleared my Meta coding round if I didn't. My coach helped me see the blind spots in my interviewing technique, and I was able to adapt it to help me minimise mistakes and handle problems I haven't seen before, which was my main struggle”",
      name: "Emri",
      jobTitle: "Machine Learning Engineer (E4)",
      companyLogo: "",
      companyAlt: "",
    },
    {
      id: 4,
      text: "“I was getting job  interviews despite the tough job market, but I kept getting rejected. I had an initial diagnostic mock interview, and got feedback on what to work, and used the AI interviews to practice in-between sessions with the Coditioning interviewers. I'm still in the process for Amazon but have cleared the OA and the first round of interviews, and I feel more confident this time round. The feedback was very detailed, and they provided video recordings of the mock interviews, so I could see my bad habits. They were also happy to respond to my messages outside sessions, worth every penny”",
      name: "Nathan",
      jobTitle: "SDE II",
      companyLogo: "",
      companyAlt: "",
    },
  ];
  const toggleFaq = (question: string) => {
    setOpenedFaq(openedFaq == question ? null : question);
  };
  const ITEMS_PER_PAGE = 4;
  const TESTIMONIALS_PER_PAGE = 2;
  // Pagination for FAQs
  const [faqPage, setFaqPage] = useState(0);
  const handleNextFaqPage = () => {
    setFaqPage((prevPage) => {
      const maxPage = Math.ceil(faqs.length / ITEMS_PER_PAGE) - 1;
      return prevPage < maxPage ? prevPage + 1 : prevPage;
    });
  };

  const handlePreviousFaqPage = () => {
    setFaqPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage));
  };

  const paginatedFaqs = faqs.slice(faqPage * ITEMS_PER_PAGE, (faqPage + 1) * ITEMS_PER_PAGE);


  // Pagination for Testimonials
  const [testimonialPage, setTestimonialPage] = useState(0);
  const handleNextTestimonialPage = () => {
    setTestimonialPage((prevPage) => {
      const maxPage = Math.ceil(testimonials.length / TESTIMONIALS_PER_PAGE) - 1;
      return prevPage < maxPage ? prevPage + 1 : prevPage;
    });
  };

  const handlePreviousTestimonialPage = () => {
    setTestimonialPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage));
  };

  const paginatedTestimonials = testimonials.slice(testimonialPage * TESTIMONIALS_PER_PAGE, (testimonialPage + 1) * TESTIMONIALS_PER_PAGE);

  const [activeStates, setActiveStates] = useState<boolean[]>(
    new Array(items.length).fill(false)
  );

  const handleClick = (id: number) => {
    // Update the active state for the clicked item
    setActiveStates((prevActiveStates) =>
      prevActiveStates.map((active, idx) => (items[idx].id === id ? !active : active))
    );
  };

  return (
    <>
      {/* <Sidebar enableMinification={true} userId={userId}/> */}
      <div className="dashboard-container">
        <section className="section1">
          <div className="first-row">
            <div className="webStatement">
              <h1 className="Home-header">Optimize your SWE Interview Performance, Land your Dream Job</h1>
              <h3 className="Home-h3">If the below resonates with you, we can help:</h3>
              <ul className="bulletpoints">
                <li>🏆 "I'm aiming for FAANG+ roles"</li>
                <li>💡 "I suck at coming up with optimal solutions"</li>
                <li>⏰ "I've got an interview coming up"</li>
                <li>👨‍💻 "I need to learn data structures & algorithms"</li>
                <li>🧠 "I keep forgetting what I've learned"</li>
                <li>📝 "I need an efficient and structured study plan"</li>
                <li>❓ "I can't solve unfamiliar problems"</li>
                <li>⏰ "I can't solve problems within the time limit"</li>
                <li>🪲 "I always have bugs or miss edge cases"</li>
                <li>🤔 "I get brain freezes during interviews"</li>
                <li>⚙️ "I need to master system design"</li>
                <li>🆘 "I need help"</li>
              </ul>
              <div className="btn-container">
                <button className="home-page-secondary-call-to-action-btn" onClick={handleNavigateToDiagnosticTests}>
                  Assess your Interview Readiness
                </button>

                <button
                  className="home-page-primary-call-to-action-btn"
                  onClick={handleNavigateToMockInterviews}
                >
                  Take a Mock Interview <span className="subtitle">Practice Under Realistic Conditions</span>
                </button>
              </div>
            </div>
            <TargetTechEmployersIcon
              width="60%"
              minWidth='300px'
              title="Our users have received offers from:"
            />
          </div>
        </section>

        <section className="section2">
          <BentoBox items={items} activeStates={activeStates} handleClick={handleClick} />
        </section>

        <section className="section3">
          <div className="leftSideSection3">
            <TestimonialConfig testimonials={paginatedTestimonials} />
            <div className="pagination-controls">
              <button
                onClick={handlePreviousTestimonialPage}
                disabled={testimonialPage === 0}
              >
                Prev
              </button>
              <button
                onClick={handleNextTestimonialPage}
                disabled={(testimonialPage + 1) * TESTIMONIALS_PER_PAGE >= testimonials.length}
              >
                Next
              </button>
            </div>
          </div>
          <div className="rightSideSection3">

            <div className="faq-container">
              <div className="FAQ">
                <h3>FAQs:</h3>
                <div className="questions">
                  {paginatedFaqs.map((faq, index) => (
                    <FAQ
                      key={index}
                      question={faq.question}
                      answerHtml={faq.answerHtml}
                      isOpen={openedFaq === faq.question}
                      toggle={() => toggleFaq(faq.question)}
                    />
                  ))}
                </div>
              </div>
              <div className="pagination-controls">
                <button onClick={handlePreviousFaqPage} disabled={faqPage === 0}>
                  Prev
                </button>
                <button onClick={handleNextFaqPage} disabled={(faqPage + 1) * ITEMS_PER_PAGE >= faqs.length}>
                  Next
                </button>
              </div>
            </div>


          </div>
        </section>

      </div>
    </>
  );
};

export default HomePage;
