import React, { useContext, useReducer, useEffect, useMemo } from 'react';
import { FaCode, FaBook, FaVideo, FaPencilAlt } from 'react-icons/fa';
import Modal from '../utils/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  JOURNEY_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  LEARNING_PAGE_PATH,
} from '@/utils/Constants';
import Sidebar from '../utils/SideBar';
import { TaskProps, TaskStatus, tasksData } from './models/Task';
import StateAccessor from '@/StateAccessor';

const TaskListItem = React.memo<{
  task: TaskProps;
  index: number;
  onClick: (index: number) => void;
}>(({ task, index, onClick }) => {
  const getIcon = (title: string): React.ReactNode => {
    const icons: { [key: string]: React.ReactNode } = {
      'Coding Challenge': <FaCode className="text-green-500" />,
      'Read a Document': <FaBook className="text-blue-500" />,
      'Watch a Video': <FaVideo className="text-red-500" />,
      'Take Quiz': <FaPencilAlt className="text-yellow-500" />,
    };

    return icons[title] || null;
  };

  const icon = useMemo(() => getIcon(task.title), [task.title]);

  const statusStyle = {
    [TaskStatus.Completed]: 'bg-green-200',
    [TaskStatus.InProgress]: 'bg-yellow-200',
    [TaskStatus.Locked]: 'bg-gray-200',
  }[task.status];

  return (
    <div
      className={`flex w-full items-center p-3 border rounded-[11px] shadow-sm space-x-2 ${statusStyle} cursor-pointer`}
      onClick={() => onClick(index)}
    >
      <span className="text-sm font-medium mr-2">Task {index + 1}</span>
      <span className="text-lg font-semibold flex-1">
        {icon} {task.title}
      </span>
      <span className="text-sm">{task.points >= 0 ? '+' : ''}{task.points}xp</span>
      <span className={`badge ${statusStyle}`}>{task.status}</span>
    </div>
  );
});

const TaskModal: React.FC<{
  task: TaskProps;
  onClose: () => void;
  onNext: () => void;
}> = ({ task, onClose, onNext }) => {
  return (
    <Modal
      content={
        <div>
          <h2 className="text-xl font-bold">{task.title}</h2>
          <p>Status: {task.status}</p>
          <p>Points: +{task.points}xp</p>
          <p>Task content goes here...</p>
          <div className="flex justify-center items-center">
            <button
              className="mt-4 bg-pink-500 text-white py-2 px-4 rounded"
              onClick={onNext}
            >
              Next
            </button>
          </div>
        </div>
      }
      closeDisabled={false}
      onClose={onClose}
    />
  );
};

type TaskState = {
  tasks: TaskProps[];
  selectedTaskIndex: number | null;
  showModal: boolean;
  showCompletedModal: boolean;
  hasAlreadyCompleted: boolean;
};

type TaskAction =
  | { type: 'SET_TASKS'; payload: TaskProps[] }
  | { type: 'SET_SELECTED_TASK_INDEX'; payload: number | null }
  | { type: 'SET_SHOW_MODAL'; payload: boolean }
  | { type: 'SET_SHOW_COMPLETED_MODAL'; payload: boolean }
  | { type: 'SET_HAS_ALREADY_COMPLETED'; payload: boolean };

const taskReducer = (state: TaskState, action: TaskAction): TaskState => {
  switch (action.type) {
    case 'SET_TASKS':
      return { ...state, tasks: action.payload };
    case 'SET_SELECTED_TASK_INDEX':
      return { ...state, selectedTaskIndex: action.payload };
    case 'SET_SHOW_MODAL':
      return { ...state, showModal: action.payload };
    case 'SET_SHOW_COMPLETED_MODAL':
      return { ...state, showCompletedModal: action.payload };
    case 'SET_HAS_ALREADY_COMPLETED':
      return { ...state, hasAlreadyCompleted: action.payload };
    default:
      return state;
  }
};

const Task: React.FC = () => {
  const { userId } = useContext(StateAccessor);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const phaseTitle = query.get('title') || 'Default Phase Title';

  const [state, dispatch] = useReducer(taskReducer, {
    tasks: tasksData,
    selectedTaskIndex: null,
    showModal: false,
    showCompletedModal: false,
    hasAlreadyCompleted: localStorage.getItem('tasks-completed') === 'true',
  });

  const { tasks, selectedTaskIndex, showModal, showCompletedModal, hasAlreadyCompleted } = state;
  const isTaskSelectedAndModalShown = (index: number) => selectedTaskIndex === index && showModal;
  const TaskListItemMemoized = React.memo(TaskListItem);

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      try {
        const parsedTasks = JSON.parse(savedTasks);
        if (Array.isArray(parsedTasks) && parsedTasks.every((task) => typeof task === 'object')) {
          dispatch({ type: 'SET_TASKS', payload: parsedTasks });
        } else {
          throw new Error('Invalid tasks format');
        }
      } catch (error) {
        console.error('Error parsing tasks from localStorage:', error);
        dispatch({ type: 'SET_TASKS', payload: tasksData });
      }
    }
  }, []);

  useEffect(() => {
    const allTasksCompleted = tasks.every((task) => task.status === TaskStatus.Completed);
    if (allTasksCompleted && !showCompletedModal && !hasAlreadyCompleted) {
      dispatch({ type: 'SET_SHOW_COMPLETED_MODAL', payload: true });
      dispatch({ type: 'SET_SHOW_MODAL', payload: false });
      dispatch({ type: 'SET_HAS_ALREADY_COMPLETED', payload: true });
    }
  }, [tasks, showCompletedModal, hasAlreadyCompleted]);

  const handleCloseModal = () => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: false });
    dispatch({ type: 'SET_SHOW_COMPLETED_MODAL', payload: false });
  };

  useEffect(() => {
    localStorage.setItem('tasks-completed', String(hasAlreadyCompleted));
  }, [hasAlreadyCompleted]);

  const navigateToTask = (task: TaskProps) => {
    // TODO: navigate to actual task
    const basePath = `${LEARNING_PAGE_PATH}/courses/3/modules/0/submodules/0/units/500`;
    const url =
      task.title === 'Coding Challenge'
        ? `${basePath}/challenge/0?nextUrl=/phases&prevUrl=/phases&tasksIndex=${task.id - 1}`
        : '';

    if (url) navigate(url);
  };

  const handleTaskClick = (index: number) => {
    dispatch({ type: 'SET_SELECTED_TASK_INDEX', payload: index });
    const task = tasks[index];
    dispatch({ type: 'SET_SHOW_MODAL', payload: true });
    if (task.status !== TaskStatus.Completed) {
      const updatedTasks = tasks.map((t, i) =>
        i === index ? { ...t, status: TaskStatus.InProgress } : t
      );
      dispatch({ type: 'SET_TASKS', payload: updatedTasks });
      localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    }
    navigateToTask(task);
  };

  const handleNextClick = () => {
    // TODO: modify the code to properly mark the coding challenge task as completed
    // when the user returns to this page from the coding challenge page
    if (selectedTaskIndex !== null && selectedTaskIndex >= 0 && selectedTaskIndex < tasks.length) {
      const updatedTasks = tasks.map((task, idx) =>
        idx === selectedTaskIndex ? { ...task, status: TaskStatus.Completed } : task
      );
      dispatch({ type: 'SET_TASKS', payload: updatedTasks });
      localStorage.setItem('tasks', JSON.stringify(updatedTasks));
      moveToNextTaskOrFinish(updatedTasks);
    }
  };

  const moveToNextTaskOrFinish = (tasks: TaskProps[]) => {
    const isLastTask = selectedTaskIndex === tasks.length - 1;
    if (isLastTask) {
      navigateToEnd(tasks);
    } else {
      moveToNextTask(tasks);
    }
  };

  const navigateToEnd = (tasks: TaskProps[]) => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: false });
  };

  const moveToNextTask = (tasks: TaskProps[]) => {
    const nextIndex = (selectedTaskIndex as number) + 1;
    dispatch({ type: 'SET_SELECTED_TASK_INDEX', payload: nextIndex });
    dispatch({ type: 'SET_SHOW_MODAL', payload: true });

    const nextTask = tasks[nextIndex];
    if (nextTask.status !== TaskStatus.Completed) {
      const updatedTasks = tasks.map((task, idx) =>
        idx === nextIndex ? { ...task, status: TaskStatus.InProgress } : task
      );
      dispatch({ type: 'SET_TASKS', payload: updatedTasks });
      localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    } else {
      const updatedTasks = tasks.map((task, idx) =>
        idx === (selectedTaskIndex as number) ? { ...task, status: TaskStatus.Completed } : task
      );
      dispatch({ type: 'SET_TASKS', payload: updatedTasks });
      localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    }

    navigateToTask(nextTask);
  };

  return (
    <>
      {/* <Sidebar enableMinification={true} userId={userId} /> */}
      <div className="container mx-auto p-4 flex flex-col lg:flex-row">
        <div className="space-y-4 w-full ">
          <h1 className="flex flex-row items-center justify-center ml-[60px] border bg-[#FFD99F] m-2.5 text-center w-[200px] h-12 font-bold text-[large] rounded-[13px] border-solid border-[#FFD99F]">
          {phaseTitle}
          </h1>
          {tasks.map((task, index) => (
            <div
              className="flex w-auto bg-gradient-to-b from-[#000691] to-[#1D9100] p-[1.7px] rounded-[13px] "
              key={task.id}
            >
              <TaskListItemMemoized task={task} index={index} onClick={handleTaskClick} />
              {isTaskSelectedAndModalShown(index) && (
                <TaskModal task={task} onClose={handleCloseModal} onNext={handleNextClick} />
              )}
            </div>
          ))}
          {showCompletedModal && (
            <Modal
              content={
                <div>
                  <h2 className="text-xl font-bold">All tasks in this phase are completed!</h2>
                  <div className="flex mt-4 justify-center items-center">
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                      onClick={() => navigate(DASHBOARD_PAGE_PATH)}
                    >
                      Go to Dashboard
                    </button>
                    <button
                      className="bg-green-500 text-white py-2 px-4 rounded"
                      onClick={() => navigate(JOURNEY_PAGE_PATH)}
                    >
                      Go to Journey
                    </button>
                  </div>
                </div>
              }
              closeDisabled={false}
              onClose={handleCloseModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Task;