import React, { useEffect, useState, useRef } from "react";
import {
  ChatContainer,
  MainContainer,
  MessageList,
  Message,
  MessageInput,
  Button,
  ExpansionPanel,
  Avatar,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./styles.css";
import {
  sendAskInterviewerQuestionRequest,
  sendTelephoneOrOnsiteInteviewerMessageRequest,
  sendAskInterviewerForFeedbackRequest,
  sendTriggerInterviewQuestionRequest,
  sendAskInterviewerToDebugCodeRequest,
  sendAskCoachToDebugCodeRequest,
  sendAskCoachToSimplifyCodeRequest,
  sendAskInterviewerForHelpRequest,
  InterviewState,
  sendTelephoneOrOnsiteInteviewerUserQuickActionsRequest
} from "./externalLayerAccessor/BackEndRequests";
import { InterviewMode, ChatMessageSender, Phase, InterviewStatus, DONE_CODING_BUTTON_TEXT, SKIP_BUTTON_TEXT, ChatMessage } from "./utils/Constants"
import { InterviewSettings, InterviewStage } from "./pages/Settings"
import LillyInterviewerImg from './img/avatar/lilly.svg'

import {
  faForward,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PredefinedInteractionsWithAI {
  messageFromCandidate: string;
  callbackForGettingResponseViaHttpRequest: (
    interviewState: InterviewState
  ) => Promise<ChatMessage>;

  constructor(
    message: string,
    callback: (interviewState: InterviewState) => Promise<ChatMessage>,
  ) {
    this.messageFromCandidate = message;
    this.callbackForGettingResponseViaHttpRequest = callback;
  }
}

class PredefinedInteractionsWithAIForTelephoneOrOnsiteInterview {
  messageFromCandidate: string;
  callbackForGettingResponseViaHttpRequest: (
    queryParams: { [key: string]: string },
    interviewState: InterviewState,
  ) => Promise<ChatMessage>;

  constructor(
    message: string,
    callback: (queryParams: { [key: string]: string }, interviewState: InterviewState) => Promise<ChatMessage>,
    queryParams: { [key: string]: string }
  ) {
    this.messageFromCandidate = message;
    // @ts-ignore
    this.callbackForGettingResponseViaHttpRequest = callback.bind(this, queryParams);

  }

}

interface ChatWindowProps {
  messages: ChatMessage[];
  appendChatMsgToMessageHistory: (chatMsg: ChatMessage) => void;
  interviewMode: InterviewMode;
  currentInterviewPhaseIndex: number;
  handleInteviewerResponseForTelephoneOrOnsiteInterview: (interviewPhaseIndex: number, chatMsg: ChatMessage, chatHistoryUpdateCallback: (chatMsg: ChatMessage) => void) => void;
  interviewPhasesFromInterviewScript: Phase[] | null;
  interviewStatus: InterviewStatus;
  isCodeSubmitted: boolean;
  candidateName: string;
  userEmail: string;
  userId: number;
  interviewSettings: InterviewSettings;
  codingChallengeQuestion: string;
  candidatesCode: string;
  programmingLanguage: string;
  skipCurrentPhase: (currentIndex: number) => void;
}

const InterviewChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  appendChatMsgToMessageHistory,
  interviewMode,
  currentInterviewPhaseIndex,
  handleInteviewerResponseForTelephoneOrOnsiteInterview,
  interviewPhasesFromInterviewScript: interviewPhasesFromInterviewScript,
  interviewStatus,
  isCodeSubmitted,
  candidateName,
  userEmail,
  userId,
  interviewSettings,
  codingChallengeQuestion,
  candidatesCode,
  programmingLanguage,
  skipCurrentPhase,
}) => {
  const [isProcessingResponse, setIsProcessingResponse] = useState(false);
  const [messagesPseudoReference, setMessagesPseudoCustomRef] = useState<ChatMessage[]>(messages);
  const callbackForGettingResponseFnRef = useRef<
    | ((interviewState: InterviewState) => Promise<ChatMessage>)
    | ((queryParams: { [key: string]: string }, interviewState: InterviewState) => Promise<ChatMessage>)
    | null
  >(sendAskInterviewerQuestionRequest);

  // define a callback ref for handling the response, let it have a null default value
  const callbackForHandlingResponseFnRef = useRef<((responseChatMessage: ChatMessage) => void) | null>(null);
  const lastInterviewPhaseIndexSnaphotRef = useRef<number | null>(null);



  useEffect(() => {
    if (messagesPseudoReference.length > 0) {
      //console.log("in useEffect, and 'callbackForGettingResponseFnRef' has a value of " + callbackForGettingResponseFnRef.current);

      //synchronize messages with messagesPseudoReference
      const lastChatMessage = messagesPseudoReference[messagesPseudoReference.length - 1];
      appendChatMsgToMessageHistory(lastChatMessage); //to update the original messages array so they will eventually be in sync, and chat


      // at this point `messagesCustomRef` is up to date, but `messages` isn't guaranteed to be due to async nature of state setter

      // since a new msg was added, if it was from candidate, then request response from interviewer
      if (callbackForGettingResponseFnRef.current && lastChatMessage.sender === ChatMessageSender.Candidate) {
        const currentInterviewState: InterviewState = {
          chatHistory: messagesPseudoReference, // use messagesPseudoReference since it is guaranteed to be up to date
          candidatesCode: candidatesCode,
          programmingLanguage: programmingLanguage,
          isCodeSubmitted: isCodeSubmitted,
          candidateName: candidateName,
          codingChallengeQuestion: codingChallengeQuestion,
          interviewSettings: interviewSettings,
          telephoneOrOnsitePhase: getTelephoneOrOnsitePhase(interviewPhasesFromInterviewScript, lastInterviewPhaseIndexSnaphotRef.current),
          userEmail: userEmail,
          userId: userId,
        };

        // print to log telephoneOrOnsitePhase
        // print if interviewScript is non-empty i.e. has a value
        // if (interviewScript) {
        //   //console.log("***Chatwindow...interviewScript: is non-empty");
        // }
        //console.log("***Chatwindow...lastInterviewPhaseIndexSnaphotRef.current: " + JSON.stringify(lastInterviewPhaseIndexSnaphotRef.current));
        //console.log("***Chatwindow...telephoneOrOnsitePhase: " + JSON.stringify(currentInterviewState.telephoneOrOnsitePhase));


        console.log("Requesting response from interviewer...");
        //@ts-ignore
        callbackForGettingResponseFnRef.current(currentInterviewState)
          .then((responseChatMessage: ChatMessage) => {
            if (callbackForHandlingResponseFnRef.current) {
              callbackForHandlingResponseFnRef.current(responseChatMessage);
            }
            else {
              console.log("Error: callbackForHandlingResponseFnRef.current is null");
            }
          })
          .catch((error) => {
            console.log("Error: " + error);
          });

        setIsProcessingResponse(false);
        callbackForGettingResponseFnRef.current = null;


      }
    }
  }, [messagesPseudoReference]);

  const getTelephoneOrOnsitePhase = (interviewPhasesFromInterviewScript: Phase[] | null, phaseIndex: number | null) => {
    if (phaseIndex === -1) {
      console.warn("phaseIndex is -1, returning null value for telephoneOrOnsitePhase")
      return null;
    }

    if (!interviewPhasesFromInterviewScript || phaseIndex === null) {
      //log error
      console.error(`Error: interviewPhases are ${interviewPhasesFromInterviewScript} and lastInterviewPhaseIndexSnapshotRef.current is ${phaseIndex}`);
      return null;
    }

    // Check if the index is within the bounds of the array
    if (phaseIndex >= 0 &&
      phaseIndex < interviewPhasesFromInterviewScript.length) {
      return interviewPhasesFromInterviewScript[phaseIndex];
    } else {
      console.error(`Error: lastInterviewPhaseIndexSnapshotRef.current is ${phaseIndex} but interview phases length is ${interviewPhasesFromInterviewScript.length}`);
      return null;
    }
  }


  const predefinedInteractionsWithAIInterviewer = useRef([

    new PredefinedInteractionsWithAIForTelephoneOrOnsiteInterview(
      "Clarify question",
      sendTelephoneOrOnsiteInteviewerUserQuickActionsRequest,
      { "quickActions": "clarify_question" }
    ),
    new PredefinedInteractionsWithAIForTelephoneOrOnsiteInterview(
      "Hint please",
      sendTelephoneOrOnsiteInteviewerUserQuickActionsRequest,
      { "quickActions": "give_hint" }
    ),
    new PredefinedInteractionsWithAIForTelephoneOrOnsiteInterview(
      "Feedback so far",
      sendTelephoneOrOnsiteInteviewerUserQuickActionsRequest,
      { "quickActions": "give_feedback" }
    ),
    // add more predefined interactions here...
  ]).current;

  const predefinedInteractionsWithAICoach = useRef([
    new PredefinedInteractionsWithAI(
      "Can you spot the bug in my code?",
      sendAskInterviewerToDebugCodeRequest
    ),
    new PredefinedInteractionsWithAI(
      "Can you explain what's wrong with my solution?",
      sendAskCoachToDebugCodeRequest
    ),
    new PredefinedInteractionsWithAI(
      "Which test case(s) am I missing?",
      sendAskInterviewerQuestionRequest
    ),
    new PredefinedInteractionsWithAI(
      "What's the time and space complexity of my solution?",
      sendAskInterviewerQuestionRequest
    ),
    new PredefinedInteractionsWithAI(
      "Could you give me a moment to think about this?",
      sendAskInterviewerQuestionRequest
    ),
    new PredefinedInteractionsWithAI(
      "I'm not sure I understand the question, can you clarify?",
      sendAskInterviewerQuestionRequest
    ),
    new PredefinedInteractionsWithAI(
      "Give me a hint",
      sendAskInterviewerForHelpRequest
    ),
    new PredefinedInteractionsWithAI(
      "Ask me a follow-up question",
      sendTriggerInterviewQuestionRequest
    ),
    new PredefinedInteractionsWithAI(
      "Give me Feedback",
      sendAskInterviewerForFeedbackRequest
    ),
    new PredefinedInteractionsWithAI(
      "Can you simplify my code?",
      sendAskCoachToSimplifyCodeRequest
    )
  ]).current;

  //
  const predefinedInteractionsDuringTakeHomeAssessmentInterviewMode = useRef([
    new PredefinedInteractionsWithAI(
      "Review my code",
      sendAskInterviewerForFeedbackRequest
    ),
  ]).current;

  //!

  // write a function that responds to a new message from the candidate
  // and updates the chat history
  const handleMsgFromCandidateEnteredInChatWindow = (
    innerHtml: string, textContent: string,
  ) => {
    // select callback function based on interview mode
    const callbackFn = interviewSettings.interviewStage === InterviewStage.TakeHomeAssessment ? sendAskInterviewerQuestionRequest : sendTelephoneOrOnsiteInteviewerMessageRequest;
    handleMsgFromCandidate(
      textContent,
      callbackFn
    );
  };

  // write a function that appends a new message from the candidate to the chat history messagescustom ref 
  const appendChatMsgToMessageHistoryReference = (chatMsg: ChatMessage) => {
    setMessagesPseudoCustomRef([...messagesPseudoReference, chatMsg]);
  };

  const inteviewerResponseForTelephoneOrOnsiteInterviewHandler = (chatMsg: ChatMessage) => {
    if (lastInterviewPhaseIndexSnaphotRef.current !== null) {
      handleInteviewerResponseForTelephoneOrOnsiteInterview(lastInterviewPhaseIndexSnaphotRef.current, chatMsg, appendChatMsgToMessageHistoryReference);
    }else{
      console.error("lastInterviewPhaseIndexSnaphotRef.current is null, so cannot handle interviewer response");
    }
  };
  const handleMsgFromCandidate = (
    msgFromUser: string,
    callbackForGettingResponse:
      | ((interviewState: InterviewState) => Promise<ChatMessage>)
      | ((queryParams: { [key: string]: string }, interviewState: InterviewState) => Promise<ChatMessage>)
  ) => {
    setIsProcessingResponse(true);
    lastInterviewPhaseIndexSnaphotRef.current = currentInterviewPhaseIndex;
    /* Update Chat history with user message
    // this is done here to ensure regardless of how messages are added e.g. chatwindow or button clicks or any other source
    // chatHistory is updated in one place with user msg & interviewer response
    */

    //log the name of callbaback functionr eceived
    console.log(`callbackForGettingResponse is ${callbackForGettingResponse.name}`);
    const chatMessageFromCandidate = new ChatMessage(
      ChatMessageSender.Candidate,
      ChatMessageSender.Interviewer,
      new Date().toISOString(),
      msgFromUser
    );

    callbackForGettingResponseFnRef.current = callbackForGettingResponse; // set so that update to msg history will trigger request to backend for response via this function
    callbackForHandlingResponseFnRef.current = interviewSettings.interviewStage === InterviewStage.TelephoneOrOnsite ? inteviewerResponseForTelephoneOrOnsiteInterviewHandler : appendChatMsgToMessageHistoryReference;
    appendChatMsgToMessageHistoryReference(chatMessageFromCandidate); // will trigger useEffect to send request to backend for response
  };


  // write a function that handles a new message from the candidate
  // and updates the chat history
  const handlePredefinedInteraction = (
    predefinedInteraction: PredefinedInteractionsWithAI | PredefinedInteractionsWithAIForTelephoneOrOnsiteInterview
  ) => {
    handleMsgFromCandidate(
      predefinedInteraction.messageFromCandidate,
      predefinedInteraction.callbackForGettingResponseViaHttpRequest
    );
  };


  return (
    <div className="chat-window-container">
      <div className="chat-history-container">
        <MainContainer responsive>
          <ChatContainer>
            <MessageList
              typingIndicator={
                isProcessingResponse ? (
                  <TypingIndicator content="thinking" />
                ) : (
                  <TypingIndicator content="listening" />
                )
              }
            >
              {messages.map((msg, index) => (
                <Message
                  key={index}
                  model={{
                    // id: index,
                    message: msg.message,
                    sentTime: msg.timeSent,
                    sender: msg.sender,
                    direction: msg.sender === ChatMessageSender.Candidate ? "outgoing" : "incoming",// NEBUG: update all instantiations of chatMessage so that sender/recipient uses string from enum
                    position: "single",
                  }}
                >
                  {msg.sender === ChatMessageSender.Interviewer && <Avatar src={LillyInterviewerImg} name="Interviewer" />}
                  <Message.Header sender={msg.sender !== ChatMessageSender.Candidate ? msg.sender : "you"} />
                </Message>
              ))}
            </MessageList>
            {interviewSettings.interviewStage === InterviewStage.TelephoneOrOnsite && (
              <MessageInput
                placeholder={
                  interviewStatus === InterviewStatus.IN_PROGRESS
                    ? "Type your message..."
                    : "Start the interview to activate chat window"
                }
                autoFocus={true}
                onSend={handleMsgFromCandidateEnteredInChatWindow}
                attachButton={false}
                disabled={interviewStatus !== InterviewStatus.IN_PROGRESS}
              />
            )}
            {interviewSettings.interviewStage === InterviewStage.TakeHomeAssessment && (
              <MessageInput
                placeholder={`The text input is disabled for the ${InterviewStage.TakeHomeAssessment} stage, but you can use any available buttons`}
                autoFocus={true}
                onSend={handleMsgFromCandidateEnteredInChatWindow}
                attachButton={false}
                disabled= {true}
              />
            )}
          </ChatContainer>
        </MainContainer>
      </div>
      {interviewMode === InterviewMode.INTERVIEW && interviewStatus === InterviewStatus.IN_PROGRESS && interviewSettings.interviewStage === InterviewStage.TelephoneOrOnsite && (
        <>
          <ExpansionPanel open title="Quick Actions">
            <Button
              icon={<FontAwesomeIcon icon={faForward} />}
              onClick={() => skipCurrentPhase(currentInterviewPhaseIndex)}
              labelPosition="right"
              disabled={interviewStatus !== InterviewStatus.IN_PROGRESS}
            >
              {SKIP_BUTTON_TEXT}
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={faCheckCircle} />}
              onClick={() => handleMsgFromCandidateEnteredInChatWindow(DONE_CODING_BUTTON_TEXT, DONE_CODING_BUTTON_TEXT)}
              labelPosition="right"
              disabled={interviewStatus !== InterviewStatus.IN_PROGRESS}
            >
              {DONE_CODING_BUTTON_TEXT}
            </Button>
          </ExpansionPanel>
          <ExpansionPanel open title="Quick Questions">
            {predefinedInteractionsWithAIInterviewer.map(
              (predefinedInteraction, index) => (
                <Button
                  border
                  key={index}
                  onClick={() => handlePredefinedInteraction(predefinedInteraction)}
                >
                  {predefinedInteraction.messageFromCandidate}
                </Button>
              )
            )}

          </ExpansionPanel>
        </>
      )}
      {interviewMode === InterviewMode.INTERVIEW && interviewStatus == InterviewStatus.IN_PROGRESS && interviewSettings.interviewStage === InterviewStage.TakeHomeAssessment && (
        <ExpansionPanel open title="Quick Questions">
          {predefinedInteractionsDuringTakeHomeAssessmentInterviewMode.map(
            (predefinedInteraction, index) => (
              <Button
                border
                key={index}
                onClick={() => handlePredefinedInteraction(predefinedInteraction)}
              >
                {predefinedInteraction.messageFromCandidate}
              </Button>
            )
          )}

        </ExpansionPanel>
      )}
      {interviewMode === InterviewMode.COACH && interviewStatus == InterviewStatus.IN_PROGRESS && (
        <ExpansionPanel open title="Quick Questions">
          {predefinedInteractionsWithAICoach.map(
            (predefinedInteraction, index) => (
              <Button
                border
                key={index}
                onClick={() => handlePredefinedInteraction(predefinedInteraction)}
              >
                {predefinedInteraction.messageFromCandidate}
              </Button>
            )
          )}
        </ExpansionPanel>
      )}
    </div>
  );
};

export default InterviewChatWindow;

// NEBUG: LOW PRIORITY format messages from system, interviewer, candidate and ai coach so they have diff colours