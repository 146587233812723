import React from 'react';
import GenericLearningHome, { ActiveTiles, LearningComponents } from './GenericLearningHome';

// Define a wrapper component
const LearningMenu: React.FC = () => {
  // Specify the titles that should be active
  const activeTitles: ActiveTiles = new ActiveTiles([
    [LearningComponents.SystemDesignCourse, 1],
    [LearningComponents.LearningPath, 2],
    [LearningComponents.ExploreContent, 3],
    [LearningComponents.InstructorLedCrashCourses, 4],
    // Add more titles as needed
  ]);

  return (
    <div>
      {/* Pass activeTitles as a prop to GenericLearningHome */}
      <GenericLearningHome 
        activeTitles={activeTitles} 
      />
    </div>
  );
};

export default LearningMenu;

/*
interface Banner {
  text: string;
  url: string;
}
*/
