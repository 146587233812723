import React, { useState } from 'react';
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@mui/material';
import '../css/MultipleChoiceBox.css';
import CountdownTimer from './CountdownTimer';
import { DEFAULT_CONTENT_CONTAINER_MAX_HEIGHT_IN_PX, DEFAULT_CONTENT_CONTAINER_MIN_HEIGHT_IN_PX } from '../utils/Constants';

interface MultipleChoiceBoxProps {
  isSubmissionDisabled?: boolean;
  disabled?: boolean;
  timeLimitInMinutes: number;
  isTimerRunning: boolean;
  isTimerDisabled: boolean;
  setIsTimerRunning: (isTimerRunning: boolean) => void;
  timerComplete: () => void;
  unit_id: number;
  options: string[];
  firstChoiceMade: boolean;
  showMCQOPtions: boolean;
  handleMCQSubmission: (answer: string | null) => void;
  timerKey?: number;
  rightAnswer?: boolean | null;
  isAssessment?: boolean;
  handleShowMCQOptions: (showMCQOPtions: boolean) => void;
  handleUserDeclaresNotKnowingAnswer: () => void;

}

const MultipleChoiceBox: React.FC<MultipleChoiceBoxProps> = ({
  options,
  unit_id,
  timeLimitInMinutes,
  isTimerRunning,
  setIsTimerRunning,
  isTimerDisabled,
  rightAnswer,
  firstChoiceMade,
  showMCQOPtions,
  timerComplete,
  timerKey = 0,
  isAssessment = false,
  disabled = false,
  handleShowMCQOptions,
  handleMCQSubmission,
  handleUserDeclaresNotKnowingAnswer,
  isSubmissionDisabled = false,
}) => {
  const time_limit_in_seconds = timeLimitInMinutes * 60;
  const [selectedOption, setSelectedOption] = useState<string | null>(null);


  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitClick = () => {
    handleMCQSubmission(selectedOption);
  };


  const handleIDontKnowClick = () => {
    handleUserDeclaresNotKnowingAnswer();
  };


  if (disabled) {
    return null;
  }

  return (
    <div className="mcq-box" style={{ maxHeight: DEFAULT_CONTENT_CONTAINER_MAX_HEIGHT_IN_PX, minHeight: DEFAULT_CONTENT_CONTAINER_MIN_HEIGHT_IN_PX }}>
      <div className="mcq-navbar">
        <div className={`mcq-timer ${isAssessment ? 'extra-margin-right' : ''}`}>
          {!rightAnswer && (
            <CountdownTimer
              isTimerRunning={isTimerRunning}
              setIsTimerRunning={setIsTimerRunning}
              disabled={isTimerDisabled}
              unit_id={unit_id}
              onTimerComplete={timerComplete}
              duration={time_limit_in_seconds}
              disablePause={isAssessment}
              key={timerKey}
            />
          )}
        </div>
      </div>
      {!firstChoiceMade && (
        <div className="mcq-options-container">
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleIDontKnowClick}
              sx={{ margin: '0.5rem' }}
            >
              I Don't Know
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{ margin: '0.5rem' }}
              onClick={() => {
                handleShowMCQOptions(true);
              }}
            >
              Attempt
            </Button>
          </div>
        </div>
      )}
      {showMCQOPtions && (
        <div className="mcq-options-container">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="mcq-options"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio color="primary" />}
                  label={option}
                />
              ))}
            </RadioGroup>

            {!isSubmissionDisabled && (
              <Button
                color="primary"
                className="submit-button"
                variant="contained"
                onClick={handleSubmitClick}
                disabled={isSubmissionDisabled}
                style={{
                  width: 'fit-content'
                }}
              >
                Submit
              </Button>
            )}
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default MultipleChoiceBox;
