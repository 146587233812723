import React, { useState } from 'react';
import '../css/SubmissionResultSummary.css';
import ThinProgressBar from './ThinProgressBar';
import Progress from '../utils/Progress';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ChallengeType, ChallengeTestCase, SUPPORT_CONTACT_EMAIL, TestCaseExecutionResponse, ExecutionResult } from '../utils/Constants';
import { Mode, ModeValues } from './Courses';
const NEUTRAL_MESSAGE_COLOUR = '#9c27b0';


interface SubmissionExecutionOutputProps {
  submissionExecutionResults: TestCaseExecutionResponse | null;
  showSubmissionExecutionOutput: boolean;
  toggleSubmissionExecutionOutput: () => void;

}

const SubmissionExecutionOutput: React.FC<SubmissionExecutionOutputProps> = ({ submissionExecutionResults, showSubmissionExecutionOutput, toggleSubmissionExecutionOutput }) => {
  if (!submissionExecutionResults) {
    return null;
  }
  const [selectedTestCaseIndex, setSelectedTestCaseIndex] = useState<number | null>(null);

  const isTestCasePassed = (testCaseExecutionResult: ExecutionResult) => testCaseExecutionResult.expected_output === testCaseExecutionResult.actual_output;

  return (
    <div className='submission-execution-output-container'>
      <Button
        variant="outlined"
        className="outlined-button"
        sx={{ margin: '0.5rem' }}
        onClick={toggleSubmissionExecutionOutput}
      >
        {showSubmissionExecutionOutput ? (
          <>
            <VisibilityOffIcon style={{ marginRight: '8px' }} /> <span>Hide Submission Execution Output</span>
          </>
        ) : (
          <>
            <VisibilityIcon style={{ marginRight: '8px' }} /> <span>View Submission Execution Output</span>
          </>
        )}
      </Button>
      {showSubmissionExecutionOutput && submissionExecutionResults.results.testCases.map((testCaseExecutionResult, index) => (
        <div key={index} className="test-case-row">
          <div className="test-case-summary">
            <span>Test Case {index + 1}: {isTestCasePassed(testCaseExecutionResult) ? 'Passed' : 'Failed'}</span>
            <button onClick={() => setSelectedTestCaseIndex(index)}>View Details</button>
          </div>
          {selectedTestCaseIndex === index && (
            <div className="test-case-details">
              <p>Status: {testCaseExecutionResult.test_case_status}</p>
              <p>Expected Output: {testCaseExecutionResult.expected_output}</p>
              <p>Actual Output: {testCaseExecutionResult.actual_output}</p>
              <p>Stderr: <span className="stderr">{testCaseExecutionResult.stderr}</span></p>
              <p>Stdin: {testCaseExecutionResult.stdin}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

interface SubmissionResultSummaryProps {
  mode: Mode,
  submissionExecutionResults: TestCaseExecutionResponse | null;
  handleUnitAdvance: () => void;
  handleTryAgain: () => void;
  challenge_title: string;
  challengeType: ChallengeType;
  test_cases?: ChallengeTestCase[];
  rightAnswer?: boolean | null;
  close: () => void;
  passedTestCases: Progress;
  disabled?: boolean;
}

const SubmissionResultSummary: React.FC<SubmissionResultSummaryProps> = ({
  mode, // the state the user is in in the challenge
  submissionExecutionResults,
  passedTestCases,
  handleUnitAdvance,
  handleTryAgain,
  challenge_title,
  challengeType,
  rightAnswer,
  close: closeCheckSolution,
  disabled = false
}) => {
  if (disabled) {
    return null;
  }

  const [showSubmissionExecutionOutput, setShowSubmissionExecutionOutput] = React.useState<boolean>(false);

  // Determine the result message and color based on mode and rightAnswer
  let resultMessage = '';
  let resultColor = 'red';  // Default to red

  if (mode.isTerminal) {
    switch (mode.value) {
      case ModeValues.TIMED_OUT_MODE:
        resultMessage = "You've timed out on this challenge.";
        resultColor = NEUTRAL_MESSAGE_COLOUR
        break;
      case ModeValues.OUT_OF_LIVES_MODE:
        resultMessage = "You're out of lives.";
        resultColor = 'grey'
        break;
      case ModeValues.CORRECT_ANSWER_MODE:
        resultMessage = 'Correct, Well Done!';
        resultColor = 'green';  // Override to green for correct answer
        break;
      default:
        resultMessage = '';
    }
  } else {
    switch (mode.value) {
      case ModeValues.WRONG_ANSWER_MODE:
        resultMessage = 'Incorrect, please try again';
        resultColor = 'red';
        break;
      case ModeValues.CODE_EXECUTION_ERROR_MODE:
        resultMessage = 'An error occurred while executing your code, please try again and contact support if the problem persists.';
        resultColor = 'red';
        break;
      case ModeValues.CODE_EXECUTION_REJECTED_INSUFFICIENT_TOKENS_MODE:
        resultMessage = `You're out of code execution tokens, please contact support (${SUPPORT_CONTACT_EMAIL}) to help with renewal.`; // TODO: POST-MVP MAJOR ensure option to renew is added after mvp
        resultColor = NEUTRAL_MESSAGE_COLOUR;
        break;
      default:
        resultMessage = '';

    }
  }

  const toggleSubmissionExecutionOutput = () => {
    setShowSubmissionExecutionOutput(!showSubmissionExecutionOutput);
  }

  return (
    <div className='check-solution'>
      <h1 className='check-solution-title'>
        <b>{challenge_title}</b>
      </h1>
      {challengeType === ChallengeType.CODING && (
        <>
          <div className='check-solution-progress'>
            <h1 style={{ color: resultColor }}>{resultMessage}</h1>
            <ThinProgressBar progress={passedTestCases} contentName={'test cases passed'} />
          </div>

          <SubmissionExecutionOutput
            submissionExecutionResults={submissionExecutionResults}
            showSubmissionExecutionOutput={showSubmissionExecutionOutput}
            toggleSubmissionExecutionOutput={toggleSubmissionExecutionOutput}
          />
        </>
      )}

      {challengeType === ChallengeType.KNOWLEDGE && (
        <div className='check-solution-result'>
          <h1 style={{ color: resultColor }}>{resultMessage}</h1>
        </div>
      )}




      <div className="check-solution-buttons">
        <Button
          variant="outlined"
          className="outlined-button"
          sx={{ margin: '0.5rem' }}
          onClick={closeCheckSolution}
        >
          Back to Problem
        </Button>

        {mode.isTerminal && (
          <Button
            color="secondary"
            variant="contained"
            className="contained-button"
            sx={{ margin: '0.5rem' }}
            onClick={handleTryAgain}
          >
            Try Again
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          className="contained-button"
          sx={{ margin: '0.5rem' }}
          onClick={handleUnitAdvance}
        >
          Next Problem
        </Button>
      </div>
    </div>
  );
};

export default SubmissionResultSummary;
