import React from 'react';
import TargetTechEmployersIcon from '../TargetTechEmployersIcon';
import MultiModalTestimonials, { StyleType, Testimonial, TestimonialType } from '@/utils/MultiModalTestimonials';
import { TechCompanyLogoUrl } from '@/utils/Constants';

const sampleTestimonials: Testimonial[] = [
    {
        id: '1',
        userDisplayName: 'S.Jay',
        type: TestimonialType.TEXT,
        text: `My Google mocks were possibly the best investment I've made in my life. I received detailed feedback on bad signals I was giving off without knowing, and the sessions also helped me optimize for Google's interviewing style. I cleared the coding rounds with positive feedback in all rounds, and am now at the team matching stage!`,
        companyName: 'Google',
        companyLogoUrl: TechCompanyLogoUrl.GOOGLE,
    },
    {
        id: '2',
        userDisplayName: 'H.Yun',
        type: TestimonialType.TEXT,
        text: "I recently landed a job offer working on AI infrastructure. I just want to say thank you to all your resources and your mock feedback. I genuinely appreciate your time & effort!",
        companyName: 'AI Startup',
    },
    {
        id: '3',
        userDisplayName: 'R.Wama',
        type: TestimonialType.TEXT,
        text: 'Got the Amazon offer. After bombing a bunch of interviews, it became clear that just grinding LeetCode wasn’t cutting it. I must have solved around 500 problems but I still sucked at interviews, and never got feedback from recruiters. The detailed feedback from Coditioning mocks were invaluable and really helped me improve. Just glad to be done with interviews. Thanks',
        companyName: 'Amazon',
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
    },

];


interface SocialProofProps {
    // Custom props as needed
    disabled: boolean;
}

const SocialProof: React.FC<SocialProofProps> = (props) => {
    if (props.disabled) {
        return null;
    }
    return (
        <>
            <div className="social-proof-container landing-page-row">
                <h1 className='highlight-text'> Maximise your chances of securing offers from top employers:</h1>
                <TargetTechEmployersIcon
                    width="40%"
                    minWidth='300px'

                />

            </div>
            <div className="social-proof-container landing-page-row ">
                <h1 className='highlight-text'>Hone your interviewing skills through realistic Mocks & Coaching:</h1>
                {<MultiModalTestimonials
                    testimonials={sampleTestimonials}
                    itemsPerPage={6}
                    styleType={StyleType.STICKY_NOTE}
                />}
            </div>
        </>
    );
}

export default SocialProof;