import React, { useState } from 'react';
import '../css/Careers.css';
import { Link } from 'react-router-dom'; // Import Link if not already imported

const Careers: React.FC = () => {
  const [title] = useState('AI Prompt Engineer (Remote)');
  const [isHiring] = useState(true); // Add isHiring state. Set to true when hiring
  const description = `
  <p>If you excel at algorithm & data structure problems, enjoy tackling them, and are intrigued by the potential of generative AI, this might be for you.</p>
  
  <p>We are seeking content contributors willing to harness AI tools to produce technical content for our online platform. Your main responsibility will be to design & apply prompts that instruct AI in formulating coding challenges, solutions, and their corresponding test cases.</p>
  
  <h3>Projects you'll engage in:</h3>
  <ul>
    <li>Generate technical challenges tailored for software engineers.</li>
    <li>Compose prompts directing AI in the creation of coding challenges and solutions.</li>
    <li>Validate the accuracy and quality of generated content.</li>
  </ul>
  
  <h3>Candidate Requirements:</h3>
  <ul>
    <li>Proficiency in at least one programming language.</li>
    <li>Experience or <strong>interest</strong> in technical content creation.</li>
    <li>Eagerness to utilize AI tools for content generation.</li>
    <li>Availability for at least 8hrs weekly (flexible for one-off or long-term arrangements).</li>
    <li>Detail-oriented with an emphasis on content quality.</li>
    <li>Exceptional problem-solving skills, particularly in coding challenges.</li>
    <li>Proficient communication skills in English.</li>
  </ul>
  
  <h3>Desirable but not mandatory:</h3>
  <ul>
    <li>Experience in preparing for or participating in software engineering interviews.</li>
    <li>Familiar with multiple programming languages.</li>
  </ul>
  
  <h3>Compensation:</h3>
  <p>We offer competitive pay commensurate with experience and skills.</p>
  
  <h3>Application Process:</h3>
  <ul>
    <li>Detail the programming language(s) you're proficient in.</li>
    <li>Specify the number of hours you can dedicate weekly and for how many weeks (short-term commitments are welcome).</li>
    <li>Please attach your CV.</li>
  </ul>
`;

  const [email] = useState('coditioning@outlook.com');
  const [hasConsented, setHasConsented] = useState(false); // Consent state

const handleApplyClick = () => {
  if (hasConsented) {
    const emailTitle = !isHiring ? `Submitting Application for future internship opportunities` : `Applying for ${title}`;
    window.open(`mailto:${email}?subject=${encodeURIComponent(emailTitle)}`);
  } else {
    alert('Please consent to the privacy policy before applying');
  }
};

  return (
    <div className="careers">
      {!isHiring && <div className="careers__hiringStatus">Hiring is currently paused but you can apply for future openings</div>}
      <h2 className="careers__title">{title}</h2>
      <div className="careers__description" dangerouslySetInnerHTML={{ __html: description }} />

      {isHiring ?
        <button className="careers__applyButton" onClick={handleApplyClick}>
          Apply via Email
        </button>
        :
        <React.Fragment>
          <p className="careers__status">Hiring is currently paused. You can still submit your application for future consideration.</p>
          <button className="careers__applyButton" onClick={handleApplyClick}>
            Submit for future consideration
          </button>
        </React.Fragment>
      }

      <div className="careers__consent">
        <input type="checkbox" id="consent" name="consent" onChange={() => setHasConsented(!hasConsented)} />
        <label htmlFor="consent"> I have read and agree to the <Link to="/privacy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.</label>
      </div>

      <i> all application emails should be sent to {email}</i>
    </div>
  );
};

export default Careers;
