import React, { useState, useContext } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Modal from '../utils/Modal';
import { ChallengeType, InterviewType, Phase, UserExperienceFeedback } from '../utils/Constants';
import { storeUserExperienceFeedback } from '../externalLayerAccessor/BackEndRequests';
import StateAccessor from '../StateAccessor';
import "../css/PostInterviewActions.css"
import { Interview } from './InterviewSelectionPage';
import { InterviewStage } from './Settings';
import { ReactSyntaxHighlighterUtils } from '@/utils/HelperFunction';


interface InterviewSolutionsProps {
    interviewPhasesFromInterviewScript: Phase[];
}

/* NITO
(0) (I think i've only used code stub for language speicifc interviews) remove code stub for now, as it will become code stub by language, and don't think we need it!
(i) add css for the various headings
(ii) add formatting for code stubs, and sample code solutions (GPT should do this)
(iv) for now just display solution in pseudocode
(v) make note allow user to choose language for solution (interview script will have model answers for each solution)
(vi) exclude certain question types like "explain our code", have a whitelist of question types that can have solutions
() syntax highlighting one last chance with prism
(vii) handle take-home as well
*/

// Define a React functional component that takes in `interviewPhasesFromInterviewScript` as a prop
export const InterviewSolutions: React.FC<InterviewSolutionsProps> = ({ interviewPhasesFromInterviewScript }) => {
    const [activeChallengeIndex, setActiveChallengeIndex] = useState<number | null>(null);
    const [selectedSolutionIndex, setSelectedSolutionIndex] = useState<number | null>(null);
    const [showQuickExplanation, setShowQuickExplanation] = useState<boolean>(false);
    const [showThoughtProcessExplanation, setShowThoughtProcessExplanation] = useState<boolean>(false);

    const handleSolutionButtonClick = (index: number) => {
        setSelectedSolutionIndex(index === selectedSolutionIndex ? null : index);
    };

    const handleChallengeButtonClick = (index: number) => {
        setActiveChallengeIndex(index === activeChallengeIndex ? null : index);
        setSelectedSolutionIndex(null);
    };

    const handleQuickExplanationButtonClick = () => {
        setShowQuickExplanation(!showQuickExplanation);
    };

    const handleThoughtProcessExplanationButtonClick = () => {
        setShowThoughtProcessExplanation(!showThoughtProcessExplanation);
    };

    return (
        <div className='interview-solutions-container'>
            <div className='challenges-and-solutions-container'>
                <div className='challenges-container'>
                    {interviewPhasesFromInterviewScript.map((phase, index) => (
                        phase.challengeType === ChallengeType.CODING || phase.challengeType === ChallengeType.LOW_LEVEL_DESIGN || phase.challengeType === ChallengeType.Algorizing || phase.challengeType === ChallengeType.KNOWLEDGE ?
                        <button
                            key={phase.id}
                            className='toggle-button'
                            onClick={() => handleChallengeButtonClick(index)}
                        >
                            {phase.language || `Challenge ${index + 1}`}
                        </button>
                        : null
                    ))}
                    </div>
                <div className='solutions-container'>
                    {interviewPhasesFromInterviewScript.map((phase, index) => (
                        phase.challengeType === ChallengeType.CODING || phase.challengeType === ChallengeType.LOW_LEVEL_DESIGN || phase.challengeType === ChallengeType.Algorizing || phase.challengeType === ChallengeType.KNOWLEDGE ?
                            activeChallengeIndex === index &&
                            <div className='challenge-container' key={phase.id}>
                                <p><strong>Question</strong></p>
                                <p>{phase.challenge}</p>
                                {phase.codeStub && (
                                    <>
                                        <p><strong>Code</strong></p>
                                        <SyntaxHighlighter language={ReactSyntaxHighlighterUtils.getLanguageHighlightName(phase.language)} PreTag="div" style={solarizedlight}>                                                    
                                            {phase.codeStub}
                                        </SyntaxHighlighter>
                                    </>
                                )}                             
                                <div className='solution-buttons-container'>
                                    {phase.referenceSolutions && phase.referenceSolutions.map((referenceSolution, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handleSolutionButtonClick(index)}
                                            className={selectedSolutionIndex === index ? 'solution-button selected' : 'solution-button'}
                                        >
                                            {referenceSolution.referenceSolutionLabel ?? 'Solution'}
                                        </button>
                                    ))}
                                    {selectedSolutionIndex !== null && phase.referenceSolutions && (
                                        <div className="solution">
                                            {phase.referenceSolutions[selectedSolutionIndex].referenceSolutionIsCode && (
                                                <pre>
                                                    <SyntaxHighlighter language={ReactSyntaxHighlighterUtils.getLanguageHighlightName(phase.referenceSolutions[selectedSolutionIndex].referenceSolutionProgrammingLanguage)} PreTag="div" style={solarizedlight}>
                                                    {phase.referenceSolutions[selectedSolutionIndex].referenceSolution}
                                                    </SyntaxHighlighter>
                                                </pre>
                                            )}
                                            {!phase.referenceSolutions[selectedSolutionIndex].referenceSolutionIsCode && (
                                                <p>{phase.referenceSolutions[selectedSolutionIndex].referenceSolution}</p>
                                            )}
                                            {phase.referenceSolutions[selectedSolutionIndex].referenceSolutionExplanationQuick && (
                                                <>
                                                    <button onClick={handleQuickExplanationButtonClick} className='explanation-button explanation-button-quick'>
                                                        <strong>View Quick Explanation</strong>
                                                    </button>

                                                    {showQuickExplanation && (
                                                        <>
                                                            <div className='explanation-container'>
                                                                <p>{phase.referenceSolutions[selectedSolutionIndex].referenceSolutionExplanationQuick}</p>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                            }

                                            {phase.referenceSolutions[selectedSolutionIndex].referenceSolutionExplanationThoughtProcessHtml && (
                                                <>
                                                    <button onClick={handleThoughtProcessExplanationButtonClick} className='explanation-button explanation-button-thought-process'>
                                                        <strong>View Solution's Thought Process</strong>
                                                    </button>

                                                    {showThoughtProcessExplanation && (
                                                        <>
                                                            <div className='explanation-container'>
                                                                <p dangerouslySetInnerHTML={{ __html: phase.referenceSolutions[selectedSolutionIndex].referenceSolutionExplanationThoughtProcessHtml || '' }} />
                                                            </div>
                                                        </>

                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            : null
                    ))}
                </div>
            </div>
        </div>
    );
};

interface PostInterviewActionsProps {
    showPostInterviewActions: boolean;
    setShowPostInterviewActions: React.Dispatch<React.SetStateAction<boolean>>;
    interviewPhasesFromInterviewScript: Phase[];
    interview: Interview;
}

const PostInterviewActions: React.FC<PostInterviewActionsProps> = (
    {
        showPostInterviewActions,
        setShowPostInterviewActions,
        interviewPhasesFromInterviewScript,
        interview
    }) => {

    // user experience feedback form
    const { userEmail } = useContext(StateAccessor);
    const [userFeedbackRating, setUserFeedbackRating] = useState<number>(0);
    const [recommendPlatform, setRecommendPlatform] = useState<string>("");
    const [likedMostFeedback, setLikedMostFeedback] = useState<string>("");
    const [improvementFeedback, setImprovementFeedback] = useState<string>("");
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const handleSubmitUsersInterviewExperienceFeedback = async (e: React.FormEvent) => {
        e.preventDefault();

        const feedback = new UserExperienceFeedback(
            userFeedbackRating, recommendPlatform,
            likedMostFeedback, improvementFeedback,
            userEmail
        );

        storeUserExperienceFeedback(feedback);

        setIsSubmitted(true);

        setTimeout(() => {
            setUserFeedbackRating(0);
            setRecommendPlatform("");
            setLikedMostFeedback("");
            setImprovementFeedback("");
            setIsSubmitted(false);
            setShowForm(false);
        }, 3000);
    };


    // interview solutions
    const [showSolutions, setShowSolutions] = useState<boolean>(false);


    // misc
    const hideAllContent = () => {
        setShowForm(false);
        setShowSolutions(false);
    };

    const handleDisplayContent = (setShowContent: React.Dispatch<React.SetStateAction<boolean>>) => {
        hideAllContent();
        setShowContent(true);
    };

    return (
        <>
            {showPostInterviewActions && (
                <Modal
                    content={
                        <>
                            <div className="link-container">
                                <Button
                                    variant="contained"
                                    className="give-feedback-button"
                                    onClick={() => handleDisplayContent(setShowForm)}
                                    style={{ backgroundColor: '#28a5b3' }}
                                >
                                    Give us Feedback
                                </Button>

                                {interview.interviewType == InterviewType.TECHNICAL && interview.interviewStage == InterviewStage.TelephoneOrOnsite &&
                                    (<Button
                                        onClick={() => handleDisplayContent(setShowSolutions)} /*NITO-1 reveal solutions*/
                                        variant="contained"
                                        className="my-button"
                                        style={{ backgroundColor: 'black' }}
                                    >
                                        View Solution(s)
                                    </Button>)
                                }

                                <Button
                                    component={RouterLink}
                                    to="/app/interviewSelection"
                                    variant="contained"
                                    className="my-button"
                                >
                                    Practice More Mock Interviews
                                </Button>

                            </div>
                            {showSolutions && <InterviewSolutions interviewPhasesFromInterviewScript={interviewPhasesFromInterviewScript} />}
                            {showForm && (
                                <div className="post-interview-feedback-modal-container">
                                    {isSubmitted ? (
                                        <p>Thank you for your feedback!</p>
                                    ) : (
                                        <>
                                            <p>We value your feedback</p>
                                            <form onSubmit={(e) => handleSubmitUsersInterviewExperienceFeedback(e)}>
                                                <div>
                                                    <label>
                                                        Overall interview experience rating (1-5):
                                                        <span className="required-field">*</span>
                                                    </label>

                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max="5"
                                                        required
                                                        value={userFeedbackRating}
                                                        onChange={(e) => setUserFeedbackRating(Number(e.target.value))}
                                                    />
                                                </div>
                                                <div>
                                                    <label>
                                                        Would you recommend our platform to a friend? (Yes/No):
                                                        <span className="required-field">*</span>
                                                    </label>
                                                    <select
                                                        required
                                                        value={recommendPlatform}
                                                        onChange={(e) => setRecommendPlatform(e.target.value)}>
                                                        <option value="">Select...</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>
                                                        What can we improve to make your experience or the platform better?
                                                        <span className="optional-field">(Optional)</span>
                                                    </label>
                                                    <textarea
                                                        value={improvementFeedback}
                                                        onChange={(e) => setImprovementFeedback(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <label>
                                                        What did you like the most about the interview?
                                                        <span className="optional-field">(Optional)</span>
                                                    </label>
                                                    <textarea
                                                        value={likedMostFeedback}
                                                        onChange={(e) => setLikedMostFeedback(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <button type="submit">Submit Feedback</button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    }
                    onClose={() => setShowPostInterviewActions(false)}
                />
            )}
        </>
    );
};

export default PostInterviewActions;