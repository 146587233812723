import React from 'react';
import { Button } from '@mui/material';
import { Challenge, ChallengeType } from './Constants';

// NEBUG NITO 23/09/2023
interface ChallengeInteractiveMenuProps {
  challenge: Challenge;

  handleSkip?: () => void;
  showIDontKnow?: boolean;
  handleIDontKnow?: () => void;
  handleRevealSolution?: () => void;
  handlePrevious?: () => void;
  handleNext?: () => void;

  showTryAgain?: boolean;
  handleTryAgain?: () => void;
}

const buttonStyles = {
  flex: 1,
  margin: '0 5px',
  color: 'white'
};

const ChallengeInteractiveMenu: React.FC<ChallengeInteractiveMenuProps> = (
  { 
    challenge,
    handleSkip, 
    showIDontKnow = true , handleIDontKnow, 
    showTryAgain = false, handleTryAgain, 
    handleRevealSolution, handlePrevious, handleNext,
  
  }
  ) => {
  
  const shouldDisableIDontKnow = () => {
    return challenge.challenge_type === ChallengeType.TUTORIAL || challenge.challenge_type === ChallengeType.EXPLAIN_CODE;
  }

  const shouldDisableRevealSolution = () => {
    return challenge.challenge_type === ChallengeType.TUTORIAL || challenge.challenge_type === ChallengeType.EXPLAIN_CODE;
  }

  return (
    <div className="buttons-container">
      <div className="buttons-row">
        {handlePrevious && (
          <Button
            sx={{ ...buttonStyles, backgroundColor: '#9191e1', ':hover': { backgroundColor: '#6c6cdb' } }}
            className="interactive-button"
            onClick={handlePrevious}
          >
            <b>Previous</b>
          </Button>
        )}

        {handleSkip && (
          <Button
            sx={{ ...buttonStyles,backgroundColor: '#9191e1', ':hover': { backgroundColor: '#6c6cdb' }, }}
            className="interactive-button-skip"
            onClick={handleSkip}
          >
            <b>Skip Question</b>
          </Button>
        )} 

        {showIDontKnow && handleIDontKnow && (
          <Button
            sx={{ ...buttonStyles, backgroundColor: '#0097A9', ':hover': { backgroundColor: 'cyan' } }}
            className="interactive-button"
            onClick={handleIDontKnow}
            disabled={shouldDisableIDontKnow()}
          >
            <b>I don't know</b>
          </Button>
        )}

        {showTryAgain && handleTryAgain && (
          <Button
            sx={{ ...buttonStyles, backgroundColor: '#f9d9a8', color:'#464141', ':hover': { backgroundColor: '#fceec0' } }}
            className="interactive-button"
            onClick={handleTryAgain}
          >
            <b>Try Again</b>
          </Button>
        )}

        {handleRevealSolution && (
          <Button
            sx={{ ...buttonStyles, backgroundColor: 'mediumpurple', ':hover': { backgroundColor: '#7043DB' } }}
            className="interactive-button"
            onClick={handleRevealSolution}
            disabled={shouldDisableRevealSolution()}
          >
            <b>Reveal Solution</b>
          </Button>
        )}

        {handleNext && (
          <Button
            sx={{ ...buttonStyles, backgroundColor: '#9191e1', ':hover': { backgroundColor: '#6c6cdb' } }}
            className="interactive-button"
            onClick={handleNext}
          >
            <b>Next Unit</b>
          </Button>
        )}

      </div>
    </div>
  );
};

export default ChallengeInteractiveMenu;
