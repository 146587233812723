import React, { useContext } from 'react';
import { InterviewSolutions } from './PostInterviewActions';
import { Phase, CodeBlockByLanguage, ChallengeType, Unit, AccessLevel } from '../utils/Constants';
import '../css/SolutionContent.css';
import { renderContent } from '../utils/HelperFunction';
import StateAccessor from '@/StateAccessor';
import { userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import PremiumContentAccessDeniedMessage from '@/utils/PremiumContentAccessDeniedMessage';

interface SolutionContentProps {
  acceptableAnswers?: string[];
  challengeId: number;
  challengeTimeLimit: number;
  challengeTitle: string;
  challengeType: ChallengeType;
  solutionByLanguage?: CodeBlockByLanguage;
  unit: Unit;
}

const SolutionContent: React.FC<SolutionContentProps> = ({
  acceptableAnswers,
  challengeId,
  challengeTimeLimit,
  challengeTitle,
  challengeType,
  solutionByLanguage,
  unit,
}) => {

  const { userSubscriptionTier } = useContext(StateAccessor);

  const displayContent = (): boolean => {
    return (unit.access_level !== AccessLevel.Premium || userHasPremiumSubscriptionPrivilege(userSubscriptionTier));
  }

  return displayContent() ? (
    <div className='solution-content-container'>
      <>
        {(challengeType === ChallengeType.CODING || challengeType === ChallengeType.LOW_LEVEL_DESIGN || challengeType === ChallengeType.Algorizing) && solutionByLanguage && Object.keys(solutionByLanguage).length ? (
          <InterviewSolutions
            interviewPhasesFromInterviewScript={Object.entries(solutionByLanguage).map(([language, codeStub]) =>
              new Phase(
                challengeId,
                challengeTimeLimit,
                challengeTitle,
                challengeType,
                [],
                false,
                codeStub,
                language,
                []
              )
            )}
          />
        ) : null}

        {unit && (
          <>
            <div dangerouslySetInnerHTML={{ __html: unit.challenge.problem_statement }} />
            {renderContent('Quick', unit.explanations.quick_markdown, unit.explanations.quick_html)}
          </>
        )}
        {challengeType === ChallengeType.KNOWLEDGE && acceptableAnswers ? (
          <div>
            <div className='acceptable-answers-wrapper'>
              <h2 className="h2-acceptable-answers">Acceptable Answer(s)</h2>
              <ul>
                {acceptableAnswers.map((answer, index) => (
                  <li className='acceptable-answers-item' key={index}>{answer}</li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
        {challengeType !== ChallengeType.CODING && challengeType !== ChallengeType.LOW_LEVEL_DESIGN && challengeType !== ChallengeType.Algorizing && challengeType !== ChallengeType.KNOWLEDGE ? (
          <div>
            <div className='no-content-wrapper'>
              <h1>No Content Available</h1>
            </div>
          </div>
        ) : null}
      </>
    </div>
  ) : (
    <PremiumContentAccessDeniedMessage />
  );
};

export default SolutionContent;
