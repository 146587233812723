import React, { useContext, useEffect } from 'react';
import BookingAndPayment from './BookingAndPayment';
import FAQ from './FAQ';
import SocialProof from './SocialProof';
import { Button } from '@mui/material';
// import InfoIcon from '@mui/icons-material/Info';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaymentIcon from '@mui/icons-material/Payment';
import EditIcon from '@mui/icons-material/Edit';

import './css/HumanInterviewerCoach.css';
import { logToBackendLogFile } from '../../externalLayerAccessor/BackEndRequests';
import StateAccessor from '../../StateAccessor';
import { doNothing } from '@/utils/HelperFunction';
import FeatureGrid from './FeatureGrid';
import { ContactDetails } from '../Contact';


interface HumanInterviewPreparationProps {
    // Custom props as needed
}

interface MainMessageProps {
    // Custom props as needed
}

interface HowItWorksProps {
    // Custom props as needed
    disabled: boolean;
}

const MainMessage: React.FC<MainMessageProps> = (props) => {
    return (
        <div className="main-message-container landing-page-row">
            <h1>Don't Miss Out on Your Target Job for Preventable Reasons</h1>
            <h2>Avoid Surprises: Prove You Can Perform Under Interview Conditions</h2>
            <h3 className="highlight-text">Our Insights and Tailored Guidance Give You an Edge Over the Competition</h3>
            <div className='pain-points-container'>
                <div className="pain-points-container-item">💡 "I suck at coming up with optimal solutions"</div>
                <div className="pain-points-container-item">🧠 "I keep forgetting what I've learned"</div>
                <div className="pain-points-container-item">📝 "I need an efficient & structured study plan"</div>
                <div className="pain-points-container-item">🤔 "I get brain freezes during interviews"</div>
                <div className="pain-points-container-item">❓ "I can't solve unfamiliar problems"</div>
                <div className="pain-points-container-item">⏰ "I can't solve problems within the time limit"</div>
                <div className="pain-points-container-item">🐜 "I always have bugs or miss edge cases"</div>
                <div className="pain-points-container-item">💬 "I struggle with expressing my thoughts as code"</div>
                <div className="pain-points-container-item">👥 "I need help from someone who's been there and done that"</div>
                <div className="pain-points-container-item">🏆 "Not getting the offers I want, I need an edge"</div>
            </div>

            <div className='main-message-button-container'>
                <Button
                    className='silver-button'
                    variant="contained"
                    color="primary"
                    onClick={() => { doNothing() }}
                    disabled={false} // for the appearance not to be greyed out
                    style={{ 
                        cursor: 'default',
                        textTransform: 'none',
                    }}

                >
                    Join 1,000+ Happy Customers: who've rated us ⭐⭐⭐⭐⭐ (4.99 on average)
                </Button>

                {/* <Button
                    className='secondary-call-to-action-button'
                    variant="contained"
                    endIcon={<InfoIcon />}
                    onClick={() => { doNothing() }}
                    style = {{backgroundColor: 'grey'}}

                >
                    Pricing
                </Button> */}
            </div>


        </div>
    );
}

const HowItWorks: React.FC<HowItWorksProps> = (props) => {
    if (props.disabled) {
        return null;
    }
    return (
        <div className="how-it-works-container landing-page-row alternate-theme-container">
            <h1>How Scheduling Sessions Works</h1>

            <p>
                <AssignmentIcon /> <strong>Step 1:</strong> Book a session
                <ul>
                    <li><ScheduleIcon /> Each session is an <strong>hour long</strong>.</li>
                    <li> The booking process is separate from payment, so you can secure bookings for as many sessions as you want without payment</li>
                    <li> You can't book all sessions in one fell swoop i.e. you have to book each session individually.</li>
                    <li>
                        If you don't know how many sessions you want you can just book your first session, and add more later.
                    </li>
                    <li>
                        You will have the option to indicate the focus area of each session, e.g. coding, system design, behavioral, etc.
                    </li>
                    <li>
                        A booking confirmation email will be sent to you (check your spam folder if you don't see it).
                    </li>
                </ul>



                <PaymentIcon /> <strong>Step 2:</strong> Pay for your session(s) after booking.


            </p>

            <p><strong><em><EditIcon /> Note: You can always adjust your booked slots at no extra cost.</em></strong></p>
        </div>
    );
};


interface ContactSectionProps {
    // Custom props as needed
}

const ContactSection: React.FC<ContactSectionProps> = (props) => {
    return (
        <div className="contact-section-container landing-page-row">
            <h1>Still have questions?</h1>

            <div className='contact-section-child-container'>
                <div className="contact-section-image-container">
                    <figure><img src="https://coditioning3.files.wordpress.com/2024/01/brain-freeze.png?w=600" alt="ConfusedImage" /></figure>
                </div>
                <div className="contact-details">
                    {<ContactDetails />}
                </div>


            </div>



        </div >
    );
}

const scrollToFocusSection = () => {
    // Get the URL parameters
    const params = new URLSearchParams(window.location.search);
    const scrollTo = params.get('scrollTo');

    // If the scrollTo parameter exists, scroll to the specified section
    if (scrollTo) {
        const element = document.getElementById(scrollTo);
        if (element) {
            element.scrollIntoView();
        }
    }
};

const HumanInterviewerCoach: React.FC<HumanInterviewPreparationProps> = (props) => {
    const { userId } = useContext(StateAccessor);

    useEffect(() => {
        scrollToFocusSection();
        logToBackendLogFile(
            `Viewed HumanInterviewerCoach Component`,
            "info",
            userId);
    }, []);

    return (
        <div className="humanInterviewCoach">
            <MainMessage />
            <SocialProof disabled={false} />
            <FeatureGrid />
            <HowItWorks disabled={false} />
            <BookingAndPayment />
            <FAQ disabled={true} />
            <ContactSection />
        </div>
    );
}

export default HumanInterviewerCoach;
