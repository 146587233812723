// NEBUG: TODO: refactor so that the code is more modular, readable and maintainable
import React, { useState, useEffect, useRef, useContext } from "react";
import InterviewChatWindow from "../InterviewChatWindow";
import CodeEditor from "../CodeEditor";
import TopMenuBar from "../TopMenuBar";
import ChallengesTabbedWindow from "../ChallengesTabbedWindow";
// import OutputWindow from "../OutputWindow";
import FeedbackPage from "./FeedbackPage";
// import ConditioningTracker from "../ConditioningTracker";
import SettingsView, {
  InterviewSettings, InterviewerPersonality,
  InterviewStage, CompanyType, CandidateExperienceLevel, NUMBER_OF_INTERVIEWERS_DEFAULT,
  TargetRoleType, ProgrammingLanguage, getInterviewDifficultyAsInteger,
} from './Settings';
import StateAccessor from "../StateAccessor";
import Modal from "../utils/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { InterviewMode, Phase, ChatMessageSender, InterviewStatus, ChallengeType, CandidateAttemptEvaluationByPhaseIndex, TelephoneOrOnsiteInterviewState, TakeHomeAssessmentInterviewState, DONE_CODING_BUTTON_TEXT, CandidatesMessageIntent, SKIP_BUTTON_TEXT, SIGN_UP_PAGE_PATH, ChatMessage } from "../utils/Constants";
import {
  getChallengeDetails, logToBackendLogFile,
} from "../externalLayerAccessor/BackEndRequests";
import '../css/PostInterviewFeedback.css';
import { InterviewScript, SOFTWARE_ENGINEER_INTERVIEW_SCRIPTS_BY_COMPANY } from "../utils/InterviewScript";
import PostInterviewFeedback from "./PostInterviewFeedback";
import PostInterviewActions from "./PostInterviewActions";
import { Interview } from "./InterviewSelectionPage"; // NITO: move to constants
import { isUserSignedIn } from "../utils/HelperFunctions";
import '../css/InterviewPage.css'
type LocationState = {
  interviewMode: InterviewMode;
  interview: Interview;
};


const INITIAL_PHASE_INDEX = -1;

/*
Code Stubs
*/
const pythonDefaultCodeStub = "//insert your code here"; //TODO: replace with "def hello():\n\tprint('Hello world!')" once we fix issue that ensures code stubs are in the correct language upon initial load
const cppDefaultCodeStub = "#include<iostream>\n\nint main() {\n\tstd::cout << \"Hello world!\";\n\treturn 0;\n}";
const jsDefaultCodeStub = "console.log('Hello world!');";
const javaDefaultCodeStub = "public class HelloWorld {\n\tpublic static void main(String[] args) {\n\t\tSystem.out.println(\"Hello World!\");\n\t}\n}";
const goDefaultCodeStub = "package main\n\nimport \"fmt\"\n\nfunc main() {\n\tfmt.Println(\"Hello world!\")\n}";
const csharpDefaultCodeStub = "using System;\n\npublic class Program\n{\n\tpublic static void Main()\n\t{\n\t\tConsole.WriteLine(\"Hello world!\");\n\t}\n}";
const cDefaultCodeStub = "#include <stdio.h>\n\nint main() {\n\tprintf(\"Hello world!\");\n\treturn 0;\n}";
const typescriptDefaultCodeStub = "console.log('Hello world!');";
const rubyDefaultCodeStub = "puts \"Hello world!\"";
const swiftDefaultCodeStub = "print(\"Hello world!\")";
const kotlinDefaultCodeStub = "fun main() {\n\tprintln(\"Hello world!\")\n}";
const phpDefaultCodeStub = "<?php\n\necho \"Hello world!\";\n?>";
const scalaDefaultCodeStub = "object Main extends App {\n\tprintln(\"Hello world!\")\n}";
const rustDefaultCodeStub = "fn main() {\n\tprintln!(\"Hello world!\");\n}";
const haskellDefaultCodeStub = "main = putStrLn \"Hello world!\"";
const rDefaultCodeStub = "print(\"Hello world!\")";
const perlDefaultCodeStub = "print(\"Hello world!\")";

function getDefaultCodeStub(language: ProgrammingLanguage | undefined): string {
  switch (language) {
    case ProgrammingLanguage.Python:
      return pythonDefaultCodeStub;
    case ProgrammingLanguage.Cpp:
      return cppDefaultCodeStub;
    case ProgrammingLanguage.JavaScript:
      return jsDefaultCodeStub;
    case ProgrammingLanguage.Java:
      return javaDefaultCodeStub;
    case ProgrammingLanguage.Go:
      return goDefaultCodeStub;
    case ProgrammingLanguage.Csharp:
      return csharpDefaultCodeStub;
    case ProgrammingLanguage.C:
      return cDefaultCodeStub;
    case ProgrammingLanguage.Typescript:
      return typescriptDefaultCodeStub;
    case ProgrammingLanguage.Ruby:
      return rubyDefaultCodeStub;
    case ProgrammingLanguage.Swift:
      return swiftDefaultCodeStub;
    case ProgrammingLanguage.Kotlin:
      return kotlinDefaultCodeStub;
    case ProgrammingLanguage.Php:
      return phpDefaultCodeStub;
    case ProgrammingLanguage.Scala:
      return scalaDefaultCodeStub;
    case ProgrammingLanguage.Rust:
      return rustDefaultCodeStub;
    case ProgrammingLanguage.Haskell:
      return haskellDefaultCodeStub;
    case ProgrammingLanguage.R:
      return rDefaultCodeStub;
    case ProgrammingLanguage.Perl:
      return perlDefaultCodeStub;
    default:
      return pythonDefaultCodeStub; // default to Python if language not found
  }
}



const InterviewPage: React.FC = () => {

  const { userId, userName, userEmail } = useContext(StateAccessor);
  const navigate = useNavigate();
  // INITIALISE SETTINGS
  const location = useLocation(); // receive state from previous page
  /*
  The TypeScript cast as LocationState doesn't enforce any runtime checks or guarantees about the presence or type of these values; 
  it's merely a compile-time construct that tells TypeScript to treat location.state as if it were a LocationState. 
  If any of the properties are actually missing at runtime, TypeScript won't raise an error or stop the execution. 
  The application would still continue to run, possibly leading to unexpected behavior or errors later on in your component.
  */
  const locationState = location.state as LocationState;

  const {
    interviewMode,
    interview,
  } = locationState;

  if (!locationState) {
    console.error("No state passed to InterviewPage");
    return null;
  }

  if (!interviewMode || !interview) {
    console.error("Missing one or more state properties in InterviewPage");
    // print locationState
    //console.log("locationState: ", locationState);
    return null; // will prevent rendering of the page
  }

  const [interviewSettings, setInterviewSettings] = React.useState(
    new InterviewSettings(
      interview.interviewDifficulty,
      InterviewerPersonality.Fair,
      interview.interviewStage,
      CompanyType.GenericTechCompany, // NEBUG: deprecate or use value from interview object received via locationState
      CandidateExperienceLevel.Junior, // NEBUG: deprecate or use value from locationState
      TargetRoleType.SoftwareEngineer, //NITO: use value from locationState
      interview.interviewDurationInMinutes,
      interview.interviewDurationInMinutes,
      NUMBER_OF_INTERVIEWERS_DEFAULT
    )
  );

  const [isSettingsVisible, setIsSettingsVisible] = React.useState(true);




  // timer
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timerVisible, setTimerVisible] = useState(false);
  const TIMER_VISIBILITY_INTERVAL = 5000;
  const displayTimerTemporarily = () => {
    setTimerVisible(true);
    setTimeout(() => {
      setTimerVisible(false);
    }, TIMER_VISIBILITY_INTERVAL);
  };

  const [messages, setMessages] = React.useState<ChatMessage[]>([]);

  //print all the states received from the previous page
  //NEBUG: delete
  // console.log("state received from previous page:")
  // console.log(location.state)
  // console.log("company: ", company);
  // console.log("roleType: ", roleType);
  // console.log("interviewType: ", interviewType);
  // console.log("interviewMode: ", interviewMode);
  //!NEBUG

  //function that fetches the script and returns object of type Phase[]
  //NEBUG: TODO: HIGH PRIORITY: UPDATE WITH CALL TO BACKEND and make async

  const getInterviewScript = (interviewIndexAndId: number): Phase[] => {
    const scriptsByCompany: InterviewScript[] = SOFTWARE_ENGINEER_INTERVIEW_SCRIPTS_BY_COMPANY.get(interview.company) || [];

    if (interviewIndexAndId < 0 || interviewIndexAndId >= scriptsByCompany.length) {
      console.error(`Invalid interviewId: ${interviewIndexAndId}. It must be within the array bounds.`);
      return [];
    }

    const script: InterviewScript = scriptsByCompany[interviewIndexAndId];

    return script
      ? script.phases
      : (console.error(`No interview found that matches the interviewId: ${interviewIndexAndId}`), []);
  };



  // tracking current phase of the interview
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState<number>(INITIAL_PHASE_INDEX);
  const phaseTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const phaseEndedRef = useRef<{ [phaseIndex: number]: { ended: boolean } }>({});

  // create a use state object that trackers whether interviewee answered in phaseIndex i was correct
  //@ts-ignore
  const [candidateAttemptEvaluationByPhaseIndex, setCandidateAttemptEvaluationByPhaseIndex] = useState<{ [phaseIndex: number]: CandidateAttemptEvaluationByPhaseIndex }>({});

  /**
 * Updates the candidate's evaluation for a specific phase i.e. track all their attempts/submissions
 *
 * @param phaseIndex - The index of the phase to update.
 * @param candidateAnsweredCorrectly - Boolean value indicating if the candidate answered correctly.
 * @param interviewerFeedback - The feedback given by the interviewer.
 */


  const updateCandidateAttemptEvaluation = ( 
    phaseIndex: number,
    candidateAnsweredCorrectly: boolean,
    interviewerFeedback: string
  ) => {

    // if (interviewPhaseIndex is null or undefined, then log this info and return
    if (phaseIndex === null || phaseIndex === undefined) {
      console.error(`Invalid phaseIndex: ${phaseIndex}`);
      return;
    }

    setCandidateAttemptEvaluationByPhaseIndex((prevState) => {
      let currentPhaseData = prevState[phaseIndex]; // returns undefined if not found, doesn't throw
      if (!currentPhaseData) {
        currentPhaseData = new CandidateAttemptEvaluationByPhaseIndex(phaseIndex, [], []);
      }

      
      currentPhaseData = {
        ...currentPhaseData,
        attemptCorrectnessByAttempt: [...currentPhaseData.attemptCorrectnessByAttempt, candidateAnsweredCorrectly],
        interviewerFeedbackByAttempt: [...currentPhaseData.interviewerFeedbackByAttempt, interviewerFeedback],
      };

      return { ...prevState, [phaseIndex]: currentPhaseData };
    });

    // if candidate answered correctly, try to start next phase
    if (candidateAnsweredCorrectly) {
      attemptStartNextPhaseIfCurrentIndexIsAsExpected(phaseIndex);
    }
  };



  //!

  // write function which takes a phaseIndex, and a msg string and calls setFeedbackNotes
  const addFeedbackNote = (phaseIndex: number, msg: string) => {
    // prepend new line to msg
    let feedbackNote = `\n${msg}`;
    setFeedbackNotes((prevState) => {
      return [...prevState, { phaseIndex: phaseIndex, message: feedbackNote }];
    });
  };



  //feedback
  const [showPostInterviewActions, setShowPostInterviewActions] = useState<boolean>(false);
  const [showPostInterviewFeedbackModal, setShowPostInterviewFeedbackModal] = useState<boolean>(false);
  //@ts-ignore
  const [feedbackNotes, setFeedbackNotes] = useState<{ phaseIndex: number; message: string }[]>([{ phaseIndex: INITIAL_PHASE_INDEX, message: "" }]);
  //@ts-ignore
  const [overallFeedbackSummaryHtml, setOverallFeedbackSummaryHtml] = useState<string>("");

  //@ts-ignore
  const buildTelephoneInterviewState = (): TelephoneOrOnsiteInterviewState => {
    return {
      candidateName: userName,
      programmingLanguage: programmingLanguage,
      interviewerPersonality: interviewSettings.interviewerPersonality,
      candidateAttemptEvaluationByPhaseIndex: candidateAttemptEvaluationByPhaseIndex,
      interviewScriptPhases: interviewPhasesFromInterviewScript.current,

    };
  }
  //@ts-ignore
  const buildTakeHomeAssessmentInterviewState = (): TakeHomeAssessmentInterviewState => {
    return {
      candidateName: userName,
      programmingLanguage: programmingLanguage,
      interviewerPersonality: interviewSettings.interviewerPersonality,
      codingTaskDescription: problemDescription,
      candidatesCode: codeEditorValue,
      acceptableAnswers: '',
    };
  }
  // const requestPostInterviewFeedback = async () => {
  //   setOverallFeedbackSummaryHtml("<p><strong>Coming Soon</strong>: Post-interview feedback will be displayed here in the future</p>");
  //   /* NEBUG TODO HIGH PRIORITY: uncomment once ready to enable post interview feedback and you've overcome heroku router 30s timeout
  //   setOverallFeedbackSummaryHtml("<p>Please wait whilst we generate feedback on your interview ...</p>");

  //   try {
  //     let overallFeedbackHTML: string = "";

  //     if (interviewSettings.interviewStage === InterviewStage.TelephoneOrOnsite) {
  //       overallFeedbackHTML = await sendTelephoneOrOnsiteInteviewerGivePostInterviewFeedbackRequest(buildTelephoneInterviewState());
  //     } else if (interviewSettings.interviewStage === InterviewStage.TakeHomeAssessment) {
  //       overallFeedbackHTML = await sendTakeHomeAssessmentInteviewerGivePostInterviewFeedbackRequest(buildTakeHomeAssessmentInterviewState());
  //     }
  //     // Use setOverallFeedback to append contents feedbackNote.message for each feedbackNote in feedbackNotes
  //     feedbackNotes.forEach((feedbackNote) => {
  //       overallFeedbackHTML += `<p>${feedbackNote.message}</p>`;

  //     });

  //     // Set the overall feedback in the state
  //     setOverallFeedbackSummaryHtml(overallFeedbackHTML);
  //   } catch (error) {
  //     setOverallFeedbackSummaryHtml("<p>Sorry, we were unable to generate feedback on your interview. Please reach out to our support team for assistance.</p>");
  //     console.error('Failed to fetch interview feedback', error);
  //   }
  //   */
  // };



  const skipCurrentPhase = (currentIndex: number): void => {
    console.log("Attempting to skip current phase...");
    attemptStartNextPhaseIfCurrentIndexIsAsExpected(currentIndex);
    // nebug: todo: add feedback note that phase was skipped
  }

  const attemptStartNextPhaseIfCurrentIndexIsAsExpected = (currentIndexFromCallersPerspective: number) => {

    // if currentIndexFromCallersPerspective < currentPhaseIndex, log interview has moved on, and call to startNextPhaseIfCurrentIndexIsAsExpected is ignored and then return
    if (currentIndexFromCallersPerspective < currentPhaseIndex) {
      console.warn(`current phase index is ${currentPhaseIndex}, and caller is trying to start next phase with expected current phase index ${currentIndexFromCallersPerspective}, ignoring starting next phase`);
      return;
    }
    else if (currentIndexFromCallersPerspective > currentPhaseIndex) {
      // log warning that interview is behind, and call to startNextPhaseIfCurrentIndexIsAsExpected is ignored and then return
      console.error(`current phase index is ${currentPhaseIndex}, and caller is trying to start next phase with expected current phase index ${currentIndexFromCallersPerspective}, ignoring starting next phase`);
      return;
    }

    // currentPhaseIndex === currentIndexFromCallersPerspective

    if (currentPhaseIndex < 0) {
      console.error(`current phase index is ${currentPhaseIndex}, ignoring starting next phase`);
      return
    }

    // currentPhaseIndex is >= 0

    if (currentPhaseIndex < interviewPhasesFromInterviewScript.current.length) {
      // there are more phases to go through

      if (phaseEndedRef.current[currentPhaseIndex] === undefined || phaseEndedRef.current[currentPhaseIndex] === null) {
        // initialize the phaseEndedRef for the current phase
        phaseEndedRef.current[currentPhaseIndex] = { ended: false };
      }
      // only start the next phase if the current phase hasn't ended
      if (!phaseEndedRef.current[currentPhaseIndex].ended) {
        // Mark the current phase as ended
        phaseEndedRef.current[currentPhaseIndex].ended = true;
        setCurrentPhaseIndex((prevIndex) => prevIndex + 1);
      }

      // do nothing if the current phase has already ended (probably by some other component)
    } else {
      // there are no more phases to go through
      const endOfInterviewMsg = "That's all the questions I have for you. Thank you for your time!";
      endInterview(endOfInterviewMsg);
    }
  };

  const initiatePhase = (phase: Phase) => {
    const phaseIndexAtOutsetOfScope = currentPhaseIndex;
    phaseEndedRef.current[phaseIndexAtOutsetOfScope] = { ended: false };
    const { phaseTimeInMilliSeconds, challenge, challengeType, acceptableAnswer, required, codeStub } = phase;

    // Clear any existing phase timeout
    clearPhaseTimeout();

    if (codeStub) { // nebug: TODO: should be pasting the codeStub for the selected programming language
      setCodeEditorValue(codeStub);
    }
    // Display challenge to the candidate

    const challengeMsg = `${challenge}${challengeType === ChallengeType.CODING ? `\nClick '${DONE_CODING_BUTTON_TEXT}' when you are done. (Write your solution in the code editor.)` : ''}`;

    appendChatMsgToMessageHistory(
      ChatMessage.createWithChallengeType(
        ChatMessageSender.Interviewer,
        ChatMessageSender.Candidate,
        new Date().toISOString(),
        challengeMsg,
        challengeType
      )
    );


    // Set up phase timeout
    const phaseTimeout = setTimeout(() => {
      // Before executing the timeout logic, check if the phaseIndex at the time the timeout was started has already ended
      if (!phaseEndedRef.current[phaseIndexAtOutsetOfScope]?.ended) {
        console.log(`Phase ${phaseIndexAtOutsetOfScope} timed out.`);

        addFeedbackNote(phaseIndexAtOutsetOfScope, `For the challenge: '${challenge}', you did not submit a correct answer within ${phaseTimeInMilliSeconds / 60000} minutes.`);
        if (!required) {
          console.log(`Phase ${phaseIndexAtOutsetOfScope} is not required. Skipping to next phase.`);
          // Give answer if the phase is not required
          appendChatMsgToMessageHistory(
            new ChatMessage(
              ChatMessageSender.Interviewer,
              ChatMessageSender.Candidate,
              new Date().toISOString(),
              `In the interest of time, i'll share this answer so we can move on: ${acceptableAnswer}`
            )
          );
          updateCandidateAttemptEvaluation(phaseIndexAtOutsetOfScope, false, "candidate failed to provide satisfactory response within the allotted time");

          // Proceed to the next phase
          attemptStartNextPhaseIfCurrentIndexIsAsExpected(phaseIndexAtOutsetOfScope);
        } else {
          console.log(`Phase ${phaseIndexAtOutsetOfScope} is required. adding feedback notes`);
        }
      }
    }, phaseTimeInMilliSeconds);

    // Store the phase timeout in the ref
    phaseTimerRef.current = phaseTimeout;
  };


  const initialiseChatHistory = () => {
    //send welcome message to candidate
    const welcomeMsg: string = `Welcome ${userName}, to this mock ${interview.company} interview for a ${interview.roleType} role.\n\nClick the start button when ready to kick off this interview.\n\nAs an AI interviewer in the alpha stage, my insights may not always be completely accurate. Please keep in mind that I'm not perfect, so always cross-verify my advice.`;
    const welcomeChatMsg: ChatMessage = new ChatMessage(
      ChatMessageSender.Interviewer,
      ChatMessageSender.Candidate,
      new Date().toISOString(),
      welcomeMsg
    );
    setMessages([welcomeChatMsg]);
  }

  //NEBUG
  //function for starting the interview that takes a setIsTimerRunning(bool) function as a parameter
  const [interviewStatus, setInterviewStatus] = useState(
    InterviewStatus.NOT_STARTED
  );
  const startInterview = () => {
    //if not signed in (userEMail is defined and not an empty string )navigate to sign in page
    if (!isUserSignedIn(userId)) {
      logToBackendLogFile("user denied 'start interview' access until sign-in", "info", userId);
      navigate(SIGN_UP_PAGE_PATH);
      return;
    }


    if (interviewStatus === InterviewStatus.IN_PROGRESS) {
      console.warn("Interview has already started, cannot start again");
      return;
    }

    console.log("Starting interview");

    prepareForFreshStart();
    displayTimerTemporarily();
    //print value of currentPhaseIndex
    console.log("currentPhaseIndex is " + currentPhaseIndex);
    if (interviewSettings.interviewStage === InterviewStage.TakeHomeAssessment) {
      handleTakeHomeAssessmentStart();
    }
    else if (interviewSettings.interviewStage === InterviewStage.TelephoneOrOnsite) {
      handleTelephoneOrOnsiteInterviewStart();
    } else {
      console.error(`Interview type ${interviewSettings.interviewStage} is not supported. Failed to start interview`);
    }

  };

  const endInterview = (endOfInterviewMsgToCandidate: string) => {
    console.log("Ending interview");
    setInterviewStatus(InterviewStatus.ENDED);
    sendChatMessageToCandidateFromSystem(endOfInterviewMsgToCandidate);
    //TODO [MAJOR]: submit code to backend asynchronously for execution?
    setIsTimerRunning(false);
    setTimerVisible(false);
    setShowPostInterviewActions(true);
  };

  const prepareForFreshStart = () => {
    setCurrentPhaseIndex(INITIAL_PHASE_INDEX);
    setIsTimerRunning(false);
    setTimerVisible(false);
    setFeedbackNotes([]);
    setOverallFeedbackSummaryHtml("");
    phaseTimerRef.current = null;
    phaseEndedRef.current = {};
    setCandidateAttemptEvaluationByPhaseIndex({});
    setIsCodeSubmitted(false);
    setInterviewStatus(InterviewStatus.NOT_STARTED);
    interviewPhasesFromInterviewScript.current = getInterviewScript(interview.interviewId); //reset interview script
  }



  const changeInterviewStatusToInProgress = () => {
    setInterviewStatus(InterviewStatus.IN_PROGRESS);
    setIsTimerRunning(true);
  }


  //function that encupsulates the logic for handling tak-home assessment
  const handleTakeHomeAssessmentStart = () => {
    getChallengeDetails(getInterviewDifficultyAsInteger(interviewSettings.interviewDifficulty), programmingLanguage, interviewSettings.timeLimitInMinutes, userId)
      .then(challengeDetails => {
        handleProblemLoad(challengeDetails.problemDescription);
        setCodeEditorValue(challengeDetails.codeEditorContent);
        sendChatMessageToCandidateFromSystem(
          "The interview has begun, and the timer has started, I would advise you to make sure you understand the problem before diving in "
        );
        changeInterviewStatusToInProgress();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  // telephone interview
  // NEBUG
  /**
   * Handles the interviewer's response for a telephone or onsite interview.
   * Determines the interviewer's perceived intent of the candidate's message and takes appropriate action.
   * @param interviewPhaseIndex - The index of the interview phase.
   * @param chatMsg - The chat message object containing the candidate's message and intent classification.
   * @param chatHistoryUpdateCallback - The callback function to update the chat history with the final response message to the candidate.
   */
  const handleInteviewerResponseForTelephoneOrOnsiteInterview = (interviewPhaseIndex: number, 
    chatMsg: ChatMessage,
    chatHistoryUpdateCallback: (chatMsg: ChatMessage) => void) => {
    const candidateMsgIntentClassification = chatMsg.interviewersClassificationOfCandidatesIntent;
    
    if (candidateMsgIntentClassification === undefined) {
      chatMsg.interviewersClassificationOfCandidatesIntent = CandidatesMessageIntent.UNKNOWN;
      console.log(`candidateMsgIntentClassification is undefined, setting to default value of ${CandidatesMessageIntent.UNKNOWN}`);
    }

    let finalResponseMsgToCandidate: string = chatMsg.message; // this will be overriden if needed
    //* use switch statement to different classifications of the interviewers perceived intent of the candidates message
    switch (candidateMsgIntentClassification) {
      case CandidatesMessageIntent.ATTEMPT_TO_ANSWER_QUESTION:
        const didCandidateAnswerCorrectly = chatMsg.candidatesAnswerWasAccepted ?? false;
        if (chatMsg.candidatesAnswerWasAccepted === undefined) {
          console.log(`candidatesAnswerWasAccepted was undefined, setting to ${didCandidateAnswerCorrectly}`);
        }
        updateCandidateAttemptEvaluation(interviewPhaseIndex, didCandidateAnswerCorrectly, finalResponseMsgToCandidate);
        break;
      case CandidatesMessageIntent.REQUEST_TO_SKIP:
        finalResponseMsgToCandidate = `If you want to skip this, just hit the '${SKIP_BUTTON_TEXT}' button`;
        break;
      case CandidatesMessageIntent.UNKNOWN:
        console.log(`candidatesMessageIntent is ${CandidatesMessageIntent.UNKNOWN}`);
        break;
      default:
        // do nothing special for other types
        break;
    }

    console.log(`Overriding chat message with final response message: ${finalResponseMsgToCandidate}`);
    chatMsg.message = finalResponseMsgToCandidate;
    chatHistoryUpdateCallback(chatMsg);
  };
  //!
  const handleTelephoneOrOnsiteInterviewStart = () => {
    changeInterviewStatusToInProgress();
    setCurrentPhaseIndex(INITIAL_PHASE_INDEX);

    /* NEBUG: consider decoupling from javascript implementation to prevent future issues
    To guarantee that the state is updated before the next line of code is executed, we can use the setTimeout function with a delay of 0 milliseconds.
    it relies on the implementation details of JavaScript's event loop and React's state update batching, which could potentially change in future versions.

    Call setCurrentPhaseIndex(INITIAL_PHASE_INDEX). This schedules an update to happen later.
    Set a timeout with a delay of 0 milliseconds. This puts the following function on the event queue to be executed later, after the current execution context is finished (including any other state updates that have been scheduled).
    The function passed to setTimeout is called, which executes setCurrentPhaseIndex(0). This schedules another state update.

    Using setTimeout with a delay of 0 is a common technique for deferring the execution of a function until after the current execution context is finished and any pending tasks have been completed.
    */
    setTimeout(() => {
      setCurrentPhaseIndex(0);
    }, 0);
  }

  const appendChatMsgToMessageHistory = (chatMsg: ChatMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      chatMsg,
    ]);
  };

  // Set the default value of the programmingLanguage state to the restrictedProgrammingLanguage value from the interview object, or to Python if it is not defined
  const [programmingLanguage, setProgrammingLanguage] = useState(
    interview.restrictedProgrammingLanguage ?? ProgrammingLanguage.Python // NEBUG MINOR TODO: update default value to be users preferred language from profile, that way codestub loaded on start will be in their preferred language
  );

  const [codeEditorValue, setCodeEditorValue] = useState(
    getDefaultCodeStub(programmingLanguage)
  );


  const [problemDescription, setProblemDescription] = React.useState(
    "The problem description will be displayed here"
  );
  const [problemSolutions] = React.useState(
    "The solutions will be displayed here"
  );

  const [isCodeSubmitted, setIsCodeSubmitted] = React.useState(false);

  const handleProblemLoad = (problemDescription: string) => {
    setProblemDescription(problemDescription);
  };

  const sendChatMessageToCandidateFromSystem = (message: string) => {
    appendChatMsgToMessageHistory(
      new ChatMessage(
        ChatMessageSender.System,
        userName,
        new Date().toISOString(),
        message
      )
    );
  };

  const handleSettingsClose = () => {
    setIsSettingsVisible(false);
  };

  const interviewPhasesFromInterviewScript = useRef<Phase[]>([]);

  const shouldDisplayChatWindow = () => {
    return true;
    // if (interviewSettings.interviewType === InterviewType.TelephoneOrOnsite ||
    //   (interviewSettings.interviewType === InterviewType.TakeHomeAssessment && interviewMode === InterviewMode.COACH)) {
    //   return true;
    // }
    // return false;
  }



  // cleanups

  /*
    * Clears the phase timeout
  */
  const clearPhaseTimeout = () => {
    if (phaseTimerRef.current) {
      console.log("Clearing phase timeout");
      clearTimeout(phaseTimerRef.current);
      phaseTimerRef.current = null;
    }
  };

  useEffect(() => {
    initialiseChatHistory();
    return () => {
      // Clean up timers on unmount
      clearPhaseTimeout();
    };
  }, [userName]);

  // other hooks

  useEffect(() => {
    console.log(`Phase index changed to ${currentPhaseIndex}`)
    if (currentPhaseIndex >= 0 && currentPhaseIndex < interviewPhasesFromInterviewScript.current.length) {
      console.log(`Starting phase ${currentPhaseIndex}`);
      initiatePhase(interviewPhasesFromInterviewScript.current[currentPhaseIndex]);
    }
    else if (currentPhaseIndex === interviewPhasesFromInterviewScript.current.length) {
      const endOfInterviewMsg = "That's the end of the interview. Thank you for your time.";
      endInterview(endOfInterviewMsg);
    }
    else {
      console.warn(`Invalid phase index: ${currentPhaseIndex}`);
    }
  }, [currentPhaseIndex]);
  return (
    <div className="App">
      {isSettingsVisible && (
        <Modal
          content={
            <SettingsView
              onClose={handleSettingsClose} // NITO: pass a function that closes then starts interview i.e. wrap the two methods?This only happens on the inteviewPage
              interviewSettings={interviewSettings}
              setInterviewSettings={setInterviewSettings}
              programmingLanguage={programmingLanguage}
              setProgrammingLanguage={setProgrammingLanguage}
              settingsAccessibility={{
                programmingLanguage: {
                  // enabled value false if, interview.restrictedProgrammingLanguage is undefined or null
                  enabled: interview.restrictedProgrammingLanguage === undefined || interview.restrictedProgrammingLanguage === null,
                  defaultValue: interview.restrictedProgrammingLanguage
                }
              }}
            />
          }
          onClose={handleSettingsClose}
        />
      )}
      <TopMenuBar
        isSettingsVisible={isSettingsVisible}
        setIsSettingsVisible={setIsSettingsVisible}
        startInterview={startInterview}
        interviewStatus={interviewStatus}
        isTimerRunning={isTimerRunning}
        timerVisible={timerVisible}
        setTimerVisible={setTimerVisible}
        endInterview={endInterview}
        interviewSettings={interviewSettings}
        setInterviewSettings={setInterviewSettings}
        programmingLanguage={programmingLanguage}
        setProgrammingLanguage={setProgrammingLanguage}
        displayTimerTemporarily={displayTimerTemporarily}
        settingsAccessibility={{
          programmingLanguage: {
            // enabled value false if, interview.restrictedProgrammingLanguage is undefined or null
            enabled: interview.restrictedProgrammingLanguage === undefined || interview.restrictedProgrammingLanguage === null,
            defaultValue: interview.restrictedProgrammingLanguage
          }
        }}
      />
      <div className="interview-page-elements-container">

        {interviewSettings.interviewStage === InterviewStage.TakeHomeAssessment &&
          <ChallengesTabbedWindow // TODO: this is only for the take-home assessment mode
            problemDescription={problemDescription}
            problemSolutions={problemSolutions}
          />
        }
      <CodeEditor
        value={codeEditorValue}
        language={programmingLanguage}
        codeEditorHeightInPx={440}
        onChange={(value: string) => setCodeEditorValue(value)}
        userId={''}  //TODO MAJOR: set actual userId once it is no longer being randomly generated
        taskId={`interview-question:${interview.interviewId}`} 
      />
        {/* <ConditioningTracker /> NEBUG: enable just for nito*/}
        {shouldDisplayChatWindow()
          && (
            <div>
              <InterviewChatWindow
                messages={messages}
                appendChatMsgToMessageHistory={appendChatMsgToMessageHistory}
                interviewMode={interviewMode}
                currentInterviewPhaseIndex={currentPhaseIndex}
                handleInteviewerResponseForTelephoneOrOnsiteInterview={handleInteviewerResponseForTelephoneOrOnsiteInterview}
                interviewPhasesFromInterviewScript={interviewPhasesFromInterviewScript.current}
                interviewStatus={interviewStatus}
                isCodeSubmitted={isCodeSubmitted}
                candidateName={userName}
                userEmail={userEmail}
                userId={userId}
                interviewSettings={interviewSettings}
                codingChallengeQuestion={problemDescription}
                candidatesCode={codeEditorValue}
                programmingLanguage={programmingLanguage}
                skipCurrentPhase={skipCurrentPhase}
              />

            </div>
          )
        }
        {/* <OutputWindow setIsCodeSubmitted={setIsCodeSubmitted} />  */} {/* NEBUG: TODO: LOW PRIORITY: add output window */}

        {/* Add the feedback modal here */}
        {
          <PostInterviewActions
            setShowPostInterviewActions={setShowPostInterviewActions}
            showPostInterviewActions={showPostInterviewActions}
            interviewPhasesFromInterviewScript={interviewPhasesFromInterviewScript.current}
            interview={interview}
          />

        }

        {showPostInterviewFeedbackModal && (
          <PostInterviewFeedback
            overallFeedbackSummaryHtml={overallFeedbackSummaryHtml}
            onClose={() => setShowPostInterviewFeedbackModal(false)}
          />


        )}
      </div>
    </div>
  );
};

export default InterviewPage;