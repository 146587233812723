// src/externalLayerAccessor/paymentsServiceAccessor.ts
import axios from 'axios';
import { REACT_APP_BACK_END_BASE_URL, retryRequest } from './BackEndRequests';
import { PAYMENT_CANCELLED_PAGE_PATH, PAYMENT_SUCCESS_PAGE_PATH } from '@/utils/Constants';
import { stripePromise } from '@/utils/stripe';
import { UserSubscription, UserSubscriptionTier } from './models/firebaseModels';


const BACKEND_STRIPE_PAYMENT_SERVICE_BASE_URL = `${REACT_APP_BACK_END_BASE_URL}/stripe`;

export interface LineItem {
  price_id: string;
  quantity: number;
}

export interface CheckoutDetail {
  payment_method_types: PaymentMethodType[];
  line_items: LineItem[];
  mode: CheckoutMode;
}

export enum PaymentMethodType {
  CARD = 'card',
  // ALIPAY = 'alipay',
  // WECHAT_PAY = 'wechat_pay',
}

export enum CheckoutMode {
  PAYMENT = 'payment',
  SUBSCRIPTION = 'subscription',
}

export interface SubscriptionCheckoutSessionRequest {
  payment_method_types: PaymentMethodType[];
  line_items: LineItem[];
  mode: CheckoutMode;
  success_url: string;
  cancel_url: string;
  user_id: number;
  display_name: string | null;
  user_email: string;
  subscription_tier: UserSubscriptionTier;
  subscription_expiry: Date | null;
}

export interface CheckoutSessionResponse {
  id: string;
}

export async function createCheckoutSession(userId: number, sessionRequest: SubscriptionCheckoutSessionRequest): Promise<CheckoutSessionResponse | null> {
  const url = `${BACKEND_STRIPE_PAYMENT_SERVICE_BASE_URL}/${userId}/checkout-session`;

  try {
    return await retryRequest(async () => {
      const response = await axios.post(url, sessionRequest);
      if (response.status === 201) {
        return {
          id: response.data.id,
        };
      } else {
        console.error(`Failed to create checkout session: ${response.status}`);
        return null;
      }
    });
  } catch (error: any) {
    if (error.response && error.response.status === 500) {
      console.error('Internal server error');
    } else {
      console.error('Error creating checkout session:', error);
    }
    return null;
  }
}

export async function createSubscriptionStripeCheckoutSession(
  userId: number,
  checkoutDetail: CheckoutDetail,
  subscriptionDetails: UserSubscription,

): Promise<void> {
  const sessionRequest: SubscriptionCheckoutSessionRequest = {
    payment_method_types: checkoutDetail.payment_method_types,
    line_items: checkoutDetail.line_items,
    mode: checkoutDetail.mode,
    success_url: `${window.location.origin}${PAYMENT_SUCCESS_PAGE_PATH}&session_id={CHECKOUT_SESSION_ID}`, // note stripe will replace placeholder {CHECKOUT_SESSION_ID} with the actual session ID
    cancel_url: `${window.location.origin}${PAYMENT_CANCELLED_PAGE_PATH}`,
    user_id: userId,
    display_name: subscriptionDetails.displayName,
    user_email: subscriptionDetails.userEmail,
    subscription_tier: subscriptionDetails.subscriptionTier,
    subscription_expiry: subscriptionDetails.subscriptionExpiry,
  };

  const session: CheckoutSessionResponse | null = await createCheckoutSession(userId, sessionRequest);

  const stripe = await stripePromise;

  if (stripe && session) {
    const { error } = await stripe.redirectToCheckout({// user will be redirected to the Stripe-hosted checkout pagess
      sessionId: session.id,
    });
    if (error) {
      throw new Error(`Stripe error: ${error.message}`);
    }
  } else {
    throw new Error('Failed to create checkout session or Stripe is not loaded');
  }
}
