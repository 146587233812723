import React from "react";
import start from '../img/icons/start.png';
import Foundation_Phase from '../img/icons/foundation_phase.png'
import Interview_Learning from '../img/icons/interview_learning.png'
import Optimal_Algorithms from '../img/icons/optimal_algorithms.png'
import Coding_Under_Pressure from '../img/icons/coding_under_time_pressure.png'
import Interview_Training from '../img/icons/interview_training.png'
import Mock_Interview from '../img/icons/mock_interview.png'
import AI_Interview from '../img/icons/ai_interview.png'
import Human_Interview from '../img/icons/human_interview.png'
import Finish from '../img/icons/finish.png'
import '../css/JourneyPath_beta.css'
import { PHASES_PAGE_PATH, AI_INTERVIEW_SELECTION_PAGE_PATH } from "@/utils/Constants";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


export enum ProgressStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum StepSide {
  Left = "left",
  Right = "right",
}

interface Step {
  id: string;
  title: string;
  status: ProgressStatus; 
  icon: string;
  side: StepSide;
  url: string;
}

interface JourneyProps {
  steps: Step[];
}

const Journey: React.FC<JourneyProps> = ({ steps }) => {
  return (
    <div>
      <div className="flex flex-row items-center justify-center border bg-[#FFD99F] m-2.5 ml-40 text-center w-40 h-12 font-bold text-[large] rounded-[10px] border-solid border-[#FFD99F]">
        <h2 className="page-header flex flex-row items-center justify-center text-center m-1">User Journey</h2>
      </div>
      <div className="flex flex-col self-center items-center mx-auto ">
        {steps.map((step) => (
          <div
            className="flex flex-col sm:flex-row"
            key={step.id}
          >
            {step.side === "left" ? (
              <div
                className={`w-full flex w-[10em] md:w-[20em] text-wrap overflow-x-scroll justify-end flex-end justify-items-end justify-self-end items-end gap-x-0 my-0 border-r-2 py-8 px-2 border-${
                  step.status === ProgressStatus.NotStarted ? "slate-400" : "black"
                }`}
              >
                <span
                  className={`text-base md:text-lg font-bold ${
                    step.status === ProgressStatus.NotStarted ? "text-gray-500" : "text-black"
                  }`}
                >
                  {step.status === ProgressStatus.Completed && (
                    <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
                  )}                  
                  {step.title}
                </span>
                <div className="w-10 h-6 ml-1 mr-1 sm:flex items-center justify-center">
                <Link to={`${step.url}?title=${encodeURIComponent(step.title)}`}>
                  <img className="w-10 h-10" src={step.icon} alt={step.title} />
                </Link>               
                </div>
                <div className="items-center hidden md:flex flex-row gap-x-1">
                  {[...Array(3)]
                    .map((_, dotIndex) => (
                      <Dot
                        radius={dotIndex * 4 + 4}
                        color={`${
                          step.status === "completed"
                            ? "black"
                            : step.status === ProgressStatus.InProgress
                            ? "blue"
                            : "gray"
                        }`}
                      />
                    ))
                    .reverse()}
                </div>
              </div>
            ) : (
              <div className="w-full flex w-[10em] md:w-[20em]"></div>
            )}
            {step.side === "right" ? (
              <div
                className={`w-full w-[10em] relative left-[-0.125em] md:w-[20em] flex items-center my-0 border-l-2 border-${
                  step.status === ProgressStatus.NotStarted ? "slate-400" : "black"
                } py-8 px-2`}
              >
                <div className="items-center hidden md:flex flex-row gap-x-1">
                  {[...Array(3)].map((_, dotIndex) => (
                    <Dot
                      radius={dotIndex * 4 + 4}
                      color={`${
                        step.status === "completed"
                          ? "black"
                          : step.status === ProgressStatus.InProgress
                          ? "blue"
                          : "gray"
                      }`}
                    />
                  ))}
                </div>
                <div className="w-10 h-10 ml-1 mr-1 sm:flex items-center justify-center">
                <Link to={`${step.url}?title=${encodeURIComponent(step.title)}`}>
                  <img className="w-10 h-10" src={step.icon} alt={step.title} />
                </Link>                
                </div>
                <span
                  className={`text-base md:text-lg font-bold ${
                    step.status === ProgressStatus.NotStarted ? "text-gray-500" : "text-black"
                  }`}
                >
                  {step.title}
                  {step.status === ProgressStatus.Completed && (
                    <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-2" />
                  )}
                </span>
              </div>
            ) : (
              <div className="w-full flex w-[10em] md:w-[20em]"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const JourneyPath: React.FC = () => {
  const steps: Step[] = [
    {
      id: "1",
      title: "Start",
      status: ProgressStatus.Completed,
      icon: start,
      side: StepSide.Left,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "2",
      title: "Foundation Phase",
      status: ProgressStatus.Completed,
      icon: Foundation_Phase,
      side: StepSide.Right,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "3",
      title: "Interview Learning",
      status: ProgressStatus.Completed,
      icon: Interview_Learning,
      side: StepSide.Left,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "4",
      title: "Optimal Algorithms",
      status: ProgressStatus.Completed,
      icon: Optimal_Algorithms,
      side: StepSide.Right,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "5",
      title: "Coding & Pressure",
      status: ProgressStatus.Completed,
      icon: Coding_Under_Pressure,
      side: StepSide.Left,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "6",
      title: "Interview Training",
      status: ProgressStatus.Completed,
      icon: Interview_Training,
      side: StepSide.Right,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "7",
      title: "Mock Interview",
      status: ProgressStatus.Completed,
      icon: Mock_Interview,
      side: StepSide.Left,
      url: AI_INTERVIEW_SELECTION_PAGE_PATH,
    },
    {
      id: "8",
      title: "AI Interview",
      status: ProgressStatus.NotStarted,
      icon: AI_Interview,
      side: StepSide.Right,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "9",
      title: "Human Interview",
      status: ProgressStatus.NotStarted,
      icon: Human_Interview,
      side: StepSide.Left,
      url: PHASES_PAGE_PATH,
    },
    {
      id: "10",
      title: "Finish",
      status: ProgressStatus.NotStarted,
      icon: Finish,
      side: StepSide.Right,
      url: PHASES_PAGE_PATH,
    },
  ];
  return (
    <div className="App overflow-y-auto">
      <Journey steps={steps} />
    </div>
  );
};

const Dot: React.FC<{ radius: number; color: string }> = ({
  radius,
  color,
}) => {
  return (
    <svg
      width={radius * 2}
      height={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      fill={color}
      className="inline-block"
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius}
        fill={color}
      />
    </svg>
  );
};


export default JourneyPath;
