import React, { useState, useEffect } from 'react';
import '../css/CountdownTimer.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface CountdownTimerProps {
  duration: number;
  isTimerRunning: boolean;
  setIsTimerRunning: (isTimerRunning: boolean) => void;
  unit_id: number;
  onTimerComplete: () => void;
  disablePause?: boolean;
  disabled?: boolean;
}

function formatTime(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const hh = hours.toString().padStart(2, '0');
  const mm = minutes.toString().padStart(2, '0');
  const ss = seconds.toString().padStart(2, '0');

  return `${hh}h:${mm}m:${ss}s`;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  isTimerRunning,
  setIsTimerRunning,
  unit_id,
  duration,
  onTimerComplete,
  disablePause = false,
  disabled = false
}) => {
  const [key, setKey] = useState(0);

  const handleToggleTimerState = () => {
    setIsTimerRunning(!isTimerRunning);
  };

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [unit_id]);

  if(disabled) {
    return null;
  }

  return (
    <div className="timer">
      <CountdownCircleTimer
        key={key}
        isPlaying={isTimerRunning}
        duration={duration}
        strokeWidth={0}
        size={20}
        trailColor={'#FFFFFF'}
        colors={[['#000000', 0.33], ['#000000', 0.33], ['#000000']] as any}
        onComplete={onTimerComplete}
      >
        {({ remainingTime }) => (
          <div className="countdown-timer-text">{formatTime(remainingTime)}</div>
        )}
      </CountdownCircleTimer>
      {!disablePause && (
        <div
          className="pause-play-icon"
          onClick={handleToggleTimerState}
        >
          {isTimerRunning ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
