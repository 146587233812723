import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JOURNEY_PAGE_PATH } from '@/utils/Constants';
import { cardColors } from './LearningPaths_beta';
import { ILearningPath }from './LearningPaths_beta';
import '../css/ProgressSummary.css'

interface ProgressSummaryBetaProps {
  currentJourney: ILearningPath;
}

interface ProgressSummaryProps {
  label: string;
  onClick: () => void;
}

const ProgressItem: React.FC<ProgressSummaryProps> = ({ label, onClick }) => {
  return (
      <button className="bg-[#ffca7a] border text-black text-center no-underline inline-block text-l cursor-pointer ml-[30px] px-3 py-1.5 rounded-[10px] border-solid border-transparent hover:bg-[#edaf59]" onClick={onClick}>
        {label}
      </button>
  );
};

const ProgressSummary_beta:React.FC<ProgressSummaryBetaProps> = ({ currentJourney }) => {
  const navigate = useNavigate();

  const [experiencePoints, setExperiencePoints] = useState<number | null>(null);
  
  useEffect(() => {
    // Simulate an API call
    const timeoutId = setTimeout(() => {
      setExperiencePoints(2500); // update with new value from API
    }, 2000);

    // Cleanup function
    return () => clearTimeout(timeoutId);
  }, []);
  
  const handleContinueJourney = () => {
    navigate(JOURNEY_PAGE_PATH);
  };

  return (
    <>
    <div className="dashboard-header">
      <h2 className="page-header">User Dashboard</h2>
    </div>
    <div className="progress-summary-container">
      <div className="progress-summary-content">
        <div className="progress-info">
          <h2 className="progress-title">Progress Summary</h2>
          <p className="progress-subtitle">Experience Points</p>
          <p className="experience-points">{experiencePoints ? new Intl.NumberFormat().format(experiencePoints) : ''} XP</p>
        </div>
        <div className="current-journey-container">
          <div className="current-journey-title">
            <h2 className="font-bold text-2xl text-center">
              <span className="block">Current</span>
              <span className="block">Journey</span>
            </h2>
          </div>
          <div className="current-journey-card">
            <div className="current-journey-details" style={{ background: cardColors[currentJourney.status] }}>
              <div className="current-journey-text">
                <div className="current-journey-title-text">{currentJourney.title}</div>
                <div className="current-journey-status">{currentJourney.status}</div>
                <div className="current-journey-progress">
                  Progress: {currentJourney.progress}/{currentJourney.total}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="continue-journey-container">
          <ProgressItem label="Continue Journey" onClick={handleContinueJourney} />
        </div>
      </div>
    </div>
  </>
  );
};

export default ProgressSummary_beta;