import React from 'react';

interface FAQProps {
    // Custom props as needed
    disabled: boolean;
}

const FAQ: React.FC<FAQProps> = (props) => {
    if (props.disabled) {
        return null;
    }
    return (
        <div className="faq landing-page-row">
            faq placeholder
        </div>
    );
}

export default FAQ;