// Step 1: Define the Enum for Testimonial Types
import React, { useState, useCallback, useMemo } from 'react';
import '../css/MultiModalTestimonials.css';

// Enum for testimonial types
export enum TestimonialType {
    TEXT = 'text',
    IMAGE = 'image',
}

// Enum for style types
export enum StyleType {
    CARD = 'card',
    STICKY_NOTE = 'sticky-note',
}

// Interfaces for testimonials

// Base interface for all testimonials
interface TestimonialBase {
    id: string; // Unique identifier for each testimonial
    userDisplayName: string; // Name of the user giving the testimonial
    type: TestimonialType; // Discriminator for the type of testimonial
}

// Text testimonial interface extending the base
interface TextTestimonial extends TestimonialBase {
    type: TestimonialType.TEXT; // Using enum for type
    text: string; // The text content of the testimonial
    companyName?: string; // Optional company name
    companyLogoUrl?: string; // Optional URL for the company logo
}

// Image testimonial interface extending the base
interface ImageTestimonial extends TestimonialBase {
    type: TestimonialType.IMAGE; // Using enum for type
    imageUrl: string; // URL to the image file
    altText: string; // Alt text for the image
    caption?: string; // Optional caption for the image
}

// Union type for all possible testimonials (Currently only Text and Image)
export type Testimonial = TextTestimonial | ImageTestimonial;

// Props interface for the main MultiModalTestimonials component
interface MultiModalTestimonialsProps {
    testimonials: Testimonial[]; // Array of testimonial objects (either text or image)
    itemsPerPage?: number; // Optional number of testimonials to display per page
    styleType?: StyleType; // Optional style type for text testimonials
}

// Step 2: Auxiliary Components
interface TextTestimonialCardProps {
    testimonial: TextTestimonial; // Specific type for text testimonial
    styleType: StyleType; // Style type for the card
}

const TextTestimonialCard: React.FC<TextTestimonialCardProps> = React.memo(
    ({ testimonial, styleType }) => {
      return (
        <div className={`testimonial-card ${styleType}`}>
          <p>{testimonial.text}</p>
          <div className="testimonial-footer">
            <span className="user-display-name">
              {testimonial.userDisplayName}
            </span>
            {testimonial.companyName && (
              <span className="company-name">
                {testimonial.companyLogoUrl ? (
                  <img
                    src={testimonial.companyLogoUrl}
                    alt={testimonial.companyName}
                  />
                ) : (
                  testimonial.companyName
                )}
              </span>
            )}
          </div>
        </div>
      );
    }
  );

interface ImageTestimonialCardProps {
    testimonial: ImageTestimonial; // Specific type for image testimonial
  }
  
  const ImageTestimonialCard: React.FC<ImageTestimonialCardProps> = React.memo(
    ({ testimonial }) => {
      return (
        <div className="image-testimonial-card sticky-note">
          {/* Render image with alt text */}
          <img
            src={testimonial.imageUrl}
            alt={testimonial.altText}
            className="testimonial-image"
          />
  
          {/* Optional caption for the image */}
          {testimonial.caption && (
            <p className="image-caption">{testimonial.caption}</p>
          )}
  
          {/* User information */}
          <div className="testimonial-footer">
            <span className="user-display-name">
              {testimonial.userDisplayName}
            </span>
          </div>
        </div>
      );
    }
  );


interface TestimonialDisplayProps {
    testimonial: Testimonial; // A single testimonial object of any type (Text or Image)
    styleType: StyleType; // Style type for text testimonials
}

const TestimonialDisplay: React.FC<TestimonialDisplayProps> = React.memo(
    ({ testimonial, styleType }) => {
      switch (testimonial.type) {
        case TestimonialType.TEXT:
          return (
            <TextTestimonialCard
              testimonial={testimonial}
              styleType={styleType}
            />
          );
        case TestimonialType.IMAGE:
          return <ImageTestimonialCard testimonial={testimonial} />;
        default:
          return null;
      }
    }
  );

interface PaginationControlsProps {
    currentPage: number; // Current active page
    totalPages: number; // Total number of pages
    onPageChange: (page: number) => void; // Callback function for changing pages
}

const PaginationControls: React.FC<PaginationControlsProps> = React.memo(
    ({ currentPage, totalPages, onPageChange }) => {
      return (
        <div className="pagination-controls">
          {/* Previous Button */}
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            aria-label="Previous page"
          >
            Previous
          </button>
  
          {/* Next Button */}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            aria-label="Next page"
          >
            Next
          </button>
        </div>
      );
    }
  );

// Step 3: Implement the Main Component

// Main component for rendering testimonials with pagination

const MultiModalTestimonials: React.FC<MultiModalTestimonialsProps> = ({
    testimonials,
    itemsPerPage = 3,
    styleType = StyleType.CARD,
  }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
  
    // Calculate total pages
    const totalPages = useMemo(
      () => Math.ceil(testimonials.length / itemsPerPage),
      [testimonials, itemsPerPage]
    );
  
    // Function to handle page change
    const handlePageChange = useCallback((newPage: number) => {
      setCurrentPage(newPage);
    }, []);
  
    // Calculate the testimonials to display on the current page
    const currentTestimonials = useMemo(() => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return testimonials.slice(startIndex, endIndex);
    }, [currentPage, itemsPerPage, testimonials]);
  
    return (
      <div className="multi-modal-testimonials">
        {/* Render current page of testimonials */}
        {currentTestimonials.map((testimonial) => (
          <TestimonialDisplay
            key={testimonial.id}
            testimonial={testimonial}
            styleType={styleType}
          />
        ))}
  
        {/* Pagination Controls */}
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    );
  };
  
  export default React.memo(MultiModalTestimonials);
  