import React, { useEffect } from "react";
import MonacoEditor from "@monaco-editor/react";
import CodeStorage from "./CodeStorage";

interface Props {
  value: string;
  language: string;
  codeEditorHeightInPx: number;
  userId: string;
  taskId: string;
  onChange: (value: string) => void;
}

const CodeEditor: React.FC<Props> = ({
  value,
  language,
  codeEditorHeightInPx,
  userId,
  taskId,
  onChange,
}) => {
  useEffect(() => {
    let isMounted = true;
  
    const loadCode = async () => {
      try {
        const storedCode = await CodeStorage.fetchCode(userId, taskId, language);
        if (isMounted && storedCode && storedCode !== value) onChange(storedCode);
      } catch (error) {
        console.error("Error fetching code:", error);
      }
    };
  
    loadCode();
  
    return () => {
      isMounted = false;
    };
  }, [userId, taskId, language]);

  //@ts-ignore
  const handleEditorDidMount = (editor, monaco: typeof editor) => {
    console.log("Editor did mount", editor);
    editor.focus();
  };

  //@ts-ignore
  const handleChange = (newValue: string | undefined, e: monaco.editor.IModelContentChangedEvent) => {
    if (newValue !== undefined) {
      onChange(newValue); 
      CodeStorage.debouncedSave(userId, taskId, language, newValue); //TODO MAJOR: consider if error-handling is needed here handle appropriately if async, happy to gracefully ignore execption if it fails
    }
  };

  return (
    <div className="code-editor-container" style={{height: '100%', width: '100%', minHeight: codeEditorHeightInPx}}>
      <MonacoEditor
        height="100%"
        width="100%"
        language={language}
        theme="vs-dark"
        value={value}
        onChange={handleChange}
        onMount={handleEditorDidMount}
        options={{
        fontSize: 16
        }}
      />
    </div>
  );
};

export default CodeEditor;