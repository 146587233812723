import { useEffect, useState } from 'react';
import ProgressSummary_beta from './ProgressSummary_beta';

enum ProgressStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}

export interface ILearningPath {
  title: string;
  progress: number;
  total: number;
  status: ProgressStatus; 
}

// Initial hardcoded data with enum values
const initialLearningPaths: ILearningPath[] = [
  { title: 'Front-end Development', progress: 60, total: 100, status: ProgressStatus.InProgress },
  { title: 'Back-end Development', progress: 0, total: 100, status: ProgressStatus.NotStarted },
  { title: 'Data Science', progress: 30, total: 100, status: ProgressStatus.InProgress },
  { title: 'Mobile App Development', progress: 100, total: 100, status: ProgressStatus.Completed },
];

export const cardColors = {
  [ProgressStatus.InProgress]: 'linear-gradient(to right, #5F9CFF, #3600FF)',
  [ProgressStatus.NotStarted]: 'linear-gradient(to right, #F38940, #EB4949)',
  [ProgressStatus.Completed]: 'linear-gradient(to right, #38D472, #268B82)'
};

const LearningPaths_beta = () => {
  const [learningPaths, setLearningPaths] = useState(initialLearningPaths);

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setLearningPaths([
        { title: 'Full Stack Development', progress: 80, total: 100, status: ProgressStatus.InProgress },
        { title: 'Machine Learning', progress: 0, total: 100, status: ProgressStatus.NotStarted },
        { title: 'Cloud Computing', progress: 50, total: 100, status: ProgressStatus.InProgress },
        { title: 'Cybersecurity', progress: 100, total: 100, status: ProgressStatus.Completed },
      ]); // update with new data from API
    }, 2000);
  }, []);

  const handleCardClick = (cardTitle: string) => {
    console.log(`Card clicked: ${cardTitle}`);
  };
  // TODO: Set to actual currentJourney once there are more
  const currentJourney = learningPaths[0];

  return (
    <>
      <ProgressSummary_beta currentJourney={currentJourney} />
      <div className="flex w-auto m-2.5 bg-gradient-to-b from-[#000691] to-[#1D9100]  p-0.5 rounded-lg" style={{borderRadius: '22px'}}>
      <div className="flex flex-col w-full bg-[white] rounded-md mx-auto p-5 text-l" style={{borderRadius: '20px'}}>
        <h2 className=" flex items-start text-[25px] font-bold ml-[5px] mb-[16px]">
          Learning Paths
        </h2>
        <div className="flex overflow-x-auto gap-[16px] pb-[10px]">
          {learningPaths.map((path) => (
            <div key={path.title} onClick={() => handleCardClick(path.title)} className="w-[300px] cursor-pointer mr-[16px]">
              <div className="w-[280px] rounded" style={{ background: cardColors[path.status] }}>
                <div className="p-[16px]">
                  <div className="font-bold text-[1.3rem] whitespace-nowrap overflow-hidden text-ellipsis text-white pb-[4px]">{path.title}</div>
                  <div className="text-[0.9rem] text-white pb-[2px]">
                    {path.status}
                  </div>
                  <div className="text-xs text-white">
                    Progress: {path.progress}/{path.total}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    </>
  );
};

export default LearningPaths_beta;