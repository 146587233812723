// CourseFetcherService.ts
import QuickLRU from 'quick-lru';
import { Course } from "./Constants";  // Import your Course type definition
import { getCourses } from "../externalLayerAccessor/BackEndRequests";  // Assume this function fetches data from your backend

class CourseFetcherService {
  private static instance: CourseFetcherService;  // Singleton instance
  private cache: QuickLRU<number, Course>;  // Cache instance

  private constructor() {
    this.cache = new QuickLRU({ maxSize: 5 });  // Limit cache to 5 items
  }

  // Method to get the singleton instance of the service
  public static getInstance(): CourseFetcherService {
    if (!CourseFetcherService.instance) {
      CourseFetcherService.instance = new CourseFetcherService();
    }
    return CourseFetcherService.instance;
  }

  // Fetches a single course by ID, using the cache if available
  public async fetchCourseById(courseId: number): Promise<Course> {
    if (this.cache.has(courseId)) {
      return this.cache.get(courseId)!;  // Return cached course if available
    }

    // Fetch course from backend since it's not cached
    const courseData: Course[] | null = await getCourses([courseId]);

    if (courseData && courseData.length === 1) {
      // Cache the fetched course
      this.cache.set(courseId, courseData[0]);
      return courseData[0];
    } else if (!courseData) {
      return Promise.reject(new Error(`Course with ID ${courseId} not found`));
    } else {
      return Promise.reject(new Error(`An unexpected error occurred while fetching course with ID ${courseId}`));
    }
  }

  // Possible TODO: public async fetchMultipleCourses(courseIds: number[], maxLimit: number = 10): Promise<Course[]>;
}

export default CourseFetcherService.getInstance();  // Export the singleton instance
