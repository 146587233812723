import React, { useContext, useEffect, useState } from 'react';
import '../css/CoursesHome.css';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { CourseContext } from '../utils/CoursesContext';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { AccessLevel, AssessmentType, Course, Unit } from '../utils/Constants';
import { faPlay, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import StateAccessor from '@/StateAccessor';
import CourseFetcherService from '@/utils/CourseFetcherService';

interface CourseHomeProps {
  disabled?: boolean;
}

const CoursesHome: React.FC<CourseHomeProps> = () => {
  const {userSubscriptionTier} = useContext(StateAccessor);
  const { course_id } = useParams();
  const parsedCourseId = Number(course_id);
  // const { userId } = useContext(StateAccessor);
  const navigate = useNavigate(); // Get the history object from react-router-dom

  const [course, setCourse] = useState<Course | null>(null); // Use state for the course
  const [errorMessage, setErrorMessage] = useState<string>('Loading course...'); // Use state for error messages

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const fetchedCourse = await CourseFetcherService.fetchCourseById(parsedCourseId); // Fetch course by ID
        setCourse(fetchedCourse);
        setErrorMessage(''); // Clear error message when course is fetched
      } catch (err) {
        setErrorMessage((err as Error).message); // Set error message if fetching fails
      }
    };

    fetchCourse(); // Trigger course fetching when the component mounts or course_id changes
  }, [parsedCourseId]); // Add parsedCourseId as dependency


  if (errorMessage || !course) {
    return <div>{errorMessage}</div>;
  }

  if (!course) {
    return <div>Loading course...</div>;
  }

const handleUnitBoxClick = (moduleId: number, submodule_id: number, unit: Unit) => {
    navigate(`/app/learning/courses/${parsedCourseId}/modules/${moduleId}/submodules/${submodule_id}/units/${unit.unit_id}/challenge/${unit.challenge.challenge_id}`);
};

  return (
    <>
      {/* <Sidebar enableMinification={true} userId={userId}/> */}
      <div className='courses-home'>
        <h1 className='course-home-title'>{course.title}</h1>
        <div className='courses-home-container' key={course.course_id}>
          {course.modules.map(module => (
            <div className='module' key={module.module_id}>
              <h2 className='module-home-title'>{module.title} module</h2>
              {module.submodules.map(submodule => (
                <div className='submodule' key={submodule.submodule_id} id={`module-${module.module_id}-submodule-${submodule.submodule_id}`}>
                  <div className='submodule-header'>
                    <h3 className='submodule-home-title'>{submodule.title}</h3>
                    {submodule.estimated_completion_time_in_minutes !== null && (
                      <div className='completion-time'>
                        Estimated completion time: {submodule.estimated_completion_time_in_minutes} minutes
                      </div>
                    )}
                  </div>
                  <div className='units-container'>
                    {submodule.units.map(unit => {
                      if (unit.challenge.assessment_type === AssessmentType.SKIP_DUMMY_CHALLENGE) {
                        return null;
                      }

                      return (
                        <div className='unit-box' key={unit.unit_id} onClick={() => handleUnitBoxClick(module.module_id, submodule.submodule_id, unit)}>
                          <div className='unit-top-row'>
                            <div className='unit-title'>
                              <h5>{unit.title}</h5>
                            </div>
                            <button className={`start-challenge-button ${unit.challenge.difficulty}`}>
                              <FontAwesomeIcon
                                icon={unit.access_level === AccessLevel.Premium && !userHasPremiumSubscriptionPrivilege(userSubscriptionTier) ? faLock : faPlay} // Conditional icon
                                style={{ fontSize: "1em", marginRight: "10px" }}
                              />
                              {unit.access_level === AccessLevel.Premium ? 'Premium' : 'Standard'}
                            </button>
                          </div>
                          <div className='challenge-info'>
                            <div className='tags'>
                              <span className={`challenge_difficulty_tag ${unit.challenge.difficulty}`}> {unit.challenge.difficulty} </span>
                              <span className={`challenge_type_tag ${unit.challenge.challenge_type}`}> {unit.challenge.challenge_type} </span>
                            </div>
                            <div className='time-limit'>
                              <span className='time-limit-label'>Time Limit:</span> {unit.challenge.time_limit_in_minutes} minutes
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <Tooltip id="premium-tooltip" />
        <Outlet />
      </div>
    </>
  );
};

export default CoursesHome;
