import React from 'react';
import GenericLearningHome, { ActiveTiles, LearningComponents } from './GenericLearningHome';

// Define a wrapper component
const MockInterviewMenu: React.FC = () => {
  // Specify the titles that should be active
  const activeTitles: ActiveTiles = new ActiveTiles([
    [LearningComponents.HumanMockInterviews, 1],
    [LearningComponents.AIMockInterviews, 2],
    // Add more titles as needed
  ]);

  return (
    <div>
      {/* Pass activeTitles as a prop to GenericLearningHome */}
      <GenericLearningHome 
        activeTitles={activeTitles} 
      />
    </div>
  );
};

export default MockInterviewMenu;