import { styled } from "@mui/system";
import { TextField, Button } from "@mui/material";


export const Form = styled("form")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 16,
});

export const StyledTextField = styled(TextField)({
    marginBottom: 16,
});

export const StyledButton = styled(Button)({
    marginTop: 16,
});

export const ErrorText = styled("p")({
    color: "red",
    marginBottom: 16,
});

export const AuxiliaryButton = styled(Button)({
    marginTop: 4,
    textDecoration: "underline",
});