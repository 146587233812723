// src/utils/stripe.ts
import { loadStripe, Stripe } from '@stripe/stripe-js';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY

if (!stripePublicKey) {
  console.error('Stripe public key is missing. Please set REACT_APP_STRIPE_PUBLIC_KEY in your environment variables.');
}

// loadStripe returns a Promise that resolves to a Stripe instance
export const stripePromise: Promise<Stripe | null> = stripePublicKey ? loadStripe(stripePublicKey) : Promise.resolve(null);
