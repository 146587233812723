// src/pages/About.tsx
import React from 'react';
import '../css/DefaultFooterPage.css';

const About: React.FC = () => {
  return (
    <div className="page-content">
      <h1>About Us</h1>
      <p>
        At Coditioning, we're redefining how people learn. Our mission is simple yet ambitious: to make learning faster, ensure retention of what you learn, and make the process pain-free.
      </p>

      <h2>Our Approach to Learning</h2>
      <p>
        <strong>Optimized Learning Experience:</strong> We leverage insights from neuroscience to tailor our content and approach to align with how the human brain actually learns. We also recognize that some people may have learning style preferences. That's why we factor in individual preferences, strengths, and weaknesses to provide a customized experience. Through a combination of human experts and AI assistants, we deliver personalized learning journeys that adapt to your unique needs.
      </p>


      <h2>Focused on Software Engineers</h2>

      <p>
        <strong>Targeted Interview Preparation:</strong> Our initial focus is on helping software engineers succeed in the increasingly competitive job market. The interview process today goes beyond just evaluating technical skills—it's a harsh filtering mechanism driven by supply and demand dynamics. Our goal is to make your interview preparation as seamless and stress-free as possible while ensuring you are objectively interview-ready, meaning you can perform under real interview conditions. We aim to optimize your interview prep to maximize your chances of landing your dream job. We don't want our users to miss out on opportunities for preventable reasons.
      </p>

      <h2>Our Vision</h2>

      <p>
        While we currently focus on software engineering, our vision extends far beyond this field. We're developing a platform that will support other domains and subjects in the future. At Coditioning, we aim to optimize human learning in all its forms.
      </p>

      <h2>Join Us</h2>

      <p>
        Start your journey with Coditioning today and unlock your full potential. We are here to help you succeed!
      </p>
    </div>


  );
};

export default About;
