export enum UserSubscriptionTier {
    FREE = "FREE",
    PREMIUM = "PREMIUM",
    ADMIN = "ADMIN",
  }
  
  export interface UserSubscription {
    displayName: string | null;
    userEmail: string;
    subscriptionTier: UserSubscriptionTier;
    subscriptionExpiry: Date | null;
  }
  