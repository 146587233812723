import React, { useContext } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PaymentIcon from '@mui/icons-material/Payment';
import { Button } from '@mui/material';
import './css/BookingAndPayment.css';
import { openUrlInNewTab } from '../../utils/HelperFunctions';
import StateAccessor from '../../StateAccessor';
import { MONEY_BACK_GUARANTEE_STATEMENT } from '../Terms';


const DEDICATED_INTERVIEW_PREP_SESSION = 'https://calendly.com/coditioning/company-specific-dedicated-interview-prep';


interface BookingOptionsProps {
    userId: number;
}

const BookingOptions: React.FC<BookingOptionsProps> = ({ userId }) => {
    return (
        <>

            <div className="booking-options-container" id="booking-section">
                <h1>Book one or more sessions</h1>

                <Button
                    className='book-session-button'
                    variant="contained"
                    color="primary"
                    onClick={() => openUrlInNewTab(DEDICATED_INTERVIEW_PREP_SESSION, userId)}
                    style={{
                        textTransform: 'none',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        fontSize: '1.3rem',
                    }}
                    title='Use this button to start a brand new booking'
                >
                    <ScheduleIcon style={{ marginRight: '0.5rem' }} />
                    Book Session (No Payment Required)
                </Button>

            </div>


        </>
    );
};

interface PaymentOptionsProps {
    userId: number;
}


const PaymentOptions: React.FC<PaymentOptionsProps> = ({ userId }) => {
    const BUNDLED_SESSIONS_PAYMENT_URL = "https://buy.stripe.com/fZecNw6Zhe0jdygeV1";
    const SINGLE_SESSION_PAYMENT_URL = "https://buy.stripe.com/8wM3cW0AT3lF3XG8wH";
    const CODITIONING_360_PAYMENT_URL = "https://buy.stripe.com/28o3cW6Zh6xRgKs3co";

    const standardPrepSessionFeatures = (
        <>
          <div className="service-feature">✅ 💯% money back guarantee</div>
          <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years)</div>
          <div className="service-feature">✅ Hire or No-Hire Decision (Know where you stand)</div>
          <div className="service-feature">✅ Tailored to your target companies</div>
          <div className="service-feature">✅ Detailed actionable feedback to level you up quickly</div>
          <div className="service-feature">✅ Real-time feedback (coaching sessions)</div>
          <div className="service-feature">✅ Access to private recording of the session (if you opt in)</div>
        </>
      );

    return (
        <>
            <h1 id='payment-section'> Pay </h1>
            <div className="payment-options-grid">
                
                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Bundled Sessions</h1>
                        <span className='recommendedTag'> Cheapest</span>
                    </div>

                    
                    <div className="price-tag">$99.99/hour <span className="price-tag-subtitle">(before promotion code)</span></div>
                    <Button
                        className='payment-button'
                        variant="contained"
                        color="secondary"
                        onClick={() => openUrlInNewTab(BUNDLED_SESSIONS_PAYMENT_URL, userId)}
                        style={{
                            marginTop: '0.5rem',
                            backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                            color: 'black',
                            textTransform: 'none'
                        }}
                    >
                        <PaymentIcon style={{ marginRight: '0.5rem' }} />
                        Purchase Bundled Sessions
                    </Button>
                    

                    {/* Features */}
                    {standardPrepSessionFeatures}
                    <div className="service-feature">✅ Save when you book multiple sessions</div>
                    <div className="service-feature">✅ Mix & match different session domains (system design, coding, behavioural etc)</div>

                </div>

                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Single Session</h1>
                        <span className='recommendedTag'> Best for sampling</span>
                    </div>
                    
                    <div className="price-tag">$109.99/hour <span className="price-tag-subtitle">(before promotion code)</span></div>
                    <Button
                        className='payment-button'
                        variant="contained"
                        color="secondary"
                        onClick={() => openUrlInNewTab(SINGLE_SESSION_PAYMENT_URL, userId)}
                        style={{
                            marginTop: '0.5rem',
                            backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                            color: 'black',
                            textTransform: 'none'
                        }}
                    >
                        <PaymentIcon style={{ marginRight: '0.5rem' }} />
                        Purchase Single Session
                    </Button>

                    {/* Features */}
                    {standardPrepSessionFeatures}

                    
                </div>

                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Coditioning 360°</h1>
                        <span className='recommendedTag'>Comprehensive</span>
                    </div>
                    
                    <div className="price-tag">$169.99/hour <span className="price-tag-subtitle">(before promotion code)</span></div>
                    <Button
                        className='payment-button'
                        variant="contained"
                        color="secondary"
                        onClick={() => openUrlInNewTab(CODITIONING_360_PAYMENT_URL, userId)}
                        style={{
                            marginTop: '0.5rem',
                            backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                            color: 'black',
                            textTransform: 'none'
                        }}
                    >
                        <PaymentIcon style={{ marginRight: '0.5rem' }} />
                        Purchase Coditioning 360°
                    </Button>

                    <div className="service-feature">✅ 💯% money-back guarantee*</div>
                    <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years of experience)</div>
                    <div className="service-feature">✅ End-to-end guidance</div>
                    <div className="service-feature">✅ Access to the Coditioning Lounge (Private Slack Server)</div>
                    <div className="service-feature">✅ Full support outside coaching/mock sessions</div>
                    <div className="service-feature">✅ Tailored interview prep roadmap</div>
                    <div className="service-feature">✅ 5+ hour-long sessions</div>
                    <div className="service-feature">✅ Target company-specific tailoring</div>
                    <div className="service-feature">✅ Rigorous Data Structures & Algorithms Strengths & Weaknesses Diagnostic</div>
                    <div className="service-feature">✅ Behavioral Response Crafting</div>
                    <div className="service-feature">✅ At least 1 Strategy Session</div>
                    <div className="service-feature">✅ At least 3 Mock Interviews</div>
                    <div className="service-feature">✅ Team-matching & negotiation guidance</div>
                    <div className="service-feature">✅ Full access to Coditioning Premium</div>
                    <div className="service-feature">✅ CV Review</div>

                    
                    
                </div>

            </div>

            <div className="money-back-guarantee">
                <p>
                    <a href="/terms/#money-back-guarantee">
                        <strong>*{MONEY_BACK_GUARANTEE_STATEMENT}</strong>
                    </a>
                </p>
            </div>
        </>
    );
};


const BookingAndPayment: React.FC = () => {
    const { userId } = useContext(StateAccessor);

    return (
        <div className="booking-and-payment-container landing-page-row">
            <BookingOptions userId={userId} />
            <hr />
            <PaymentOptions userId={userId} />


        </div>
    );
};

export default BookingAndPayment;


