import React, { useContext } from 'react';
import { Button } from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import '../css/PremiumContentAccessDeniedMessage.css';
import { useNavigate } from 'react-router-dom';
import { SIGN_UP_PAGE_PATH } from './Constants';
import { isUserSignedIn } from './HelperFunctions';
import StateAccessor from '@/StateAccessor';
import Modal from '../utils/Modal';
import Subscriptions from '@/pages/AccountManagement/Subscriptions';

const PremiumContentAccessDeniedMessage: React.FC = () => {

  const navigate = useNavigate();
  const { userId } = useContext(StateAccessor);
  const [showSubscriptionModal, setShowSubscriptionModal] = React.useState(false);

  const handleSubscribeButtonClick = () => {
    if (!isUserSignedIn(userId)) {
      navigate(SIGN_UP_PAGE_PATH);
    } else {
      setShowSubscriptionModal(true);
    }
  }

  return (
    <>
      {showSubscriptionModal && (
        <SubscriptionModal
          onClose={() => setShowSubscriptionModal(false)}
        />
      )
      }

      <div className='premium-content-access-denied-message'>
        <p>🚫 You need a premium subscription to access this content</p>

        <Button
          color="secondary"
          className="premium-subscribe-button"
          variant="contained"
          title="Sign up for a premium subscription to access this content"
          onClick={handleSubscribeButtonClick}
          style={{
            color: "black",
            textTransform: "none",
          }
          }
        >
          <AutoAwesomeIcon style={{ marginRight: "5px" }} />
          Try Coditioning Premium
        </Button>
      </div>
    </>
  )
};

interface SubscriptionModalProps {
  onClose: () => void;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ onClose }) => {

  return (
    <Modal
      content={
        <Subscriptions />
      }
      onClose={onClose}
    />
  );
};

export default PremiumContentAccessDeniedMessage;