import React, { createContext, useState, ReactNode, useEffect } from "react";
import { Course } from "./Constants";
import { getCourses } from "../externalLayerAccessor/BackEndRequests";

interface ICourseContext {
  courses: Course[] | null;
  error: string | null;
}

export const CourseContext = createContext<ICourseContext>({ courses: null, error: null });

interface CourseProviderProps {
  children: ReactNode;
}

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
NOTE: [LOW PRIORITY] Strongly consider decommissioning this whenever an opportunity arises
Having a Provider i.e. global state for Fetching Courses isn't needed
We have a CourseFetcherService singleton that can be used to fetch all courses or courses by Id, and it has a cache

Suing the provider in APP.tsx makes the routing logic more complex, and we can avoid this
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
export const CourseProvider: React.FC<CourseProviderProps> = ({ children }) => {
  const [courses, setCourses] = useState<Course[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coursesData = await getCourses();
        if (coursesData) {
          setCourses(coursesData);
        } else {
          setError('Invalid courses data');
        }
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchData();
  }, []);

  const handleFetchError = (error: any) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          setError("Bad request for courses");
          break;
        case 404:
          setError("Courses not found");
          break;
        case 500:
          setError("An unexpected error occurred");
          break;
        default:
          setError(error.response.data.message || "Unknown error");
      }
    } else if (error.request) {
        setError("No response from server.");
    } else {
        setError(error.message);
    }
    console.error(error);
  };

  return (
    <CourseContext.Provider value={{ courses, error }}>
      {children}
    </CourseContext.Provider>
  );
};
