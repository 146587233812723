class Progress {
  progress_value: number;
  total: number;

  constructor(progress_value: number, total: number) {
    if (progress_value < 0 || progress_value > total) {
      console.warn("Progress value is out of range, setting to 0");
      progress_value = 0;
    }
    this.progress_value = progress_value;
    
    if (total <= 0) {
      console.warn("Total is out of range, setting to 1");
      total = 1;
    }
    this.total = total;
  }

  // Update the progress value
  updateProgress(progress_value: number) {
    if (progress_value < 0 || progress_value > this.total) {
      console.warn("Progress value is out of range, setting to 0");
      progress_value = 0;
    }
    this.progress_value = progress_value;
  }

  calculatePercentage(): number {
    if (this.total === 0) {
      console.warn("Total is zero, returning default value of 0");
      return 0;
    }
    return (this.progress_value / this.total) * 100;
  }

  getProgressFraction(): string {
    return `${this.progress_value} / ${this.total}`;
  }
}

export default Progress;