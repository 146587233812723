import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";


const PasswordResetPage = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);


  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert(`Password reset email sent to ${email}`);
      navigate("/signin");
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Error sending password reset email:", error);
      } else {
        // Handle cases where the caught value is not an Error object
        console.error("An unknown error occurred:", error);
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center">
        Forgot Password?
      </Typography>
      {error && (
        <Box mt={2} mb={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
      <Box mt={2}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Button
          onClick={handlePasswordReset}
          variant="contained"
          color="primary"
          fullWidth
        >
          Send Password Reset Email
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          onClick={() => navigate("/signin")}
          variant="outlined"
          color="primary"
          fullWidth
        >
          Back to Sign In
        </Button>
      </Box>
    </Container>
  );
};

export default PasswordResetPage;
