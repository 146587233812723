import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../css/DropdownItem.css';
interface DropdownItemProps {
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ title, children, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTitleHovered, setTitleHovered] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTitleMouseEnter = () => {
    setTitleHovered(true);
  };

  const handleTitleMouseLeave = () => {
    setTitleHovered(false);
  };

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <li className={`list-container ${isTitleHovered ? 'hovered' : ''} ${disabled ? 'disabled' : 'shown'}`}>
      <div className='list-title-container' onClick={handleToggle} onMouseEnter={handleTitleMouseEnter} onMouseLeave={handleTitleMouseLeave}>
        {title}
        {isOpen ? <KeyboardArrowUpIcon style={{ float: 'right' }} /> : <KeyboardArrowDownIcon style={{ float: 'right' }} />}
      </div>
      {isOpen && (
        <ul className={`dropdown ${isOpen ? 'open' : ''}`} onClick={handleContentClick}>
          {children}
        </ul>
      )}
    </li>
  );
};

export default DropdownItem;
