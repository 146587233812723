import React from 'react';
// import '../css/TestimonialConfig.css'

interface Testimonial {
  id: number;
  text: string;
  name: string;
  jobTitle: string;
  companyLogo: string;
  companyAlt: string;
}

interface TestimonialsProps {
  testimonials: Testimonial[];
}

const TestimonialConfig: React.FC<TestimonialsProps> = ({ testimonials }) => {
  return (
    <div className="testimonials">
      <h3>Testimonials</h3>
      {testimonials.map((testimonial) => (
        <div key={testimonial.id} className="testimony">
          <p>{testimonial.text}</p>
          <div className="nameTag">
            <p className="name">{testimonial.name}</p>
            <p className="jobTitle">{testimonial.jobTitle}</p>
            {testimonial.companyLogo && (
              <img
                src={testimonial.companyLogo}
                className="company-logo"
                alt={testimonial.companyAlt}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestimonialConfig;
