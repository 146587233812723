import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/GenericLearningHome.css';
import CoursesMenu from './CoursesMenu';
import Progress from '../utils/Progress';
import LearningHomeSubMenu from './LearningHomeSubMenu';
import { logToBackendLogFile } from '../externalLayerAccessor/BackEndRequests';
import StateAccessor from '../StateAccessor';

import { CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG, WORKSHOPS_PAGE_PATH } from '../utils/Constants';
import {BannerButtonProps} from '../utils/BannerButton';

export class ActiveTiles {
  private activeTitles: Map<LearningComponents, number>;
  private firstActiveComponent: LearningComponents | null;

  /*
  Constructore accepts an array of tuples where the first element is the LearningComponent and the second element is the rank
  The rank dictates the order in which the components should be displayed, and should ideally be used to enforce this order
  */
  constructor(initialData: [LearningComponents, number][]) {
    this.activeTitles = new Map(initialData);
    
    if(initialData.length > 0) {
      this.firstActiveComponent = initialData[0][0]; // initialData[0] returns the first tuple in the array, and [0] returns the first element of the tuple
    } else{
      this.firstActiveComponent = null;
    }
  }

  getRankByLearningComponent(component: LearningComponents): number {
    if (this.activeTitles.has(component)) {
      return this.activeTitles.get(component)!;
    } else {
      return -1;
    }
  }

  getFirstActiveComponent(): LearningComponents | null {
    return this.firstActiveComponent;
  }

  isEmpty(): boolean {
    return this.activeTitles.size === 0;
  }

  has(component: LearningComponents): boolean {
    return this.activeTitles.has(component);
  }
}

interface LearningHomeProps {
  activeTitles: ActiveTiles
  disabled?: boolean;
  banner?: BannerButtonProps | null;
}

const MemoizedLearningHomeSubMenu = React.memo(LearningHomeSubMenu);


export enum LearningComponents {
  /*
  *************** IMPORTANT ***************
  Until we have a better solution
  If you add a new component here you need to update cssClassNames so that the component has a background image.
  *************** IMPORTANT ***************
  */
  AssessInterviewReadiness = 'Assess your Interview Readiness',
  IdentifyKnowledgeGaps = 'Identify Weaknesses & Knowledge Gaps',
  LearningPath = 'Custom Learning',
  ExploreContent = 'Explore Content',
  Coaching = 'One-to-one Coaching',
  InstructorLedCrashCourses = 'Instructor-led Crash Courses',
  HumanMockInterviews = 'Mock Interviews',
  AIMockInterviews = 'AI Mock Interviews',
  SideQuests = 'Side Quests',
  StudyGroups = 'Study Groups',
  Leaderboard = 'Leaderboard',
  Revision = 'Revision',
  Achievements = 'Achievements',

  /*Crash Courses*/
  AlgosAndDataStructuresCrashCourse = 'Algorithms & Data Structures (Foundations)',
  AlgosAndDataStructuresCrashCourseIntermediate = 'Advanced Techniques for Coding Interviews', // will cover things like pattern spotting, coming up with optimal solutions, 
  SystemDesignCrashCourse = 'Mastering System Design Interviews',
  OptimizeYourInterviewPerformanceCrashCourse = 'Slaying the Technical Interview', // this is about interviewing skills & preparation strategies

  /*Workshops*/
  SystemDesignWorkShop = 'System Design Workshop',
  CodingAndBehaviouralWorkShop = 'Coding and Behavioural Workshop',
  
  /*Courses*/
  SystemDesignCourse = 'System Design',
  None = 'None',
}

interface LearningCardInfo {
  title: LearningComponents;
  cssClass: string;
}

// Create a Map of LearningComponents to CSS class names
const cssClassNames: Map<LearningComponents, string> = new Map([
  [LearningComponents.IdentifyKnowledgeGaps, 'background-discover-weaknesses'],
  [LearningComponents.LearningPath, 'background-custom-learning'],
  [LearningComponents.ExploreContent, 'background-explore-content'],
  [LearningComponents.Coaching, 'background-coaching'],
  [LearningComponents.InstructorLedCrashCourses, 'background-instructor-led-crash-courses'],
  [LearningComponents.SideQuests, 'background-side-quests'],
  [LearningComponents.StudyGroups, 'background-study-groups'],
  [LearningComponents.Leaderboard, 'background-leaderboard'],
  [LearningComponents.Revision, 'background-revision'],
  [LearningComponents.Achievements, 'background-achievements'],
  [LearningComponents.AlgosAndDataStructuresCrashCourse, 'background-algorithms-i'],
  [LearningComponents.AlgosAndDataStructuresCrashCourseIntermediate, 'background-algorithms-ii'],
  [LearningComponents.SystemDesignCrashCourse, 'background-system-design'],
  [LearningComponents.OptimizeYourInterviewPerformanceCrashCourse, 'background-optimise-interview-performance'],
  [LearningComponents.AssessInterviewReadiness, 'background-assess-interview-readiness'],
  [LearningComponents.HumanMockInterviews, 'background-human-mock-interviews'],
  [LearningComponents.AIMockInterviews, 'background-ai-mock-interviews'],
  [LearningComponents.SystemDesignWorkShop, 'background-system-design-workshop'],
  [LearningComponents.CodingAndBehaviouralWorkShop, 'background-coding-and-behavioural-workshop'],
  [LearningComponents.SystemDesignCourse, 'background-system-design-course'],
  [LearningComponents.None, ''],
]);

// Use the Map when creating the learningCards array
const learningCards: LearningCardInfo[] = Object.values(LearningComponents).map(learningComponent => ({
  title: learningComponent,
  cssClass: cssClassNames.get(learningComponent) || '',
}));


/**
 * Renders learning menu
 * @param {boolean} disabled - If true, render null
 */
const GenericLearningHome: React.FC<LearningHomeProps> = (
  { 
    activeTitles, 
    disabled = false, 
    banner = {
      text: CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG,
      url: WORKSHOPS_PAGE_PATH,
      }
    
  }
) => {
  const [activeComponent, setActiveComponent] = useState<LearningComponents | null>(LearningComponents.LearningPath);
  const { userId } = useContext(StateAccessor);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialActiveComponentName = queryParams.get("activeComponent");
    
    if (initialActiveComponentName && Object.values(LearningComponents).includes(initialActiveComponentName as LearningComponents)) {
      setActiveComponent(initialActiveComponentName as LearningComponents);
    } else {
      
      //if(activeTitles.size > 0) setActiveComponent(activeTitles.values().next().value); // this sets the first value in the set as the active component
      if(!activeTitles.isEmpty()) setActiveComponent(activeTitles.getFirstActiveComponent()!);
    }
  }, [location.search]); // useEffect called when the URL search parameters change


  const handleCardClick = (title: LearningComponents) => {
    setActiveComponent(title);
  };

  // Add components when other learning cards are clicked as needed
  const renderActiveComponent = () => {
    logToBackendLogFile(
      `Viewed the ${activeComponent} component from the learning menu`,
      "info",
      userId);

    if(activeComponent ===  LearningComponents.ExploreContent) {
      return <CoursesMenu progress={new Progress(0, 1)} />;
    }else if(activeComponent != null){
      return <MemoizedLearningHomeSubMenu mode={activeComponent} />;
    }else {
      return <MemoizedLearningHomeSubMenu mode={LearningComponents.None} />;
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <div className='learning-home'>
      <div className='learning-menu'>
        <div className="learning-cards">
          {learningCards
          .filter(card => activeTitles.has(card.title))
          .map(card => ({...card, orderRank: activeTitles.getRankByLearningComponent(card.title)}))
          .sort((card1, card2) => card1.orderRank - card2.orderRank!) // sort cards by rank
          .map(card => (
            // Remove disabled-card class for card to be clickable
            <div key={card.title} className={`learning-card-container`} title={card.title}>
              <h6 className='learning-card-title'>{card.title}</h6>
              <div
                className={`learning-card ${card.cssClass} ${card.title === activeComponent ? 'active-learning-card' : ''}`}
                onClick={() => handleCardClick(card.title)}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className='active-component-container'>
        {renderActiveComponent()}
      </div>
    </div>
  );
};
export default GenericLearningHome;