export enum TaskStatus {
    Completed = 'Completed',
    InProgress = 'In Progress',
    Locked = 'Locked',
  }
  
  export interface TaskProps {
    id: number;
    title: string;
    status: TaskStatus;
    points: number;
  }
  
  export const tasksData: TaskProps[] = [
    { id: 1, title: 'Coding Challenge', status: TaskStatus.Locked, points: 50 },
    { id: 2, title: 'Read a Document', status: TaskStatus.Locked, points: 25 },
    { id: 3, title: 'Watch a Video', status: TaskStatus.Locked, points: 40 },
    { id: 4, title: 'Take Quiz', status: TaskStatus.Locked, points: 20 },
    { id: 5, title: 'Read a Document', status: TaskStatus.Locked, points: 25 },
    { id: 6, title: 'Coding Challenge', status: TaskStatus.Locked, points: 50 },
    { id: 7, title: 'Watch a Video', status: TaskStatus.Locked, points: 40 },
    { id: 8, title: 'Take Quiz', status: TaskStatus.Locked, points: 20 },
  ];
  