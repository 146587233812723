import React from 'react';
import Modal from '../utils/Modal';

interface PostInterviewFeedbackProps {
    overallFeedbackSummaryHtml: string;
    onClose: () => void;
  }

const PostInterviewFeedback: React.FC<PostInterviewFeedbackProps> = ({ overallFeedbackSummaryHtml, onClose }) => {
    return (
        <Modal
            onClose={onClose}
            content={
                <>
                <h1>Feedback on your Interview</h1>
                <div dangerouslySetInnerHTML={{ __html: overallFeedbackSummaryHtml }} />
                </>
            }
        />
    );
};

export default PostInterviewFeedback;
