import React, { useState, useEffect, useCallback, useContext } from "react";
import "../css/AssessmentFeedback.css";
import {
  AssessmentAttemptReport,
  DIAGNOSTICS_PAGE_PATH,
  Difficulty,
  LEARNING_PAGE_PATH,
  UnitAttemptReport,
  UnitConceptTags,
} from "../utils/Constants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@mui/material";
import AssessmentUnitFeedback from "./AssessmentUnitFeedback";
import { useNavigate } from "react-router-dom";
import { LearningComponents } from "./GenericLearningHome";
import StateAccessor from "../StateAccessor";
import { logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import Modal2 from '@/utils/Modal2';

export const KeyExplanations: React.FC = () => {
    return (
        <div className="key-explanations">
            <h3 className='h3-key'>Key:</h3>
            <ul className='ul-key'>
                <li><span className="evaluation-strong">Strong Strength:</span> Excellent understanding of the concept.</li>
                <li><span className="evaluation-moderate">Moderate Strength:</span> Good understanding but we need to ask you more challenging questions or you may need to level up.</li>
                <li><span className="evaluation-weak">Weak Strength:</span> Needs improvement.</li>
                <li><span className="evaluation-strong">High Confidence:</span> We are very confident about our assessment of your strength.</li>
                <li><span className="evaluation-moderate">Medium Confidence:</span> We are somewhat confident about our assessment of your strength.</li>
                <li><span className="evaluation-weak">Low Confidence:</span> We need more data to be confident about our assessment of your strength.</li>
            </ul>
        </div>
    );
};

/**
 * Enum for representing the strength of an evaluation.
 * IMPORTANT: The values must be integers.
 */
export enum Strength { // NOTE IMPORTANT: DO NOT MODIFY THIS CLASS, otherwise you will have to review logic relying on there just being 3 values and fix
  UNKNOWN = 0,
  Weak = 1,
  Moderate = 2,
  Strong = 3,
}

/**
 * Enum for representing the strength of an evaluation.
 * IMPORTANT: The values must be integers.
 */
export enum Confidence { // NOTE IMPORTANT: DO NOT MODIFY THIS CLASS, otherwise you will have to review logic relying on there just being 3 values and fix
  Low = 1,
  Medium = 2,
  High = 3,
}

/*
Expression of evaluation of user's conceptual understanding of a unit concept.
*/
export interface ConceptEvaluation {
  concept: UnitConceptTags;
  strength: Strength;
  confidence: Confidence;
}

enum AssessmentStatus {
  NotStarted = "Not Started",
  InProgress = "In Progress",
  Completed = "Completed",
}

interface UserAssessmentState {
  assessmentId: number;
  assessmentName: string;
  status: AssessmentStatus;
}

type AssessmentFeedbackProps = {
  latestAssessmentAttemptReport: AssessmentAttemptReport;
};

// Unifies multiple evaluations for each concept into a single representative evaluation
function unifyEvaluations(
  conceptEvaluations: ConceptEvaluation[]
): ConceptEvaluation[] {
  // Initialize empty hashmap to hold counts and sums for each concept
  const conceptMap: {
    [key: string]: {
      strengthSum: number;
      strengthCounts: { [key: number]: number };
      numEvaluations: number;
    };
  } = {};

  // Populate the hashmap with sums and counts
  for (const conceptEval of conceptEvaluations) {
    const concept = conceptEval.concept;
    if (!conceptMap[concept]) {
      conceptMap[concept] = {
        strengthSum: 0,
        strengthCounts: {},
        numEvaluations: 0,
      };
    }
    conceptMap[concept].strengthSum += conceptEval.strength;
    conceptMap[concept].strengthCounts[conceptEval.strength] =
      (conceptMap[concept].strengthCounts[conceptEval.strength] || 0) + 1;
    conceptMap[concept].numEvaluations++;
  }

  // Generate the unified evaluations
  const unifiedEvaluations: ConceptEvaluation[] = [];
  for (const [concept, data] of Object.entries(conceptMap)) {
    // Calculate the average strength across all evaluations for this concept
    const avgStrength = Math.round(data.strengthSum / data.numEvaluations);

    // Initialize a variable to hold the final confidence level for this concept
    let confidence: Confidence;

    // Rule 1: If only one evaluation exists, confidence is Medium
    if (data.numEvaluations === 1) {
      confidence = Confidence.Medium;
    } // multiple evaluations exist below this point
    // Rule 2: If multiple evaluations exist and all have the same strength, confidence is High
    else if (Object.keys(data.strengthCounts).length === 1) {
      // single strength so all evaluations have the same strength
      confidence = Confidence.High;
    }
    // Rule 3: If multiple evaluations exist and they have varying strengths
    else {
      // Initialize counts for 'Strong' and 'Weak' strengths, default to 0
      const strongCount = data.strengthCounts[Strength.Strong] || 0;
      const weakCount = data.strengthCounts[Strength.Weak] || 0;

      // Rule 3a: If evaluations have both 'Strong' and 'Weak', confidence is Low
      if (strongCount > 0 && weakCount > 0) {
        confidence = Confidence.Low;
      }
      // Rule 3b: Otherwise, the confidence is Medium
      else {
        confidence = Confidence.Medium;
      }
    }

    // Create the unified evaluation object and add it to the list
    unifiedEvaluations.push({
      concept: concept as UnitConceptTags,
      strength: avgStrength as Strength,
      confidence,
    });
  }

  return unifiedEvaluations;
}

const EvaluationSummary: React.FC<{
  latestAssessmentAttemptReport: AssessmentAttemptReport;
}> = ({ latestAssessmentAttemptReport }) => {
  const renderEvaluation = (evaluation: ConceptEvaluation) => {
    let strengthClass = "";
    let confidenceClass = "";

    switch (evaluation.strength) {
      case Strength.Strong:
        strengthClass = "evaluation-strong";
        break;
      case Strength.Moderate:
        strengthClass = "evaluation-moderate";
        break;
      default:
        strengthClass = "evaluation-weak";
        break;
    }

    switch (evaluation.confidence) {
      case Confidence.High:
        confidenceClass = "evaluation-strong";
        break;
      case Confidence.Medium:
        confidenceClass = "evaluation-moderate";
        break;
      default:
        confidenceClass = "evaluation-weak";
        break;
    }

    return (
      <tr key={evaluation.concept}>
        <td className={strengthClass}>{evaluation.concept}</td>
        <td className={strengthClass}>{Strength[evaluation.strength]}</td>
        <td className={confidenceClass}>{Confidence[evaluation.confidence]}</td>
      </tr>
    );
  };

  const evaluateStrength = (unitAttemptReport: UnitAttemptReport): Strength => {
    /*
        Rule for evaluating strength:
            if question answered incorrectly then strength is weak
            if challenging question answered correctly then strength is strong
            if moderately challenging question answered correctly then strength is strong
            if easy question answered correctly then strength is moderate
        */

    if (!unitAttemptReport.answeredCorrectly) {
      return Strength.Weak;
    }

    switch (unitAttemptReport.unit.challenge.difficulty) {
      case Difficulty.Challenging:
      case Difficulty.ModeratelyChallenging:
        return Strength.Strong;

      case Difficulty.Straightforward:
        return Strength.Moderate;

      default:
        console.error(
          `Invalid difficulty: ${unitAttemptReport.unit.challenge.difficulty}`
        );
        return Strength.UNKNOWN;
    }
  };

  const getAllEvaluationsForConceptsCovered = (
    latestAssessmentAttemptReport: AssessmentAttemptReport
  ): ConceptEvaluation[] => {
    return latestAssessmentAttemptReport.unitAttemptReports.map(
      (unitAttemptReport) => {
        const [firstTag] = unitAttemptReport.unit.tags;
        return {
          concept: firstTag,
          strength: evaluateStrength(unitAttemptReport),
          confidence: Confidence.Medium, // by default result of one unit gives medium confidence
        };
      }
    );
  };

  const unifiedEvaluations: ConceptEvaluation[] = unifyEvaluations(
    getAllEvaluationsForConceptsCovered(latestAssessmentAttemptReport)
  );

    return (
        <div className="assessment-feedback">
            <div className="evaluation-summary">
                {unifiedEvaluations && unifiedEvaluations.length > 0 ? (
                    <>
                        <h2 className='h2-evaluate'>Your Evaluation Summary:</h2>
                        <p className='p-content-feedback'> Using all the assessments you've taken so far, we've evaluated your conceptual understanding of the following concepts:</p>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'left', fontSize: '1.2em', fontWeight: 'bold', backgroundColor: 'white' }}>
                                        <Tooltip title="The concept covered in the evaluation">
                                            <span>Concept</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'left', fontSize: '1.2em', fontWeight: 'bold', backgroundColor: 'white' }}>
                                        <Tooltip title="The strength of your understanding">
                                            <span>Strength</span>
                                        </Tooltip>
                                    </TableCell >
                                    <TableCell style={{ textAlign: 'left', fontSize: '1.2em', fontWeight: 'bold', backgroundColor: 'white' }}>
                                        <Tooltip title="How confident we are in our evaluation of your strength">
                                            <span>Our Confidence</span>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {unifiedEvaluations.map(evaluation => renderEvaluation(evaluation))}
                            </TableBody>
                        </Table>
                    </>
                ) : (
                    <p>Your evaluation will be displayed here when you have taken at least one assessment.</p>
                )}
            </div>

      <KeyExplanations />
    </div>
  );
};

const AssessmentFeedback: React.FC<AssessmentFeedbackProps> = ({
  latestAssessmentAttemptReport,
}) => {
  // function that returns UserAssessmentState
  const { userId } = useContext(StateAccessor);
  const getUserAssessmentState = () => {
    // TODO NITO: make call to backend to get user's assessment state
    const userAssessmentsState: UserAssessmentState[] = [
      {
        assessmentId:
          latestAssessmentAttemptReport.assessment?.assessment_id || 0,
        assessmentName: latestAssessmentAttemptReport.assessment?.title || "",
        status: AssessmentStatus.Completed,
      },
      // {
      //     assessmentId: 1,
      //     assessmentName: "Diagnostic Assessment II",
      //     status: AssessmentStatus.InProgress
      // },
      // {
      //     assessmentId: 2,
      //     assessmentName: "Diagnostic Assessment III (Coding)",
      //     status: AssessmentStatus.NotStarted
      // },
    ];

    return userAssessmentsState;
  };

  ////////////////////////////
  // useEffects
  ////////////////////////////
  useEffect(() => {
    logToBackendLogFile(`Viewed Assessment Feedback page`, "info", userId);

    setTimeout(() => {
      alert(
        "Please note/save the result of your evaluation, as it will not be retained after you navigate away from this page."
      );
    }, 2000); // 2000 milliseconds = 2 seconds
  }, []);
  return (
    <div className="assessment-feedback-container">
      <EvaluationSummary
        latestAssessmentAttemptReport={latestAssessmentAttemptReport}
      />
      <AssessmentDashboard
        userAssessmentsState={getUserAssessmentState()}
        latestAssessmentAttemptReport={latestAssessmentAttemptReport}
      />
    </div>
  );
};

type AssessmentDashboardProps = {
  userAssessmentsState: UserAssessmentState[];
  latestAssessmentAttemptReport: AssessmentAttemptReport | null;
};

const AssessmentDashboard: React.FC<AssessmentDashboardProps> = ({
  userAssessmentsState,
  latestAssessmentAttemptReport,
}) => {
  const [isAnyAssessmentTaken, setIsAnyAssessmentTaken] = useState(false);
  const [showAssessmentResultsModal, setShowAssessmentResultsModal] =
    useState(false);
  const [
    assessmentAttemptReportToDisplay,
    setAssessmentAttemptReportToDisplay,
  ] = useState<AssessmentAttemptReport | null>(null);
  const navigate = useNavigate();

  const hideAssessmentResultsModal = () => {
    setShowAssessmentResultsModal(false);
  };

  const handleAssessmentButtonActionByStatus = (
    status: string,
    assessmentId: number
  ) => {
    if (status === AssessmentStatus.Completed) {
      handleViewResult(assessmentId);
    } else if (status === AssessmentStatus.InProgress) {
      handleContinueAssessment(assessmentId);
    } else {
      handleStartAssessment(assessmentId);
    }
  };

  const handleViewResult = useCallback((assessmentId: number) => {
    console.info(
      `setting assessmentAttemptReportToDisplay for assessmentId: ${assessmentId}`
    );
    //TODO: NITO:  should be able to set based on the assessmentId
    if (
      latestAssessmentAttemptReport?.assessment?.assessment_id === assessmentId
    ) {
      setAssessmentAttemptReportToDisplay(latestAssessmentAttemptReport);
      setShowAssessmentResultsModal(true);
    } else {
      console.error(
        "Currently, support only exists for viewing the latest assessment attempt report"
      );
    }
  }, []);

  const handleContinueAssessment = (assessmentId: number) => {
    // Dummy handler
  };

  const handleStartAssessment = (assessmentId: number) => {
    // Dummy handler
  };

  const handleViewMoreAssessments = useCallback(() => {
    const url = `${DIAGNOSTICS_PAGE_PATH}?activeComponent=${encodeURIComponent(
      LearningComponents.IdentifyKnowledgeGaps
    )}`;
    navigate(url);
  }, []);

  const handleStartLearning = useCallback(() => {
    const url = `${LEARNING_PAGE_PATH}?activeComponent=${encodeURIComponent(
      LearningComponents.LearningPath
    )}`;
    navigate(url);
  }, []);

  useEffect(() => {
    const anyTaken = userAssessmentsState.some(
      (assessment) => assessment.status !== AssessmentStatus.NotStarted
    );
    setIsAnyAssessmentTaken(anyTaken);
  }, [userAssessmentsState]);

  const getAssessmentActionButtonByCompletionStatus = (status: string) => {
    switch (status) {
      case AssessmentStatus.Completed:
        return "View Answers";
      case AssessmentStatus.InProgress:
        return "Continue";
      case AssessmentStatus.NotStarted:
        return "Start";
      default:
        return "Do nothing";
    }
  };

  const tooltipMessage =
    "You need to have completed at least one assessment to use this button.";

  return (
    <>
      <div className="assessment-dashboard">
        <Button
          color="secondary"
          className="assessment-dashboard-button"
          variant="contained"
          disabled={!isAnyAssessmentTaken}
          title={isAnyAssessmentTaken ? "" : tooltipMessage}
          onClick={handleStartLearning}
        >
          Start Learning
        </Button>

        <Button
          color="primary"
          className="assessment-dashboard-button"
          variant="contained"
          disabled={!isAnyAssessmentTaken}
          title={isAnyAssessmentTaken ? "" : tooltipMessage}
          onClick={handleViewMoreAssessments}
        >
          Try More Diagnostic Assessments
        </Button>

        {userAssessmentsState.map((assessment, idx) => (
          <div key={idx} className="assessment-item">
            {/* <span className={`status status-${assessment.status.toLowerCase()}`}>{assessment.status}</span>
                        <span className="assessment-name">{assessment.assessmentName}</span> */}
            <Button
              variant="contained"
              color="primary"
              endIcon={<InfoIcon />}
              onClick={() =>
                handleAssessmentButtonActionByStatus(
                  assessment.status,
                  assessment.assessmentId
                )
              }
            >
              {getAssessmentActionButtonByCompletionStatus(assessment.status)}{" "}
              for {assessment.assessmentName}
            </Button>
          </div>
        ))}
      </div>

            {showAssessmentResultsModal && (
                <Modal2
                    content={
                        <AssessmentUnitFeedback assessmentAttemptReport={assessmentAttemptReportToDisplay} />
                    }
                    closeDisabled={false}
                    onClose={hideAssessmentResultsModal}
                    
                />
            )
            }
        </>
    );
};

export default AssessmentFeedback;
