import React, { useState } from "react";

interface ChallengesTabbedWindowProps {
  problemDescription: string;
  problemSolutions: string;
}

const ChallengesTabbedWindow: React.FC<ChallengesTabbedWindowProps> = ({
  problemDescription,
  problemSolutions
}) => {
  const [selectedTab, setSelectedTab] = useState("problem description");

  const buttons = ["Problem Description"];//, "Solutions"];//NEBUG: REINTRODUCE for take-home assessments after interview ends

  return (
    <div className="challenges-tabbed-view-container">
      <div className="challenges-tabbed-view-header">
        {buttons.map((button, index) => (
          <button
            key={index}
            className={
              selectedTab === button.toLowerCase() ? "selected-tab" : "tab"
            }
            onClick={() => setSelectedTab(button.toLowerCase())}
          >
            {button}
          </button>
        ))}
      </div>
      <div className="challenges-tabbed-view-content">
        {selectedTab === "problem description" && <div dangerouslySetInnerHTML={{ __html: problemDescription }} />}
        {selectedTab === "solutions" && <p>{problemSolutions}</p>}
        {selectedTab === "submissions" && <p>This is the Submissions tab.</p>}
      </div>
    </div>
  );
};

export default ChallengesTabbedWindow;
