import {FC, useContext} from 'react';
import ProgressSummary_beta from './ProgressSummary_beta';
import LearningPaths_beta from './LearningPaths_beta';
import Achievements_beta from './Achievements_beta';
import SpecialAbilities_beta from './SpecialAbilities_beta';
import LeaderboardContests_beta from './LeaderboardContests_beta';
import Sidebar from '../utils/SideBar'
import '../css/Dashboard.css'
import StateAccessor from '@/StateAccessor';

const DashboardPage: React.FC<{ userName: string }> = ({ userName })=> {
  const { userId } = useContext(StateAccessor);
  return (
    <div className="flex flex-row h-fit ">
      {/* <Sidebar enableMinification={false} userId={userId}/> */}
      <div className="dashboard font-sans flex flex-col h-fit">
        {/* <ProgressSummary_beta/> */} 
        <LearningPaths_beta/>
        {/* <Achievements_beta/> */}
        {/* <SpecialAbilities_beta onPracticeClick={() => console.log('Practice button clicked')} /> */}
        {/* <LeaderboardContests_beta/> */}
      </div>
    </div>
  );
};

export default DashboardPage;