
import React, { useContext } from 'react';
import { AccessLevel, Unit } from "@/utils/Constants"
import { renderTutorialContent } from "@/utils/HelperFunction";

import '../css/TutorialContent.css';
import StateAccessor from '@/StateAccessor';
import { userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import PremiumContentAccessDeniedMessage from '@/utils/PremiumContentAccessDeniedMessage';

interface TutorialContentProps {
    unit: Unit;
}

const TutorialContent: React.FC<TutorialContentProps> = ({ unit }) => {
    const { userSubscriptionTier } = useContext(StateAccessor);

    const displayContent = (): boolean => {
        return (unit.access_level !== AccessLevel.Premium || userHasPremiumSubscriptionPrivilege(userSubscriptionTier));
    }

    return (
        displayContent() ? (
            <div className="tutorial-content">
                {unit.challenge.tutorial && renderTutorialContent(unit.challenge.tutorial)}
            </div>
        ) : (
            <PremiumContentAccessDeniedMessage />
        )

    );
}
export default TutorialContent;