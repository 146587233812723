import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import ProgressBarAnimation from './ProgressBarAnimation';
import Progress from '../utils/Progress';

interface ThinProgressBarProps {
  progress: Progress;
  contentName: string;
  disabled?: boolean;
}

const ThinProgressBar: React.FC<ThinProgressBarProps> = ({ progress, contentName, disabled = false }) => {
  const animatedProgress = ProgressBarAnimation(progress);

  if (disabled) {
    return null;
  }

  return (
    <Box
      data-tooltip-id="experience-bar-tooltip"
      data-tooltip-content={`${Math.round(animatedProgress)}%`}
      sx={{ width: '100%', backgroundColor: 'transparent', borderRadius: '4px' }}
    >
      <LinearProgress
        variant="determinate"
        value={animatedProgress}
        sx={{
          backgroundColor: '#9e9e9e',
          borderRadius: '4px',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#424242',
            borderRadius: '4px',
          },
        }}
      />
      <div style={{ textAlign: 'center', fontSize: '20px', marginTop: '5px', color: 'black', backgroundColor: 'transparent' }}>
      {`${progress.getProgressFraction()} ${contentName}`}
      </div>
      <Tooltip id="experience-bar-tooltip" />
    </Box>
  );
};

export default ThinProgressBar;
