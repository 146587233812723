import log from "loglevel";
import { Environment } from "./Constants";

const env = process.env.REACT_APP_NODE_ENV as Environment || Environment.PROD;

switch(env) {
    case Environment.DEV:
    case Environment.LOCAL_DEV:
        log.setLevel('debug');
        break;
    case Environment.UAT:
    case Environment.PROD:
        log.setLevel('info');
        break;
    default:
        log.setLevel('info'); // Default to 'info' if REACT_APP_NODE_ENV is not defined or not recognized
}

export default log;