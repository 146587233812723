import { useState } from 'react';
import { Course } from './Constants';
import { getCourses } from '../externalLayerAccessor/BackEndRequests';

export const useLazyGetCourse = () => {
    const [course, setCourse] = useState<Course|null>(null);

    const lazilyGetCourse = async (courseId: number): Promise<Course | null> => {
        try {
            if (!course) {
                const result: Course[] | null = await getCourses([courseId]);
                if (result) {
                    setCourse(result[0]);
                    return result[0];
                } else {
                    setCourse(null);
                    return null;
                }
            }
            return course;
        } catch (error) {
            console.error(`Failed to fetch course with courseId: ${courseId}:`, error);
            return null;
        }
    };
    
    
    return { lazilyGetCourse };
};
