import React, {useEffect, useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Tooltip } from 'react-tooltip';
import '../css/GameNavbar.css';
import ProgressBar from './ProgressBar';
import Progress from '../utils/Progress';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { UserSpecialAbility, SpecialAbilityName } from '../utils/Constants';
import { updateUserSpecialAbilities } from '../externalLayerAccessor/BackEndRequests';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import AirIcon from '@mui/icons-material/Air';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HistoryIcon from '@mui/icons-material/History';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import BugReportIcon from '@mui/icons-material/BugReport';
import ShieldIcon from '@mui/icons-material/Shield';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { getUserSpecialAbilities } from '../externalLayerAccessor/BackEndRequests';

const getIconForAbility = (abilityName: SpecialAbilityName) => {
  switch (abilityName) {
    case SpecialAbilityName.MentorsWhisper:
      return <VolumeDownIcon className="game-navbar-icon" />;
    case SpecialAbilityName.TimeDilation:
      return <HistoryToggleOffIcon className="game-navbar-icon" />;
    case SpecialAbilityName.SecondWind:
      return <AirIcon className="game-navbar-icon" />;
    case SpecialAbilityName.DivineInspiration:
      return <HealthAndSafetyIcon className="game-navbar-icon" />;
    case SpecialAbilityName.CognitiveBoost:
      return <PsychologyIcon className="game-navbar-icon" />;
    case SpecialAbilityName.TimeWarp:
      return <HistoryIcon className="game-navbar-icon" />;
    case SpecialAbilityName.DoubleEdge:
      return <CrisisAlertIcon className="game-navbar-icon" />;
    case SpecialAbilityName.BugScanner:
      return <BugReportIcon className="game-navbar-icon" />;
    case SpecialAbilityName.Shield:
      return <ShieldIcon className="game-navbar-icon" />;
    case SpecialAbilityName.InstantFeedback:
      return <FeedbackIcon className="game-navbar-icon" />;
    default:
      return null;
  }
};

interface GameNavbarProps {
  hearts: number;
  openSidebar: () => void;
  disabled?: boolean;
}

const GameNavbar: React.FC<GameNavbarProps> = ({
  hearts,
  openSidebar,
  disabled = false
}) => {
  
  const [specialAbilities, setSpecialAbilities] = useState<UserSpecialAbility[]>([]);

  // Add a new useEffect to fetch user special abilities when the component mounts
  useEffect(() => {
    async function fetchSpecialAbilities() {
      const userId = 123; // Send backend request to get actual user ID
      const abilities = await getUserSpecialAbilities(userId);
      if (abilities) {
        setSpecialAbilities(abilities);
      }
    }
    fetchSpecialAbilities();
  }, []); // Empty dependency array to run this effect only once when the component mounts

  // Put request
  const handleAbilityUpdate = async (abilityIndex: number) => {
    // Assuming you have an actual userId
    const userId = 123;
    
    // Create a copy of the specialAbilities array to modify it
    const updatedAbilities = [...specialAbilities];
    
    // Decrement the remaining_units of the selected ability
    updatedAbilities[abilityIndex].remaining_units -= 1;

    // Call the updateUserSpecialAbilities function
    await updateUserSpecialAbilities(userId, updatedAbilities);
    setSpecialAbilities(updatedAbilities);

  };

  if(disabled) {
    return null;
  }
  return (
    <div className="game-navbar">
      <div className="hamburger-icon-container">
        <MenuIcon className="game-navbar-icon" onMouseEnter={openSidebar} />
      </div>
      <div className='progress-bar-box'>
        <ProgressBar progress={new Progress(0, 100)}/>
      </div>
      <div className='powerups-container'>
        {specialAbilities.map((ability, index) => (
          <div 
            className={`powerup-icon-container ${ability.remaining_units === 0 ? 'disabled' : ''}`} 
            key={index} 
            data-tooltip-id="powerup-tooltip" 
            data-tooltip-content={ability.ability_description}
            onClick={() => handleAbilityUpdate(index)}
          >
            <div className="powerup-remaining">
              <div className="remaining-units">{ability.remaining_units}</div>
            </div>
            {getIconForAbility(ability.ability_name)}
            <div className="powerup-text">
              {ability.ability_name
                .toLowerCase()
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </div>
          </div>
        ))}
      </div>
      <div className="heart-container">
        {hearts > 3 ? (
          <div className='heart-icon-with-text'>
            <FavoriteIcon className="heart-icon-text"/>
            <span className='challenge-lives'>Lives: {hearts}</span>
          </div>
        ) : (
          hearts > 0 && (
            <div className='heart-icons' data-tooltip-id="lives-tooltip" data-tooltip-content={`${hearts} lives left`}>
              {Array.from({ length: hearts }, (_, index) => (
                <FavoriteIcon key={index} className="heart-icon"/>
              ))}
            </div>
          )
        )}
      </div>
      <Tooltip id="lives-tooltip" />
      <Tooltip id="powerup-tooltip" />
    </div>
  );
};

export default GameNavbar;
