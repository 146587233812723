import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/Courses.css";
import GameNavbar from "./GameNavbar";
import Modal from "../utils/Modal2";
import SubmissionResultSummary from "./SubmissionResultSummary";
import ChallengeTabbedView from "./ChallengeTabbedView";
import CodeEditorBox from "./CodeEditorBox";
// import LearningPageLinks from './LearningPageLinks';
import {
  ChallengeTestCase,
  DEFAULT_CONTENT_CONTAINER_MIN_HEIGHT_IN_PX,
  Difficulty,
  ExecutionResult,
  ExecutionStatus,
  Language,
  TestCaseExecutionStatus,
} from "../utils/Constants";
import { CourseContext } from "../utils/CoursesContext";
import {
  Course,
  Module,
  Submodule,
  Unit,
  Challenge,
  ChallengeType,
  TestCaseExecutionResponse,
  TestCaseExecutionRequest,
  AssessmentType,
  ChallengeDescriptionInterface,
} from "../utils/Constants";
import Progress from "../utils/Progress";
import MultipleChoiceBox from "./MultipleChoiceBox";
import { executeCode, logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import ChallengeInteractiveMenu from "../utils/InteractiveMenu";
import {
  convertChallengeTestCaseToCodeExecutionTestCase,
  convertLanguageToCodeExecutionLanguage,
  convertToProgrammingLanguage,
  doNothingAsync,
  mapLanguagetoCodeBlockLanguageLabel,
} from "../utils/HelperFunctions";
import StateAccessor from "../StateAccessor";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { LEARNING_PAGE_PATH } from "../utils/Constants";
import { TaskProps, TaskStatus } from "./models/Task";
import OutputWindow from "@/OutputWindow"; // @ is an alias to /src, should be defined in tsconfig
import { userHasPremiumSubscriptionPrivilege } from "@/utils/PermissionUtils";
import { ProgrammingLanguage } from "./Settings";

export enum CourseChallengeTabs {
  PROBLEM = "problem",
  EXPLANATIONS = "explanations",
  SOLUTIONS = "solutions",
  AI_ASSISTANT = "ai-assistant",
  TUTORIAL = "tutorial",
}

export enum NAVIGATION_DIRECTION {
  NEXT = "next",
  PREV = "prev",
  CURRENT = "current", // e.g. a reload
}

//START COMMENT
/* 
this means Mode is a union of the values of the object

if 
const Mode = {
  INIT_MODE: { value: 'init-mode', isTerminal: false },
  LEARNING_MODE: { value: 'learning-mode', isTerminal: false },
  // ...
} as const;


then
type `Mode` will be a union of the values of the object

{ value: 'init-mode', isTerminal: false } | { value: 'learning-mode', isTerminal: false } | ...


*/
export const ModeValues = {
  INIT_MODE: "init-mode",
  LEARNING_MODE: "learning-mode",
  TIMED_OUT_MODE: "timed-out-mode",
  OUT_OF_LIVES_MODE: "out-of-lives-mode",
  CORRECT_ANSWER_MODE: "correct-answer-mode",
  WRONG_ANSWER_MODE: "wrong-answer-mode", // not out of lives, but wrong answer
  CODE_EXECUTION_ERROR_MODE: "code-execution-error-mode",
  CODE_EXECUTION_REJECTED_INSUFFICIENT_TOKENS_MODE:
    "code-execution-rejected-insufficient-tokens-mode",
} as const;

const Mode = {
  INIT_MODE: { value: ModeValues.INIT_MODE, isTerminal: false },
  LEARNING_MODE: { value: ModeValues.LEARNING_MODE, isTerminal: true },
  TIMED_OUT_MODE: { value: ModeValues.TIMED_OUT_MODE, isTerminal: true },
  OUT_OF_LIVES_MODE: { value: ModeValues.OUT_OF_LIVES_MODE, isTerminal: true },
  CORRECT_ANSWER_MODE: {
    value: ModeValues.CORRECT_ANSWER_MODE,
    isTerminal: true,
  },
  WRONG_ANSWER_MODE: { value: ModeValues.WRONG_ANSWER_MODE, isTerminal: false },
  CODE_EXECUTION_GENERIC_ERROR_MODE: {
    value: ModeValues.CODE_EXECUTION_ERROR_MODE,
    isTerminal: false,
  },
  CODE_EXECUTION_REJECTED_INSUFFICIENT_TOKENS_MODE: {
    value: ModeValues.CODE_EXECUTION_REJECTED_INSUFFICIENT_TOKENS_MODE,
    isTerminal: false,
  },
} as const;
export type Mode = (typeof Mode)[keyof typeof Mode];

interface filterCourseData {
  course: Course | undefined;
  module: Module | undefined;
  submodule: Submodule | undefined;
  unit: Unit | undefined;
  challenge: Challenge | undefined;
}

// Helper functions

//this function is used as a temporary hack to get the initial code editor text to display properly
const setProgrammingLanguageWithDelay = (language: Language, setter: (lang: Language) => void) => {
  setter(Language.Kotlin);
  setTimeout(() => {
    // Switch to an unpopular programming language, and switch back to the original language
    setter(language);
  }, 0);
};

/**
 * Filters and maps the relevant course data based on provided IDs.
 * @param {string | undefined} course_id - Course ID
 * @param {string | undefined} module_id - Module ID
 * @param {string | undefined} submodule_id - Submodule ID
 * @param {string | undefined} unit_id - Unit ID
 * @param {string | undefined} challenge_id - Challenge ID
 * @param {Course[] | null} courses - Array of all available courses
 * @returns {{ course: Course | undefined; module: Module | undefined; submodule: Submodule | undefined; unit: Unit | undefined; challenge: Challenge | undefined; }} - Returns course, module, submodule, unit and challenge if found
 */
const filterCourseData = (
  course_id: string | undefined,
  module_id: string | undefined,
  submodule_id: string | undefined,
  unit_id: string | undefined,
  challenge_id: string | undefined,
  courses: Course[] | null
): filterCourseData => {
  //TODO: MAJOR refactor this function to be more efficient
  const parsedCourseId = course_id ? parseInt(course_id) : undefined;
  const parsedModuleId = module_id ? parseInt(module_id) : undefined;
  const parsedSubmoduleId = submodule_id ? parseInt(submodule_id) : undefined;
  const parsedUnitId = unit_id ? parseInt(unit_id) : undefined;

  const coursesMap = new Map(
    courses?.map((course) => [course.course_id, course])
  );
  const course =
    parsedCourseId !== undefined ? coursesMap.get(parsedCourseId) : undefined;

  const modulesMap = new Map(
    course?.modules.map((module) => [module.module_id, module])
  );
  const module =
    parsedModuleId !== undefined ? modulesMap.get(parsedModuleId) : undefined;

  const submodulesMap = new Map(
    module?.submodules.map((submodule) => [submodule.submodule_id, submodule])
  );
  const submodule =
    parsedSubmoduleId !== undefined
      ? submodulesMap.get(parsedSubmoduleId)
      : undefined;

  const unitsMap = new Map(
    submodule?.units.map((unit) => [unit.unit_id, unit])
  );
  const unit =
    parsedUnitId !== undefined ? unitsMap.get(parsedUnitId) : undefined;

  const challenge = unit?.challenge;

  return { course, module, submodule, unit, challenge };
};

const Courses: React.FC = () => {
  const { userId } = useContext(StateAccessor);

  const { courses } = useContext(CourseContext);
  const params = useParams();

  const course_id = params.course_id;
  const module_id = params.module_id;
  const submodule_id = params.submodule_id;
  const unit_id = params.unit_id;
  const challenge_id = params.challenge_id;

  const { course, module, submodule, unit, challenge } = filterCourseData(
    course_id,
    module_id,
    submodule_id,
    unit_id,
    challenge_id,
    courses
  );
  if (!courses) {
    return null;
  }
  if (
    !params.course_id ||
    !params.module_id ||
    !params.submodule_id ||
    !params.unit_id ||
    !params.challenge_id
  ) {
    console.error("Incorrect URL parameters");
    return null;
  }
  if (!course || !module || !submodule || !unit || !challenge) {
    console.error("Some of the JSON data could not be filtered");
    //print contents of each variable
    // console.log("course: ", course);
    // console.log("module: ", module);
    // console.log("submodule: ", submodule);
    // console.log("unit: ", unit);
    // console.log("challenge: ", challenge);

    return null;
  }

  const challengeDescriptionValues: ChallengeDescriptionInterface = {
    moduleTitle: module.title || "",
    submoduleTitle: submodule.title || "",
    unit: unit,
    challengeTitle: challenge.title || "",
    challengeType: challenge.challenge_type || ChallengeType.CODING,
    assessmentType: challenge.assessment_type || AssessmentType.TimedCoding,
    problemStatement: challenge.problem_statement || "",
    penalty: challenge.penalty || 0,
    hints: challenge.hints || [],
    rewards: challenge.rewards || [],
    difficulty: challenge.difficulty || Difficulty.Straightforward,
    keyAssociations: challenge.key_associations || [],
    totalNumChallengesInScope: submodule.units.length,
  };

  return (
    <LearningModeCourse
      // course={course}
      // module={module}
      submodule={submodule}
      unit={unit}
      challenge={challenge}
      challengeDescriptionValues={challengeDescriptionValues}
      course_id={params.course_id}
      module_id={params.module_id}
      submodule_id={params.submodule_id}
      unit_id={params.unit_id}
      userId={userId}
    />
  );
};

interface LearningModeCourseProps {
  // course: Course;
  // module: Module;
  submodule: Submodule;
  unit: Unit;
  challenge: Challenge;
  challengeDescriptionValues: ChallengeDescriptionInterface;
  course_id: string;
  module_id: string;
  submodule_id: string;
  unit_id: string;
  userId: number;
}
const LearningModeCourse: React.FC<LearningModeCourseProps> = ({
  submodule,
  unit,
  challenge,
  challengeDescriptionValues,
  course_id,
  module_id,
  submodule_id,
  unit_id,
  userId
}) => {
  const queryParameters = new URLSearchParams(window.location.search)
  const nextUrl = queryParameters.get("nextUrl");
  const prevUrl = queryParameters.get("prevUrl");
  const tasksIndexQueryParam = queryParameters.get("tasksIndex");
  const tasksIndex = tasksIndexQueryParam !== null ? parseInt(tasksIndexQueryParam) : null;
  const [mode, setMode] = useState<Mode>(Mode.INIT_MODE); // don't use setMode unless you 100% know what you're doing, use handleModeStateChange instead!
  const [preferredProgrammingLanguage, setPreferredProgrammingLanguage] =
    useState<Language>(Language.Python);
  const [submissionExecutionResults, setSubmissionExecutionResults] = useState<TestCaseExecutionResponse | null>(null);
  const {userSubscriptionTier} = useContext(StateAccessor);

  // cod editor value
  const getInitialCodeEditorValue = () => {
    if (challenge.challenge_type === ChallengeType.CODING) {
      const codeStub = challenge.code_stub_by_language
        ? challenge.code_stub_by_language[
        mapLanguagetoCodeBlockLanguageLabel(preferredProgrammingLanguage)
        ]
        : "";
      return codeStub;
    } else if (
      challenge.challenge_type === ChallengeType.Algorizing &&
      challenge.assessment_type === AssessmentType.EfficientAlgorithm
    ) {
      return "# Sketch out your algorithm here using pseudocode \n# Note the objective is not to code up the solution,\n\t# but to come up with an efficient algorithm.";
    }
    else if (challenge.challenge_type === ChallengeType.LOW_LEVEL_DESIGN) {
      return "# You can lay out your interface and write code here";
    }
    else if (challenge.challenge_type === ChallengeType.SYSTEM_DESIGN) {
      return "# Take notes here. Use tools like Excalidraw, draw.io, or Lucidchart for system design sketches in another tab, window or monitor";
    }
    else {
      return "";
    }
  };
  const [codeEditorValue, setCodeEditorValue] = useState<string>(
    getInitialCodeEditorValue()
  );

  ///////////////////////////////
  // Hide or Display UI elements
  ///////////////////////////////
  // const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showTerminalStateOrSubmissionResultMsg, setShowTerminalStateorSubmissionResultMsg] = useState<boolean>(false);
  const [showMCQOptions, setShowMCQOptions] = useState<boolean>(false);
  const [showIDontKnowInteractiveButton, setShowIDontKnowInteractiveButton] = useState<boolean>(true);
  const [showSolutionView, setShowSolutionView] = useState<boolean>(false);
  
  const [firstChoiceMade, setFirstChoiceMade] = useState<boolean>(false); // needs a rename, refers to first choice made in MCQ
  const [testCaseProgress, setTestCaseProgress] = useState<Progress>(new Progress(0, 1));
  const [isUsersAnswerCorrect, setIsUsersAnswerCorrect] = useState<boolean | null | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>(
    CourseChallengeTabs.PROBLEM
  );
  const [hearts, setHearts] = useState<number>(1);
  const [timerKey, setTimerKey] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true);
  const [isTimerDisabled, setIsTimerDisabled] = useState<boolean>(false);
  const [isSubmissionDisabled, setIsSubmissionDisabled] =
    useState<boolean>(false);
  const [executionResult, setExecutionResult] = useState<ExecutionResult | null>(null);


  const navigate = useNavigate();
  // User failed the challenge - subtract XP points
  const handleOutOfHearts = () => {
    handleModeStateChange(Mode.OUT_OF_LIVES_MODE);
  };

  // User passed the challenge - give them rewards and update progress
  const handleCorrectAnswerSubmission = () => {
    //TODO: give rewards and xp, if first attempt, no rewards for retries?
    handleModeStateChange(Mode.CORRECT_ANSWER_MODE);
  };

  const handleIncorrectAnswerSubmission = () => {
    setHearts((prevHearts) => Math.max(0, prevHearts - 1));
    handleModeStateChange(Mode.WRONG_ANSWER_MODE);
  };

  const handleIDontKnow = () => {
    handleModeStateChange(Mode.LEARNING_MODE);
    setShowIDontKnowInteractiveButton(false);
    setFirstChoiceMade(true);
    handleShowMCQOptions(true);
    window.scrollTo(0, 0); // scroll to top of page
  };

  const handleRevealSolution = () => {
    handleModeStateChange(Mode.LEARNING_MODE);
    window.scrollTo(0, 0); // scroll to top of page
  };

  const activateLearningMode = () => {
    setShowSolutionView(true);
    handleToggleActiveTab(CourseChallengeTabs.EXPLANATIONS);
  };

  ///////////////////////////////
  // handling mode state changes
  ///////////////////////////////

  const handleModeStateChange = (newMode: Mode) => {
    // if state change is from terminal to terminal, do nothing
    if (mode.isTerminal && newMode.isTerminal) {
      console.warn(
        `Mode state change from ${mode.value} to ${newMode.value} is not allowed. Cannot change from terminal state to terminal state.`
      );
      return;
    }
    setMode(newMode);
  };

  useEffect(() => {
    // track user activity
    logToBackendLogFile(
      `Navigated to courseId ${course_id} module ${module_id} submodule ${submodule_id} unit ${unit.unit_id} challenge ${challenge.challenge_id}`,
      "info",
      userId
    );

    // temporary fix to ensure code stub is loaded properly
    const queryParams = new URLSearchParams(window.location.search);
    const direction = queryParams.get("direction");

    if (
      direction === NAVIGATION_DIRECTION.NEXT ||
      direction === NAVIGATION_DIRECTION.PREV
    ) {
      // Reload page and trigger remounting, so that the code stub is loaded properly
      // NOTE: this is a short-term fix for a time-consuming to debug issue with the code stub
      // Set the direction parameter to CURRENT
      queryParams.set("direction", NAVIGATION_DIRECTION.CURRENT);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState({}, "", newUrl); // this will update the direction parameter in the URL

      // Reload the page
      window.location.reload(); // this is a full page reload
    } else if (
      challenge.assessment_type === AssessmentType.SKIP_DUMMY_CHALLENGE
    ) {
      if (direction === NAVIGATION_DIRECTION.NEXT) {
        handleUnitAdvance();
      } else if (direction === NAVIGATION_DIRECTION.PREV) {
        handleNavigateToPreviousUnit();
      }
    }
  }, [unit_id]);

  useEffect(() => {
    // stop timer
    if (mode.isTerminal) {
      setIsTimerRunning(false);
      setIsTimerDisabled(true);
      setIsSubmissionDisabled(true);
      setShowIDontKnowInteractiveButton(false);
    }

    //activate appropriate mode where necessary
    if (mode === Mode.LEARNING_MODE) {
      activateLearningMode();
    }

    // otherwise do nothing
  }, [mode]);

  useEffect(() => {
    if (isUsersAnswerCorrect === true) {
      handleCorrectAnswerSubmission();
    } else if (isUsersAnswerCorrect === false) {
      handleIncorrectAnswerSubmission();
    }
  }, [isUsersAnswerCorrect]);

  useEffect(() => {
    if (challenge) {
      setHearts(challenge.max_attempts);
    }
  }, [challenge]);

  useEffect(() => {
    if (hearts === 0) {
      // out of lives
      handleOutOfHearts();
    }
  }, [hearts]);

  const handleToggleActiveTab = (tab: CourseChallengeTabs) => {
    switch (tab) {
      case CourseChallengeTabs.PROBLEM:
        setActiveTab(CourseChallengeTabs.PROBLEM);
        break;
      case CourseChallengeTabs.EXPLANATIONS:
        setActiveTab(CourseChallengeTabs.EXPLANATIONS);
        break;
      case CourseChallengeTabs.SOLUTIONS:
        setActiveTab(CourseChallengeTabs.SOLUTIONS);
        break;
      case CourseChallengeTabs.AI_ASSISTANT:
        setActiveTab(CourseChallengeTabs.AI_ASSISTANT);
        break;
      case CourseChallengeTabs.TUTORIAL:
        setActiveTab(CourseChallengeTabs.TUTORIAL);
        break;
      default:
        setActiveTab(CourseChallengeTabs.PROBLEM);
        break;
    }
  };

  const handleToggleSidebar = (state: boolean) => {
    // setShowSidebar(state);
  };

  const handleCloseResultsModal = () => {
    setShowTerminalStateorSubmissionResultMsg(false);
    setIsUsersAnswerCorrect(undefined); //reset
  };
  const handleShowMCQOptions = (showMCQOtions: boolean) => {
    //NITO-TODO: move to mcq
    if (!firstChoiceMade) {
      setFirstChoiceMade(true);
      setShowMCQOptions(showMCQOtions);
    }
  };

  const handleTimerComplete = () => {
    handleModeStateChange(Mode.TIMED_OUT_MODE);
    setShowTerminalStateorSubmissionResultMsg(true);
  };

  ///////////////////////////////
  // NAVIGATION
  ///////////////////////////////
  const handleUnitAdvance = () => {
    if (nextUrl) {
      // TODO: remove these local storage updates as status updates will be done via backend calls and 
      // clicking next shouldn't trigger status updates except maybe a new status called Viewed. 
      // In Progress will be used when user has actually made an attempt at the task 
      //e.g. submission attempt for code
      if (tasksIndex !== null) {
        const tasksStorage = localStorage.getItem('tasks');
        if (tasksStorage) {
          let updatedTasks = JSON.parse(tasksStorage) as TaskProps[];
          if (tasksIndex < updatedTasks.length) {
            updatedTasks[tasksIndex].status = TaskStatus.Completed;
            localStorage.setItem('tasks', JSON.stringify(updatedTasks));
          } else {
            console.warn('tasksIndex is out of range of the tasks array');
          }
        } else {
          console.warn('No tasks data found in local storage');
        }
      }

      activateInitState();
      navigate(nextUrl);
      window.scrollTo(0, 0);
    } else {
      const submoduleUnitsLength = submodule.units.length;
      const currentUnitIndex = submodule.units.findIndex(
        (unit) => unit.unit_id === (unit_id ? parseInt(unit_id) : undefined)
      );
      const thisCoursesPageUrl = `${LEARNING_PAGE_PATH}/courses/${course_id}`;

      if (currentUnitIndex < submoduleUnitsLength - 1) {
        // if not last unit in submodule
        let potentialNextUnitIndex = currentUnitIndex + 1;
        const nextUnit = submodule.units[potentialNextUnitIndex];
        const nextUnitId = nextUnit.unit_id;
        const nextChallengeId = nextUnit.challenge.challenge_id;

        activateInitState();
        navigate(
          `${thisCoursesPageUrl}/modules/${module_id}/submodules/${submodule_id}/units/${nextUnitId}/challenge/${nextChallengeId}?direction=${NAVIGATION_DIRECTION.NEXT}`
        );

        window.scrollTo(0, 0);
      } else {
        navigate(thisCoursesPageUrl);// TODO: restore navigate(JOURNEY_PAGE_PATH); or to dashboard
      }
    }
  };

  const navigateAndInitState = (urlPath: string) => {
    activateInitState();
    navigate(urlPath);
  };

  const handleNavigateToPreviousUnit = () => {
    const currentUnitIndex = submodule.units.findIndex(
      (unit) => unit.unit_id === (unit_id ? parseInt(unit_id) : undefined)
    );

    if (currentUnitIndex > 0) {
      let potentialPreviousUnitIndex = currentUnitIndex - 1;
      const previousUnit = submodule.units[potentialPreviousUnitIndex];
      const previousUnitId = previousUnit.unit_id;
      const previousChallengeId = previousUnit.challenge.challenge_id;

      if (prevUrl) {
        navigateAndInitState(prevUrl);
      } else {
        navigateAndInitState(
          `${LEARNING_PAGE_PATH}/courses/${course_id}/modules/${module_id}/submodules/${submodule_id}/units/${previousUnitId}/challenge/${previousChallengeId}?direction=${NAVIGATION_DIRECTION.PREV}`
        );
      }
      window.scrollTo(0, 0);
    } else {
      if (prevUrl && prevUrl !== "") {
        navigateAndInitState(prevUrl);
      } else {
        navigateAndInitState(
          `${LEARNING_PAGE_PATH}/courses/${course_id}/#module-${module_id}-submodule-${submodule_id}`
        );
      }
    }
  };

  /*
   * selectedOption is the answer the user selected
   */
  const handleMCQSubmission = (selectedOption: string | null) => {
    if (selectedOption) {
      const isAnswerCorrect: boolean =
        challenge.acceptable_answers.includes(selectedOption);
      setIsUsersAnswerCorrect(isAnswerCorrect);
      setShowTerminalStateorSubmissionResultMsg(true);
    }
  };

  const handleCodeExecution = async (code: string, language: Language) => {
    // trigger actual code execution
    try {

      // create dummy test case to satisfy interface, we don't actually need test cases since this isn't a submission
      const dummy_test_cases: ChallengeTestCase[] = [{
        testcase_id: 0,
        input: ".",
        expected_output: "."

      }];
      const codeExecutionResult: TestCaseExecutionResponse = await evaluateCodeExecutionThrows(code, language, dummy_test_cases);

      // actual output will be obtained from execution result of our dummy test case
      const theExecutionResult: ExecutionResult | null = codeExecutionResult.results.testCases.length == 1 ? codeExecutionResult.results.testCases[0] : null;
      setExecutionResult(theExecutionResult);
    } catch (error) {
      console.error(String(error));
      setExecutionResult(null);
    }
  };

  const handleCodeSubmission = async (code: string, language: Language) => {
    try {
      const [theTestCaseProgress, codeExecutionResult]: [Progress, TestCaseExecutionResponse] =
        await evaluateCodeSubmissionThrows(code, language);
      if (
        codeExecutionResult.executionStatus ==
        ExecutionStatus.Rejected_Insufficient_Tokens
      ) {
        handleModeStateChange(
          Mode.CODE_EXECUTION_REJECTED_INSUFFICIENT_TOKENS_MODE
        );
      } else {
        setTestCaseProgress(theTestCaseProgress);
        setIsUsersAnswerCorrect(codeExecutionResult.results.allTestCasesPassed);
        setSubmissionExecutionResults(codeExecutionResult);
      }
    } catch (error) {
      console.error(String(error));
      handleModeStateChange(Mode.CODE_EXECUTION_GENERIC_ERROR_MODE);
    }
    setShowTerminalStateorSubmissionResultMsg(true);
  };


  const evaluateCodeExecutionThrows = async (
    code: string,
    language: Language,
    test_cases: ChallengeTestCase[] = [] // Optional but with Default parameter value set to empty array
  ): Promise<TestCaseExecutionResponse> => {

    const testCodeParams: TestCaseExecutionRequest = {
      user_id: userId,
      source_code: code,
      language: convertLanguageToCodeExecutionLanguage(language),
      test_cases: convertChallengeTestCaseToCodeExecutionTestCase(
        test_cases
      ),
    };

    const codeExecutionResult: TestCaseExecutionResponse | null = await executeCode(testCodeParams);

    if (codeExecutionResult !== null) {
      return codeExecutionResult;
    }
    else {
      throw new Error("Error occurred during code execution");
    }

  }


  // Runs when 'submit' is clicked - check coding type questions
  const evaluateCodeSubmissionThrows = async (
    code: string,
    language: Language
  ): Promise<[Progress, TestCaseExecutionResponse]> => {
    if (!challenge.test_cases) {
      throw new Error("No test cases found.");
    }

    const testCodeParams: TestCaseExecutionRequest = {
      user_id: userId,
      source_code: code,
      language: convertLanguageToCodeExecutionLanguage(language),
      test_cases: convertChallengeTestCaseToCodeExecutionTestCase(
        challenge.test_cases
      ),
    };

    const codeExecutionResult: TestCaseExecutionResponse | null =
      await executeCode(testCodeParams);
    if (codeExecutionResult === null) {
      throw new Error("Error occurred during code execution");
    }

    const allTestCasesPassed = codeExecutionResult.results.allTestCasesPassed;

    const numTestCasesPassed = allTestCasesPassed
      ? challenge.test_cases.length
      : codeExecutionResult.results.testCases.reduce(
        (count, testCaseResult) =>
          testCaseResult.test_case_status === TestCaseExecutionStatus.ACCEPTED
            ? count + 1
            : count,
        0
      );

    const testCaseProgress = new Progress(
      numTestCasesPassed,
      challenge.test_cases.length
    );
    return [testCaseProgress, codeExecutionResult];
  };

  const handleTryAgain = () => {
    activateInitState();
    // Refresh the page by navigating to the current URL
    // navigate(window.location.pathname);
  };

  const activateInitState = () => {
    handleModeStateChange(Mode.INIT_MODE);

    setShowIDontKnowInteractiveButton(true);
    setIsSubmissionDisabled(false);
    setFirstChoiceMade(false);
    setShowMCQOptions(false);
    setActiveTab(CourseChallengeTabs.PROBLEM);
    setIsUsersAnswerCorrect(undefined);
    setTestCaseProgress(new Progress(0, 1));
    setHearts(challenge.max_attempts);
    setShowTerminalStateorSubmissionResultMsg(false);

    // enable timer
    setTimerKey((prevKey) => prevKey + 1);
    setIsTimerRunning(true);
    setIsTimerDisabled(false);
  };

  return (
    <div className="courses-container">
      {/* <Sidebar enableMinification={true}  userId={userId}/> */}
      <GameNavbar
        hearts={hearts}
        openSidebar={() => handleToggleSidebar(true)}
      />
      <ChallengeInteractiveMenu
        challenge={challenge}
        handlePrevious={handleNavigateToPreviousUnit}
        handleNext={handleUnitAdvance}
        handleIDontKnow={handleIDontKnow}
        showIDontKnow={showIDontKnowInteractiveButton}
        handleRevealSolution={handleRevealSolution}
        showTryAgain={mode.isTerminal}
        handleTryAgain={handleTryAgain}
      />
      <br></br>
      <div className="boxes-container">
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel minSize={10}>
            <div
              className="content-grid-element">
              <ChallengeTabbedView
                unit={unit}
                challenge={challenge}
                acceptableAnswers={challenge.acceptable_answers}
                challengeDescriptionValues={challengeDescriptionValues}
                solutionByLanguage={challenge.solution_by_language || null}
                showSolutionView={showSolutionView}
                challengeTimeLimit={challenge.time_limit_in_minutes}
                activeTab={activeTab}
                handleToggleActiveTab={handleToggleActiveTab}
                currentChallengeRank={unit.unit_id + 1}
                candidateCode={codeEditorValue}
                programmingLanguage={convertToProgrammingLanguage(
                  preferredProgrammingLanguage
                )}
              />
            </div>
          </ResizablePanel>
          <ResizableHandle orientation="vertical" />
          <ResizablePanel minSize={10}>
            <ResizablePanelGroup direction="vertical">
              <ResizablePanel minSize={10}>
                <div
                  className="user-response-grid-element"
                  style={{
                    height: '100%',
                    width: '100%',
                    minHeight: DEFAULT_CONTENT_CONTAINER_MIN_HEIGHT_IN_PX,
                  }}
                >
                  {challenge.challenge_type === ChallengeType.CODING && (
                    <CodeEditorBox
                      userId={userId}
                      codeEditorValue={codeEditorValue}
                      setCodeEditorValue={setCodeEditorValue}
                      codeBlockByLanguage={challenge.code_stub_by_language}
                      timerComplete={handleTimerComplete}
                      unit_id={unit.unit_id}
                      codeSubmissionHandler={handleCodeSubmission}
                      codeRunHandler={handleCodeExecution}
                      rightAnswer={isUsersAnswerCorrect}
                      timerKey={timerKey}
                      codeEditorSettings={{
                        isRunningDisabled: preferredProgrammingLanguage !== Language.Python || !userHasPremiumSubscriptionPrivilege(userSubscriptionTier),
                        isSubmissionDisabled: true || !userHasPremiumSubscriptionPrivilege(userSubscriptionTier) || isSubmissionDisabled,
                        disabled: false,
                        programmingLanguage: preferredProgrammingLanguage,
                        isAssessment: false,
                        timeLimitInMinutes: challenge.time_limit_in_minutes,
                        isTimerDisabled: isTimerDisabled,
                        isTimerRunning: isTimerRunning,
                        setProgrammingLanguage:
                          setPreferredProgrammingLanguage,
                        setIsTimerRunning: setIsTimerRunning,
                        programmingLanguageWhiteList: null,
                      }}
                    />
                  )}
                  {challenge.challenge_type === ChallengeType.TUTORIAL && (
                    <CodeEditorBox
                      userId={userId}
                      codeEditorValue={codeEditorValue}
                      setCodeEditorValue={setCodeEditorValue}
                      codeBlockByLanguage={challenge.code_stub_by_language}
                      timerComplete={handleTimerComplete}
                      unit_id={unit.unit_id}
                      codeSubmissionHandler={doNothingAsync}
                      codeRunHandler={handleCodeExecution}
                      rightAnswer={isUsersAnswerCorrect}
                      timerKey={timerKey}
                      codeEditorSettings={{
                        isRunningDisabled: true,
                        disabled: false,
                        isSubmissionDisabled: true, // nothing to submit for tutorial
                        programmingLanguage: preferredProgrammingLanguage,
                        isAssessment: false,
                        timeLimitInMinutes: challenge.time_limit_in_minutes,
                        isTimerDisabled: isTimerDisabled,
                        isTimerRunning: isTimerRunning,
                        setProgrammingLanguage:
                          setPreferredProgrammingLanguage,
                        setIsTimerRunning: setIsTimerRunning,
                        programmingLanguageWhiteList: new Set(
                          Object.values(Language)
                        ),
                      }}
                    />
                  )}
                  {challenge.challenge_type === ChallengeType.LOW_LEVEL_DESIGN && (
                    <CodeEditorBox
                      userId={userId}
                      codeEditorValue={codeEditorValue}
                      setCodeEditorValue={setCodeEditorValue}
                      codeBlockByLanguage={challenge.code_stub_by_language}
                      timerComplete={handleTimerComplete}
                      unit_id={unit.unit_id}
                      codeSubmissionHandler={doNothingAsync}
                      codeRunHandler={handleCodeExecution}
                      rightAnswer={isUsersAnswerCorrect}
                      timerKey={timerKey}
                      onLoad={
                        () => {
                          setProgrammingLanguageWithDelay(preferredProgrammingLanguage, setPreferredProgrammingLanguage);
                        }
                      }
                      codeEditorSettings={{
                        isRunningDisabled: preferredProgrammingLanguage !== Language.Python || !userHasPremiumSubscriptionPrivilege(userSubscriptionTier),
                        disabled: false,
                        isSubmissionDisabled: true, //nothing to submit for low level design
                        programmingLanguage: preferredProgrammingLanguage,
                        isAssessment: false,
                        timeLimitInMinutes: challenge.time_limit_in_minutes,
                        isTimerDisabled: isTimerDisabled,
                        isTimerRunning: isTimerRunning,
                        setProgrammingLanguage:
                          setPreferredProgrammingLanguage,
                        setIsTimerRunning: setIsTimerRunning,
                        programmingLanguageWhiteList: new Set(
                          Object.values(Language)
                        ),
                      }}
                    />
                  )}
                  {challenge.challenge_type === ChallengeType.SYSTEM_DESIGN && (
                    <iframe src="https://excalidraw.com" width="100%" height="100%" title="Excalidraw"></iframe>
                  )}
                  {challenge.challenge_type === ChallengeType.KNOWLEDGE && (
                    <MultipleChoiceBox
                      isSubmissionDisabled={isSubmissionDisabled}
                      isTimerDisabled={isTimerDisabled}
                      isTimerRunning={isTimerRunning}
                      setIsTimerRunning={setIsTimerRunning}
                      timeLimitInMinutes={challenge.time_limit_in_minutes}
                      timerComplete={handleTimerComplete}
                      unit_id={unit.unit_id}
                      options={challenge.assessment_options}
                      showMCQOPtions={showMCQOptions}
                      handleShowMCQOptions={(showMCQOptions: boolean) =>
                        handleShowMCQOptions(showMCQOptions)
                      }
                      firstChoiceMade={firstChoiceMade}
                      handleMCQSubmission={handleMCQSubmission}
                      rightAnswer={isUsersAnswerCorrect}
                      timerKey={timerKey}
                      handleUserDeclaresNotKnowingAnswer={handleIDontKnow}
                    />
                  )}
                  {challenge.challenge_type === ChallengeType.Algorizing && (
                    <CodeEditorBox
                      userId={userId}
                      codeEditorValue={codeEditorValue}
                      setCodeEditorValue={setCodeEditorValue}
                      codeBlockByLanguage={challenge.code_stub_by_language}
                      timerComplete={handleTimerComplete}
                      unit_id={unit.unit_id}
                      codeSubmissionHandler={doNothingAsync}
                      codeRunHandler={doNothingAsync}
                      rightAnswer={null}
                      timerKey={timerKey}
                      codeEditorSettings={{
                        isRunningDisabled: true, //!userHasPremiumSubscriptionPrivilege(userSubscriptionTier),
                        disabled: false,
                        isSubmissionDisabled: true, // no submission for algorizing since objective is not to generate working code
                        programmingLanguage: preferredProgrammingLanguage,
                        isAssessment: false,
                        timeLimitInMinutes: challenge.time_limit_in_minutes,
                        isTimerDisabled: isTimerDisabled,
                        isTimerRunning: isTimerRunning,
                        setProgrammingLanguage:
                          setPreferredProgrammingLanguage,
                        setIsTimerRunning: setIsTimerRunning,
                        programmingLanguageWhiteList: new Set(
                          Object.values(Language)
                        ),
                      }}
                    />
                  )}
                </div>
              </ResizablePanel>
              {(challenge.challenge_type === ChallengeType.CODING || challenge.challenge_type === ChallengeType.LOW_LEVEL_DESIGN) && (
                <>
                  <ResizableHandle orientation="horizontal" />
                  <ResizablePanel minSize={10}>
                    <div className="output-box-component">
                    <OutputWindow codeExecutionResult={executionResult} />
                    </div>
                  </ResizablePanel>
                </>
              )}
            </ResizablePanelGroup>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
      {showTerminalStateOrSubmissionResultMsg && (
        <Modal
          content={
            <SubmissionResultSummary
              mode={mode}
              submissionExecutionResults={submissionExecutionResults}
              handleUnitAdvance={handleUnitAdvance}
              handleTryAgain={handleTryAgain}
              challenge_title={challenge.title}
              close={handleCloseResultsModal}
              passedTestCases={testCaseProgress}
              rightAnswer={isUsersAnswerCorrect}
              challengeType={challenge.challenge_type}
            />
          }
          onClose={handleCloseResultsModal}
        />
      )}
    </div>
  );
};

export default Courses;