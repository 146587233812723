import React, { FC, useMemo } from 'react';
import Progress from '../utils/Progress';
import useProgressBarAnimation from './ProgressBarAnimation';
import "../css/ProgressBar.css"

interface ProgressBarProps {
  progress: Progress;
  disabled?: boolean;
}

/**
 * ProgressBar component
 * It takes a Progress object and an optional disabled flag.
 * It uses the useProgressBarAnimation hook to animate the progress bar.
 * @param {Progress} progress - Instance of Progress.
 * @param {boolean} disabled - Optional flag to disable the progress bar.
 * @returns {ReactElement} - The rendered progress bar.
 */
const ProgressBar: FC<ProgressBarProps> = ({ progress, disabled = false }) => {
  const animatedProgress = useProgressBarAnimation(progress);

  const progressBarFillerStyle = useMemo(() => {
    return { width: `${animatedProgress}%` };
  }, [animatedProgress]);

  if (disabled) {
    return null;
  }

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-filler" style={progressBarFillerStyle}>
        {animatedProgress > 0 ? (
            <div className="progress-bar-label">{`${Math.round(animatedProgress)}%`}</div>
          ) : null}
      </div>
    </div>
  );
};

ProgressBar.defaultProps = {
  disabled: false,
};

export default React.memo(ProgressBar);