import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  Button,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
} from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "../css/LearningPathGenerator.css";
import { ConceptEvaluation, Confidence, Strength } from "./AssessmentFeedback";
import {
  LEARNING_PAGE_PATH,
  LEARNING_PATH_PATH,
  LOCAL_STORAGE_LEARNING_PATH,
  LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT,
  UnitConceptTags,
} from "../utils/Constants";
import { useNavigate } from "react-router-dom";
import { logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import { LearningComponents } from "./GenericLearningHome";
import StateAccessor from "../StateAccessor";
import Sidebar from "../utils/SideBar";

export const LearningPathGenerator: React.FC = ({}) => {
  const [bulkStrength, setBulkStrength] = useState<Strength | null>(null);
  const [bulkConfidence, setBulkConfidence] = useState<Confidence | null>(null);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userId } = useContext(StateAccessor);
  // checkbox
  const toggleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedIndexes([]);
    } else {
      setSelectedIndexes(concepts.map((_, index) => index));
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const initialConcepts: ConceptEvaluation[] = Object.values(
    UnitConceptTags
  ).map((tag) => ({
    concept: tag,
    strength: Strength.UNKNOWN,
    confidence: Confidence.Medium,
  }));

  const [concepts, setConcepts] =
    useState<ConceptEvaluation[]>(initialConcepts);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  useEffect(() => {
    logToBackendLogFile(`Viewed Learning Path Generator page`, "info", userId);

    // handle learning path already exists
    if (localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH)) {
      const userConfirmed = window.confirm(
        "Are you sure you want to regenerate your learning path?"
      );

      if (userConfirmed) {
        localStorage.removeItem(LOCAL_STORAGE_LEARNING_PATH);
      } else {
        navigate(LEARNING_PATH_PATH);
      }
    }
    const storedConcepts = localStorage.getItem(
      LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT
    );
    if (storedConcepts) {
      setConcepts(JSON.parse(storedConcepts));
    }
  }, []);

  const handleStrengthChange = (index: number, newValue: Strength) => {
    const newConcepts = [...concepts];
    newConcepts[index].strength = newValue;
    setConcepts(newConcepts);
  };

  const handleConfidenceChange = (index: number, newValue: Confidence) => {
    const newConcepts = [...concepts];
    newConcepts[index].confidence = newValue;
    setConcepts(newConcepts);
  };

  const [showTable, setShowTable] = useState(false);

  const setBeginnerDefaults = () => {
    // Set all concepts' strength to LOW and confidence to HIGH for beginners
    const beginnerConcepts = concepts.map((concept) => ({
      ...concept,
      strength: Strength.Weak,
      confidence: Confidence.High,
    }));
    setConcepts(beginnerConcepts);
    setShowTable(true);
  };

  const setExperiencedDefaults = () => {
    // Set all concepts' strength to MODERATE and confidence to MEDIUM for experienced users
    const experiencedConcepts = concepts.map((concept) => ({
      ...concept,
      strength: Strength.Moderate,
      confidence: Confidence.Medium,
    }));
    setConcepts(experiencedConcepts);
    setShowTable(true);
  };

  const handleGenerateLearningPath = () => {
    // Save to localStorage here before navigating
    localStorage.setItem(
      LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT,
      JSON.stringify(concepts)
    ); // synchronous, so only returns when the data is saved

    //there's a "happens-before" relationship between saving to localStorage and navigating to the learning path.
    navigate(LEARNING_PATH_PATH);
  };

  /*
   * USE EFFECTS
   */
  useEffect(() => {
    if (selectedIndexes.length === concepts.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedIndexes, concepts]);

  return (
    <>
      {/* <Sidebar enableMinification={true} userId={userId}/> */}
    <div className="learning-path-generator">
      <Typography variant="h5" align="center" gutterBottom>
        Quick Self-Assessment
      </Typography>

      <div className="guidance">
        <FlashOnIcon style={{ marginRight: "10px", color: "black" }} />
        <Typography variant="body2">
          This will take a few mins, it's super quick! Assessing your current
          knowledge will help us create a custom learning path tailored to your
          unique needs.
        </Typography>
      </div>

      <div className="guidance">
        <HelpOutlineIcon style={{ marginRight: "10px", color: "black" }} />
        <Typography variant="body2">
          If you prefer, you can try our diagnostic tests to discover your
          knowledge gaps, then return here to adjust your ratings.
        </Typography>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          navigate(
            `${LEARNING_PAGE_PATH}?activeComponent=${encodeURIComponent(
              LearningComponents.IdentifyKnowledgeGaps
            )}`
          )
        }
      >
        Take Diagnostic Tests (Optional)
      </Button>

      <div className="guidance">
        <LightbulbIcon style={{ marginRight: "10px", color: "#70B0CE" }} />
        <Typography variant="body2">
          Assess each concept/topic based on your current knowledge and
          confidence. The strength indicates how well you know the concept,
          while confidence indicates how sure you are about your knowledge.
        </Typography>
      </div>

      <div className="guidance">
        <LightbulbIcon style={{ marginRight: "10px", color: "#70B0CE" }} />
        <Typography variant="body2">
          Selecting one of the below quick-actions will preset the strength and
          confidence ratings, you can then override ratings for any concept
        </Typography>
      </div>

      <div className="quick-actions">
        <Button
          variant="contained"
          color="primary"
          onClick={setBeginnerDefaults}
          style={{ 
            backgroundColor: "#70B0CE",
            textTransform: "none",
          }}
        >
        👶 I'm a beginner
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={setExperiencedDefaults}
          style={{ 
            textTransform: "none",
          }}
        >
        🥷 I'm experienced
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ 
            backgroundColor: "rgb(51, 162, 100)",
            textTransform: "none",
          }}
          onClick={() => setShowTable(true)}
        >
          Set Manually
        </Button>
      </div>

      {showTable && (
        <>
          <div className="guidance">
            <LightbulbIcon style={{ marginRight: "10px", color: "#70B0CE" }} />
            <Typography variant="body2">
              To perform bulk actions, select one or more concepts. Use the topmost checkbox to select all concepts.
            </Typography>
          </div>
          <div className="bulk-actions-row">
            <div className="bulk-actions">
              <Typography variant="body1">Bulk Actions:</Typography>

              <FormControl
                variant="outlined"
                size="medium"
                style={{ minWidth: "110px" }}
              >
                <InputLabel>Strength</InputLabel>
                <Select
                  value={bulkStrength || ""}
                  onChange={(e) =>
                    setBulkStrength(e.target.value as unknown as Strength)
                  }
                >
                  {Object.keys(Strength)
                    .filter((key) => isNaN(Number(key))) // This will filter out numeric keys
                    .map((key) => (
                      <MenuItem
                        key={key}
                        value={Strength[key as keyof typeof Strength]}
                      >
                        {key}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              
              <FormControl
                variant="outlined"
                size="medium"
                style={{ minWidth: "135px" }}
              >
                <InputLabel>Confidence</InputLabel>
                <Select
                  value={bulkConfidence || ""}
                  onChange={(e) =>
                    setBulkConfidence(e.target.value as unknown as Confidence)
                  }
                >
                  {Object.keys(Confidence)
                    .filter((key) => isNaN(Number(key))) // Filter out numeric keys
                    .map((key) => (
                      <MenuItem
                        key={key}
                        value={Confidence[key as keyof typeof Confidence]}
                      >
                        {key}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const newConcepts = [...concepts];
                  selectedIndexes.forEach((index) => {
                    if (bulkStrength) newConcepts[index].strength = bulkStrength;
                    if (bulkConfidence)
                      newConcepts[index].confidence = bulkConfidence;
                  });
                  setConcepts(newConcepts);
                  // Reset the bulk action dropdowns
                  setBulkStrength(null);
                  setBulkConfidence(null);
                }}
              >
                Apply
              </Button>
            </div>
          </div>

          <div className="concept-table">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectAllChecked}
                      onChange={toggleSelectAll}
                      size="medium"
                      title="Toggle select all items"
                      aria-label="Toggle select all items"
                    />{" "}
                    (all)
                  </TableCell>
                  <TableCell>Concept</TableCell>
                  <TableCell>Strength</TableCell>
                  <TableCell>Confidence</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {concepts.map((concept, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIndexes.includes(index)}
                        onChange={() => {
                          if (selectedIndexes.includes(index)) {
                            setSelectedIndexes((prev) =>
                              prev.filter((i) => i !== index)
                            );
                          } else {
                            setSelectedIndexes((prev) => [...prev, index]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{concept.concept}</TableCell>
                    <TableCell>
                      <FormControl variant="outlined" size="medium">
                        <Select
                          value={concept.strength}
                          onChange={(e) =>
                            handleStrengthChange(
                              index,
                              e.target.value as unknown as Strength
                            )
                          }
                        >
                          {Object.keys(Strength)
                            .filter((key) => isNaN(Number(key)))
                            .map((key) => (
                              <MenuItem
                                key={key}
                                value={Strength[key as keyof typeof Strength]}
                              >
                                {key}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="outlined" size="medium">
                        <Select
                          value={concept.confidence}
                          onChange={(e) =>
                            handleConfidenceChange(
                              index,
                              e.target.value as unknown as Confidence
                            )
                          }
                        >
                          {Object.keys(Confidence)
                            .filter((key) => isNaN(Number(key)))
                            .map((key) => (
                              <MenuItem
                                key={key}
                                value={
                                  Confidence[key as keyof typeof Confidence]
                                }
                              >
                                {key}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateLearningPath}
          >
            Generate Learning Path
          </Button>
        </>
      )}
    </div>
    </>
  );
};

export default LearningPathGenerator;
