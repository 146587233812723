import React from "react";
import DownArrow from "../img/svg/downArrow.svg";

interface FaqItemProps {
  question: string;
  answerHtml: string;
  isOpen: boolean;
  toggle: () => void;
}

const FAQ: React.FC<FaqItemProps> = ({ question, answerHtml, isOpen, toggle }) => {
  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`} onClick={toggle}>
      <div className="question">
        <p>{question}</p>
        <img
          src={DownArrow}
          alt="Toggle Answer"
          width="20px"
          height="20px"
          className="arrow"
          style={{
            transform: isOpen ? "rotate(180deg)" : "none",
            transition: "transform 0.3s",
          }}
        />
      </div>
      {isOpen && <div className="answer" dangerouslySetInnerHTML={{ __html: answerHtml }} />}
    </div>
  );
};

export default FAQ;
