import React from 'react';
import Progress from '../utils/Progress';

const INTERVAL_DURATION_IN_MILLISECONDS = 5;

/**
 * Custom hook for animating progress bar. 
 * It takes a Progress object and returns the current animated progress.
 * @param {Progress} progress - Instance of Progress to be animated.
 * @returns {number} - Current animated progress.
 */
const useProgressBarAnimation = (progress: Progress) => {
  const [animatedProgress, setAnimatedProgress] = React.useState(0);

  // useEffect hook to handle progress updates
  React.useEffect(() => {
    const calculatedProgress = progress.calculatePercentage();
    let timer: NodeJS.Timeout | null = null;

    if (calculatedProgress < animatedProgress) {
      setAnimatedProgress(0);
    } else if (calculatedProgress > animatedProgress) {
      const diff = calculatedProgress - animatedProgress;
      const step = diff / 100;
      let currentProgress = animatedProgress;

      timer = setInterval(() => {
        currentProgress += step;
        setAnimatedProgress(Math.min(currentProgress, calculatedProgress));
        if (currentProgress >= calculatedProgress) {
          clearInterval(timer as NodeJS.Timeout);
        }
      }, INTERVAL_DURATION_IN_MILLISECONDS);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [progress, animatedProgress]);

  return animatedProgress;
};

export default useProgressBarAnimation;
