// src/pages/Contact.tsx
import React from 'react';
import '../css/DefaultFooterPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faComments, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DISCORD_URL, SUPPORT_CONTACT_EMAIL } from '@/utils/Constants';

const Contact: React.FC = () => {
  return (
    <div className="page-content">
      <h1>Contact Us</h1>
      {<ContactDetails />}
    </div>
  );
};

export const ContactDetails: React.FC = () => (
  <div>
    <p> <FontAwesomeIcon icon={faComments} /> Chat to us now via our chat widget </p>
    <p><a href={`mailto:${SUPPORT_CONTACT_EMAIL}`}><FontAwesomeIcon icon={faEnvelope} />  {SUPPORT_CONTACT_EMAIL}</a> </p>
    <p> <a href={DISCORD_URL} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDiscord} /> Drop a message on our # support channel</a></p>
  </div>
);

export default Contact;
