import { fetchSubscriptionByEmailNoThrow } from "@/externalLayerAccessor/firebaseAccessor";
import { UserSubscription, UserSubscriptionTier } from "@/externalLayerAccessor/models/firebaseModels";
/*


The `userSubscriptionTier` property must have had expiry accounted for!

This means if user has a premium subscription that has expired, then the `userSubscriptionTier` property should not be set to `PREMIUM` or `ADMIN` but rather `FREE`.
*/
export function userHasPremiumSubscriptionPrivilege(userSubscriptionTierWhichAccountsForExpiryDate: UserSubscriptionTier): boolean {
  const hasPremiumTier: boolean = userSubscriptionTierWhichAccountsForExpiryDate == UserSubscriptionTier.PREMIUM || userSubscriptionTierWhichAccountsForExpiryDate == UserSubscriptionTier.ADMIN;
  return hasPremiumTier;
}


export async function getSubscriptionByUserEmail(userEmail: string): Promise<UserSubscription> {
  let subscription: UserSubscription | null = await fetchSubscriptionByEmailNoThrow(userEmail);

  if (!subscription) {
    return {
      displayName: "user",
      userEmail: userEmail,
      subscriptionTier: UserSubscriptionTier.FREE,
      subscriptionExpiry: null
    };
  }

  //! subscription exists
  const currentDate: Date = new Date();

  //Overrides
  if (subscription.subscriptionExpiry && subscription.subscriptionExpiry < currentDate) { // only susbcription details that have not expired are considered to be valid subscriptions
    subscription.subscriptionTier = UserSubscriptionTier.FREE;
  }

  if(subscription.subscriptionTier == UserSubscriptionTier.FREE){// free subscription does not have expiry date
    subscription.subscriptionExpiry = null;
  }
  return subscription;
}