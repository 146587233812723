import React from 'react';
import combinedTechCompanyLogoFaangPlusImg from '../img/logos/combined-tech-company-logos_fang_and_more.png';

interface TargetTechEmployersIconProps {
  width?: string;
  minWidth?: string;
  title?: string;
}

const TargetTechEmployersIcon: React.FC<TargetTechEmployersIconProps> = ({
  width = "100%",
  minWidth = "300px",
  title = undefined,
}) => {
  return (
    <div className={`companyCredentials`}>
      {title && (
        <h2><em>{title}</em></h2>
      )}
      <img
        src={combinedTechCompanyLogoFaangPlusImg}
        style={{
          width: width,
          minWidth: minWidth,
          margin: "auto",
          borderRadius: "20px",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          padding: "10px",
          backgroundColor: "white",
          zIndex: "5",
        }}
        alt="Company Logos"
      />
    </div>
  );
};

export default TargetTechEmployersIcon;
