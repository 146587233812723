import React from "react";
import { Alert } from "@mui/material";
import PublicLayout from "./PublicLayout";

const PublicWithMobileOptimisationWarningLayout: React.FC = () => {
    return (
        <>
            <div className="mobile-alert">
                <Alert severity="warning">
                    Access this site from your desktop/laptop/tablet for the best experience.
                </Alert>
            </div>
            <PublicLayout/>
        </>
    );
};

export default PublicWithMobileOptimisationWarningLayout;
