import React, { useContext } from 'react';
import JourneyPath from './JourneyPath_beta'
import '../css/Journey_beta.css'
import Sidebar from '../utils/SideBar';
import StateAccessor from '@/StateAccessor';



const JourneyPage: React.FC = () => {
  const { userId } = useContext(StateAccessor);
  return (
    <div className="journey flex flex-col font-sans">
      {/* <Sidebar enableMinification={true} userId={userId}/> */}
      <JourneyPath  />
    </div>
  );
};

export default JourneyPage;