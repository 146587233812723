import React, { useContext, useState } from 'react';
import '../css/ChallengeDescription.css';
import {
  ChallengeDescriptionInterface, Difficulty,
  // Category 
} from '../utils/Constants';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import DropdownItem from '../utils/DropdownItem';  // You might need to adjust the path based on the actual file structure
import Ribbon from '../utils/Ribbon';
// import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import StateAccessor from '@/StateAccessor';
import PremiumContentAccessDeniedMessage from '@/utils/PremiumContentAccessDeniedMessage';

const toPascalCase = (s: string) =>
  s.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

interface ChallengeDescriptionProps {
  challengeDescriptionValues: ChallengeDescriptionInterface;
  currentChallengeRank: number;
  isAssessment?: boolean;
  disabled?: boolean;
}

const ChallengeDescription: React.FC<ChallengeDescriptionProps> = ({ currentChallengeRank, challengeDescriptionValues, isAssessment = false, disabled = false }) => {
  const {
    moduleTitle,
    submoduleTitle,
    unit,
    assessmentType,
    problemStatement,
    rewards,
    // penalty,
    hints,
    difficulty, totalNumChallengesInScope } = challengeDescriptionValues;

  const [currentHintIndex, setCurrentHintIndex] = useState(0);

  const showPreviousHint = () => setCurrentHintIndex(prevIndex => Math.max(prevIndex - 1, 0));
  const showNextHint = () => setCurrentHintIndex(prevIndex => Math.min(prevIndex + 1, hints.length - 1));
  const shouldDisplayContent = (): boolean => {
    return true;
  }



  if (disabled) {
    return null;
  } else if (!unit.challenge.problem_statement) {
    return (
      <div className="challenge-description">
        <p>No problem to solve for this unit</p>
      </div>
    );
  }

  return shouldDisplayContent() ? (
    <div className="challenge-description">
      <div className='challenge-description-top-row'>
        <div className='title-difficulty'>
          <h3 className="unit-title2">
            {unit.title}
          </h3>
          <Ribbon
            parameter={difficulty}
            data={{
              [Difficulty.Straightforward]: { color: 'green', text: 'Straightforward' },
              [Difficulty.ModeratelyChallenging]: { color: 'yellow', text: 'Moderately Challenging' },
              [Difficulty.Challenging]: { color: 'red', text: 'Challenging' },
            }}
          />
        </div>
        {hints.length > 0 && (
          <Popup trigger={<span className="hint-trigger">Hint</span>} position="bottom center">
            <div className="hint-container">
              <button disabled={currentHintIndex === 0} onClick={showPreviousHint}>
                <KeyboardArrowLeft />
              </button>
              <button disabled={currentHintIndex === hints.length - 1} onClick={showNextHint}>
                <KeyboardArrowRight />
              </button>
              <p>{hints[currentHintIndex]}</p>
            </div>
          </Popup>
        )}
      </div>
      <h5 className="unit-subtitle">
        <i className="unit-subtitle-tag">
          {isAssessment
            ? `Challenge ${currentChallengeRank} of ${totalNumChallengesInScope}`
            : `Unit ${currentChallengeRank}`}
        </i><i className="unit-subtitle-tag">{toPascalCase(assessmentType)}</i>
      </h5>
      {/* <h4 className='challenge-title'>{challengeTitle}</h4> */}
      <div className="problem-statement" dangerouslySetInnerHTML={{ __html: problemStatement }} />
      {/* {penalty > 0 && (
        <div className='penalty-container'>
          <h5 className='penalty-title'>Penalty for a wrong answer</h5>
          <p>Lose <b>{penalty}</b> XP</p>
        </div>
      )}
      {rewards && rewards.length > 0 && (
        <div className="reward-container">
          <h5 className='reward-title'>Rewards</h5>
          <div className="reward-content">
            {rewards.map((singleReward, index) => (
              <div key={index} className="single-reward">
                {singleReward.category === Category.Badge && (
                  <div className="badge-reward">
                    <LocalPoliceIcon data-tooltip-id="badge-tooltip" data-tooltip-content="Badge" sx={{ width: '32px', height: '32px', margin: '0.5rem' }} />
                    <span className="reward-criteria">Criteria for achieving: <b>{singleReward.criteria_for_achieving}</b></span>
                  </div>
                )}
                {singleReward.category === Category.ExperiencePoints && (
                  <div className="xp-reward" >
                    <BarChartIcon data-tooltip-id="xp-tooltip" data-tooltip-content="Experience Points" sx={{ width: '32px', height: '32px', margin: '0.5rem' }} />
                    <span className="reward-criteria">Criteria for achieving: <b>{singleReward.criteria_for_achieving}</b></span>
                  </div>
                )}
                {singleReward.category === Category.SpecialAbility && (
                  <div className="special-ability-reward">
                    <AutoAwesomeIcon data-tooltip-id="special-ability-tooltip" data-tooltip-content="Special Ability" sx={{ width: '32px', height: '32px', margin: '0.5rem' }} />
                    <span className="reward-criteria">Criteria for achieving: <b>{singleReward.criteria_for_achieving}</b></span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )} */}
      <div className='challenge-category'>
        {moduleTitle && submoduleTitle && (
          <DropdownItem title="Module">
            <div>
              <li className="challenge-description-course-container">
                <h4 className='module-title'>{moduleTitle} module</h4>
                <h6 className='submodule-title'>{submoduleTitle}</h6>
              </li>
            </div>
          </DropdownItem>
        )}
      </div>
      <Tooltip id="badge-tooltip" />
      <Tooltip id="xp-tooltip" />
      <Tooltip id="special-ability-tooltip" />
    </div>
  ) : (<PremiumContentAccessDeniedMessage />);
};

export default ChallengeDescription;
