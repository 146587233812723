import React from 'react';
import GenericLearningHome, { ActiveTiles, LearningComponents } from './GenericLearningHome';

// Define a wrapper component
const CrashCourseMenu: React.FC = () => {
  // Specify the titles that should be active
  const activeTitles: ActiveTiles = new ActiveTiles([
    [LearningComponents.AlgosAndDataStructuresCrashCourse, 1],
    [LearningComponents.AlgosAndDataStructuresCrashCourseIntermediate, 2],
    [LearningComponents.OptimizeYourInterviewPerformanceCrashCourse, 3],
    [LearningComponents.SystemDesignCrashCourse, 4],
    // Add more titles as needed
  ]);

  return (
    <div>
      {/* Pass activeTitles as a prop to GenericLearningHome */}
      <GenericLearningHome 
        activeTitles={activeTitles}
      />
    </div>
  );
};

export default CrashCourseMenu;
