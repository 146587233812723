import React from 'react';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import Modal from '../utils/Modal';
import { Interview } from './InterviewSelectionPage';
import '../css/Modal.css';
import '../css/InterviewSelectionModal.css';

interface InterviewSelectionModalProps {
  availableInterviews: Interview[];
  onClose: () => void;
  onInterviewClick: (interview: Interview) => void;
}

const InterviewSelectionModal: React.FC<InterviewSelectionModalProps> = ({ availableInterviews, onClose, onInterviewClick }) => {
  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty.toLowerCase()) {
      case "straightforward": return "#28a5b3";
      case "moderately challenging": return "#ffb020";
      case "challenging": return "red";
      default: return "black";
    }
  }

  const getStageColor = (stage: string) => {
    switch (stage.toLowerCase()) {
      case "telephone": return "purple";
      case "take-home assessment": return "#814fbf";
      case "on-site": return "brown";
      default: return "black";
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'interviewName',
      headerName: 'Interview Name',
      width: 170
    },
    {
      field: 'interviewDifficulty',
      headerName: 'Difficulty',
      width: 200,
      renderCell: (params: GridCellParams) => (
        <div style={{ backgroundColor: getDifficultyColor(params.value ? params.value as string : ''), color: 'white', borderRadius: '5px', padding: '5px' }}>
          {params.value ? params.value as string : ''}
        </div>
      ),
    },
    {
      field: 'interviewDurationInMinutes',
      headerName: 'Time (mins)',
      width: 100
    },
    {
      field: 'interviewStage',
      headerName: 'Stage',
      minWidth: 200,
      flex: 1, // Here we give this column a larger flex value, which will make it take up the remaining space
      renderCell: (params: GridCellParams) => (
        <div style={{ backgroundColor: getStageColor(params.value ? params.value as string : ''), color: 'white', borderRadius: '5px', padding: '5px' }}>
          {params.value ? params.value as string : ''}
        </div>
      ),
    },
  ];

  const rows = availableInterviews.map((interview) => ({
    id: interview.interviewId,
    ...interview
  }));

  return (
    <Modal
      onClose={onClose}
      content={
        <div className="interview-selection-modal-container">
          <h2 className="modal-title">Available Interviews</h2>
          <span className='mobile-note'>
            Note: Scroll to the right to view all columns
          </span>
          <div style={{ height: 412, width: '100%' }}>
            <DataGrid
              className="my-datagrid-class"
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              onRowClick={(params) => onInterviewClick(params.row as Interview)}
            />
          </div>
        </div>
      }
    />
  );
};

export default InterviewSelectionModal;
