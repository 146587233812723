
import { collection, query, where, getDocs, QuerySnapshot, DocumentData} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { logToBackendLogFile } from "@/externalLayerAccessor/BackEndRequests";
import { UserSubscription, UserSubscriptionTier } from "./models/firebaseModels";


export const fetchSubscriptionByEmailNoThrow = async (userEmail: string): Promise<UserSubscription | null> => {
    try {
        const subscriptionsCollectionRef = collection(db, "subscriptions");
        const fetchSubscriptionByEmailQuery = query(subscriptionsCollectionRef, where("email", "==", userEmail.toLowerCase()));
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(fetchSubscriptionByEmailQuery);

        if (querySnapshot.empty) {
            return null;
        }

        //! reaching here means at least one document was found in the collection matching the query

        if (querySnapshot.docs.length > 1) {
            logToBackendLogFile(`More than one subscription found for email: ${userEmail}`, "warn");
        }

        const firstSubscriptionDoc = querySnapshot.docs[0];
        const subscriptionData = firstSubscriptionDoc.data();

        // Ensure all required fields are present
        if (!subscriptionData.subscriptionExpiry || !subscriptionData.subscriptionTier) {
            logToBackendLogFile(`Missing subscription data for email: ${userEmail}`, "error");
            return null;
        }

        // sanity check that emails match
        if (subscriptionData.email !== userEmail.toLowerCase()) {
            const errMsg = `Subscription email received: ${subscriptionData.email} does not match that provided: ${userEmail} in query`;
            logToBackendLogFile(errMsg, "error");
            return null;
        }

        const expiryDate: Date | null = subscriptionData.subscriptionExpiry ? subscriptionData.subscriptionExpiry.toDate() : null;
        const tier: UserSubscriptionTier = UserSubscriptionTier[subscriptionData.subscriptionTier as keyof typeof UserSubscriptionTier] || UserSubscriptionTier.FREE;

        return {
            displayName: subscriptionData.displayName,
            userEmail: subscriptionData.email,
            subscriptionTier: tier,
            subscriptionExpiry: expiryDate,
        };
    } catch (error) {
        //console.error(`Error fetching subscription for email: ${userEmail}: ${error}`);
        logToBackendLogFile(`Error fetching subscription for email: ${userEmail}: ${error}`, "error");
        return null;
    }
};
