import ReactDOM from "react-dom/client";
import { Elements } from "@stripe/react-stripe-js"; // Import Elements provider from @stripe/react-stripe-js
import App from "./App";
import log from "./utils/Logger";
import { stripePromise } from './utils/stripe'; // Import the shared Stripe initialization module


log.info(`REACT_APP_NODE_ENV: ${process.env.REACT_APP_NODE_ENV}`);

// create a root to render the application
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}
const root = ReactDOM.createRoot(rootElement);

// Render the application wrapped with the Elements provider
// The Elements provider sets up a React context that allows all child components
// to access the same Stripe instance. This is necessary for managing payment flows
// consistently and securely across the application.
root.render(
  // Elements provider takes the initialized Stripe instance (Promise) and makes it
  // available to any nested Stripe Elements components via React's Context API.
  <Elements stripe={stripePromise}>
    <App />
  </Elements>
);
