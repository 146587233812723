import React, { useState } from "react";
import "./css/OutputWindow.css";
import { ExecutionResult } from "./utils/Constants";

interface OutputWindowProps {
  codeExecutionResult: ExecutionResult | null;
}

enum Tabs {
  StdOut = "std out",
  StdErr = "std err",
  ExecutionMetrics = "execution metrics",
}

interface TabContentProps {
  selectedTab: Tabs;
  codeExecutionResult: ExecutionResult | null;
}

const TabContent: React.FC<TabContentProps> = ({ selectedTab, codeExecutionResult }) => {
  // Early return for ExecutionMetrics tab when codeExecutionResult is null
  if (selectedTab === Tabs.ExecutionMetrics && !codeExecutionResult) {
    return <p>Execution metrics like memory use and running time will be displayed here.</p>;
  }


  switch (selectedTab) {
    case Tabs.StdOut:
      return (
        <pre><p>{codeExecutionResult?.actual_output || "No output to display"}</p></pre>
      );
    case Tabs.StdErr:
      return <pre><p>{codeExecutionResult?.stderr || "No errors to display"}</p></pre>
    case Tabs.ExecutionMetrics:
      const executionTimeSeconds = codeExecutionResult?.execution_time_seconds;
      const memoryUsageBytes = codeExecutionResult?.memory_usage_bytes;
      const memoryUsageMB = memoryUsageBytes ? (memoryUsageBytes / (1024 * 1024)).toFixed(2) : null; // memory used in MB to 2 decimal places
      return (
        <>
          <pre><p>Execution Time: {executionTimeSeconds ? `${executionTimeSeconds} seconds` : 'N/A'}</p></pre>
          <pre><p>Memory Usage: {memoryUsageMB ? `${memoryUsageMB} MB` : 'N/A'}</p></pre>
        </>
      );
    default:
      return <p>...</p>;
  }
};
const OutputWindow: React.FC<OutputWindowProps> = ({ codeExecutionResult }) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.StdOut);

  return (
    <div className="output-tabbed-view-container">
      <div className="output-tabbed-view-header">
        {/* <button>Run</button> */}
        <button
          className={`std-out-tab ${selectedTab === Tabs.StdOut ? "selected-tab" : "selectable-tab"}`}
          onClick={() => setSelectedTab(Tabs.StdOut)}
        >
          Std Out
        </button>
        <button
          className={`std-err-tab ${selectedTab === Tabs.StdErr ? "selected-tab" : "selectable-tab"}`}
          onClick={() => setSelectedTab(Tabs.StdErr)}
        >
          Std Err
        </button>
        <button
          className={`execution-metrics-tab ${selectedTab === Tabs.ExecutionMetrics ? "selected-tab" : "selectable-tab"}`}
          onClick={() => setSelectedTab(Tabs.ExecutionMetrics)}
        >
          Execution Metrics
        </button>
      </div>
      <div className="output-tabbed-view-content">
        <TabContent selectedTab={selectedTab} codeExecutionResult={codeExecutionResult} />
      </div>
    </div>
  );
};

export default OutputWindow;