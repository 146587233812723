import React from 'react';
import Task from './Task_beta';
import '../css/Phases.css'

const PhasesPage: React.FC = () => {
  return (
    <div className="phases font-sans">
      <Task/>
    </div>
  );
};

export default PhasesPage;