import axios from "axios";
import { REACT_APP_BACK_END_BASE_URL, retryRequest } from "./BackEndRequests";

const USERS_COLLECTION_BASE_URL = `${REACT_APP_BACK_END_BASE_URL}/users`;

export interface ResourceUsageStats {
    remainingUnits: number;
    lastUsedAt: Date;
}

export interface UserResourceUsage {
    codeExecution: ResourceUsageStats | null;
    ai_assistant: ResourceUsageStats | null;
}

export enum UserResourceType {
    CODE_EXECUTION = "codeExecution",
    AI_ASSISTANT = "aiAssistant",
}

enum UserResourceTypeBackend {
    CODE_EXECUTION = "code_execution",
    AI_ASSISTANT = "ai_assistant",
}

function constructQueryStringForFetchingUserResources(userId: number): string {
    let baseUrl = `${USERS_COLLECTION_BASE_URL}/${userId}/resources`;
    let params = new URLSearchParams();

    const allResourceTypes = [UserResourceTypeBackend.CODE_EXECUTION, UserResourceTypeBackend.AI_ASSISTANT];
    params.append('resource_types', allResourceTypes.join(','));

    // Construct the full URL with query string if parameters exist
    let queryString = params.toString();
    let fullUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    return fullUrl;
}

export async function getResourceUsageByUserId(userId: number): Promise<UserResourceUsage | null> {

    // prepare get request
    const url = constructQueryStringForFetchingUserResources(userId);

    // send get request and return result
    try {
        return await retryRequest(async () => {
            const response = await axios.get(url);
            if (response.status === 404) {
                console.error("User not found");
                return null;
            }

            const resources = response.data.resources;

            let result: UserResourceUsage = {
                codeExecution: null,
                ai_assistant: null,
            };

            // iterate through resources and populate result
            resources.forEach((resource: any) => {
                const resourceType: UserResourceTypeBackend = resource.resource_type;
                const resourceStats: ResourceUsageStats = {
                    remainingUnits: resource.remaining_units,
                    lastUsedAt: new Date(resource.last_used_at),
                };

                switch(resourceType) {
                    case UserResourceTypeBackend.CODE_EXECUTION:
                        result.codeExecution = resourceStats;
                        break;
                    case UserResourceTypeBackend.AI_ASSISTANT:
                        result.ai_assistant = resourceStats;
                        break;
                    default:
                        console.error(`Unknown resource type: ${resourceType}`);
                }
            });

            return result;

        });

    } catch (error: any) {
        if (error.response && error.response.status === 500) {
            console.error("Internal server error");
        } else {
            console.error("Error fetching user resources:", error);
        }
        return null;
    }
}
