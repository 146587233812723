import React from 'react';
import '../css/BentoBox.css';

export interface BentoBoxItem {
  id: number;
  imgSrc: string;
  altText: string;
  title: string;
  description: string;
  linkText: string;
  linkAction: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface BentoBoxProps {
  items: BentoBoxItem[];
  activeStates: boolean[];
  handleClick: (id: number) => void;
  imgWidth?: string;
  imgHeight?: string;
}

const BentoBox: React.FC<BentoBoxProps> = ({ items, activeStates, handleClick, imgWidth = "150px", imgHeight = "150px" }) => {
  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    linkAction: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  ) => {
    e.preventDefault();
    linkAction(e);
  };

  return (
    <div className="bento-box">
      {items.map((item, index) => (
        <div key={item.id} className={`grid-item item${item.id}`} onClick={() => handleClick(item.id)}>
          <img src={item.imgSrc} alt={item.altText} style={{ width: imgWidth, height: imgHeight }} />
          <div className={`itemText ${activeStates[index] ? "active" : ""}`}>
            <h3 className={activeStates[index] ? "active" : ""}>{item.title}</h3>
            <div className={activeStates[index] ? "box active" : "box"}>
              <p>{item.description}</p>
              <a href="#" onClick={(e) => handleLinkClick(e, item.linkAction)}>{item.linkText} →</a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BentoBox;
