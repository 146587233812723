import React from 'react';

interface RibbonProps {
  parameter: string;
  data: Record<string, { color: string; text: string }>;
}

const Ribbon: React.FC<RibbonProps> = ({ parameter, data }) => {
  const { color, text } = data[parameter] || data[Object.keys(data)[0]];
  return <button className={`ribbon ${color}`}><b>{text}</b></button>;
};

export default Ribbon;
