import React from 'react';

interface ModalProps {
  content: React.ReactNode;
  closeDisabled?: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ content, closeDisabled = false, onClose }) => {
    // Define the inline styles for the modal container
    const modalContainerStyle: React.CSSProperties = {
      backgroundColor: '#fff',
      borderRadius: '5px',
      boxShadow: '0 0 10px 0 rgba(0,0,0,.5)',
      height: '85%',
      maxHeight: '90%',
      overflow: 'auto',
      position: 'relative',
      width: '79%',
      // Add any additional styles you want to apply to the modal container
    };
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10" onClick={onClose}>
        <div className="relative w-4/5 my-8" onClick={(event) => event.stopPropagation()} style={modalContainerStyle}>
          {!closeDisabled && (
            <button className="absolute top-2 right-2 text-3xl cursor-pointer bg-transparent border-none" onClick={onClose}>
              X
            </button>
          )}
          <div className="content-box-modal2 bg-white p-4 rounded-lg shadow-lg">
            {content}
          </div>
        </div>
      </div>
    );
  };

export default Modal;